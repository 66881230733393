import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Chip } from 'radiance-ui';
import { SPACER } from 'radiance-ui/lib/constants';

import type { BlogTagBasicEntry } from 'schema';
import { getBlogListPath } from 'utils/getBlogListPath';

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${SPACER.x3large};
`;

const TagContainer = styled(Link)`
  margin: 0 ${SPACER.small} ${SPACER.small} 0;
  text-decoration: none;
`;

export const Tags = ({ tags }: { tags: BlogTagBasicEntry[] }) => (
  <TagsContainer>
    {tags.map((tag) => (
      <TagContainer key={tag.title} to={getBlogListPath(tag.slug)}>
        <Chip text={tag.title} isLowContrast />
      </TagContainer>
    ))}
  </TagsContainer>
);
