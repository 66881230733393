import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';

export const OuterContainer = styled.div`
  padding: ${SPACER.x3large} 0 0;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.COLORS.white};

  ${MEDIA_QUERIES.mdUp} {
    padding: 4.5rem;
  }
`;

export const InnerContainer = styled.div<{ showTextBox: boolean }>`
  margin: 0 auto;
  max-width: 1136px;
  display: grid;
  gap: ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    grid-template-columns: ${({ showTextBox }) =>
      showTextBox ? `2fr 3fr` : `1fr`};
    justify-content: space-between;
    justify-items: center;
  }
`;

export const TextContainer = styled.div`
  padding: 0 ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    padding: 0;
    margin-right: ${SPACER.large};
  }
`;

export const headerStyles = (theme: ThemeType) => css`
  color: ${theme.COLORS.primary};
`;

export const headerDescription = (theme: ThemeType) => css`
  color: ${theme.COLORS.primaryTint1};
  margin-top: ${SPACER.medium};
`;

export const ctaButtonStyles = () => css`
  margin-top: ${SPACER.xlarge} !important;
  margin-bottom: ${SPACER.small};
  margin: unset;
  text-align: left;

  ${MEDIA_QUERIES.mdUp} {
    margin-bottom: unset;
  }
`;
