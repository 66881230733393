import React from 'react';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';

import { MultiCategoryHero } from './MultiCategoryHero';
import type { MultiCategoryHeroEntry } from 'schema';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { SmartImage } from 'components/global/SmartImage';

export const ContentfulMultiCategoryHero = ({
  subheadline,
  categories,
  ...props
}: MultiCategoryHeroEntry) => (
  <MultiCategoryHero
    {...props}
    subheadline={documentToReactComponents(subheadline, RICH_TEXT_OPTIONS)}
    categories={categories.map(({ desktopImage, mobileImage, ...props }) => ({
      ...props,
      desktopImage: <SmartImage {...desktopImage} />,
      mobileImage: <SmartImage {...mobileImage} />,
    }))}
  />
);
