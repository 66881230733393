import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { MEDIA_QUERIES, SPACER, Z_SCALE } from 'radiance-ui/lib/constants';

import { LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';

export const MainBox = styled.div`
  margin: 0 auto;
  padding: ${SPACER.x2large} ${SPACER.large} ${SPACER.x4large};
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};

  ${MEDIA_QUERIES.mdUp} {
    padding: 5rem ${SPACER.xlarge};
    position: relative;
  }
`;

export const Header = styled.h2`
  ${MEDIA_QUERIES.mdUp} {
    text-align: center;
  }
`;

export const disclaimerStyles = css`
  ${MEDIA_QUERIES.mdUp} {
    text-align: center;
  }
`;

export const StepsBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${SPACER.xlarge};

  ${MEDIA_QUERIES.mdUp} {
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;

    margin-top: ${SPACER.x3large};
  }
`;

export const StepBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${SPACER.large};

  :last-child {
    margin-bottom: 0;
  }

  ${MEDIA_QUERIES.mdUp} {
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 4.5rem;
    max-width: 208px;
    width: 100%;

    :last-child {
      margin-right: 0;
    }
  }
`;

export const StepCircle = styled.div`
  width: 144px;
  aspect-ratio: 1;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  z-index: ${Z_SCALE.e6};

  ${MEDIA_QUERIES.mdUp} {
    width: 208px;
  }

  img {
    aspect-ratio: 1;
    object-fit: cover;
  }
`;

export const StepText = styled.div`
  margin-left: ${SPACER.large};
  flex: 1;

  ${MEDIA_QUERIES.mdUp} {
    margin-left: 0;
    margin-top: ${SPACER.medium};
  }
`;

export const Text = styled.div`
  ${MEDIA_QUERIES.mdUp} {
    text-align: center;
  }
`;

export const ctaButtonStyles = () => css`
  margin-top: ${SPACER.xlarge};
  ${MEDIA_QUERIES.lgUp} {
    margin: ${SPACER.x2large} auto 0 auto;
    small {
      text-align: center;
    }
  }
`;
