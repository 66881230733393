import React from 'react';
import { Carousel as RadianceCarousel } from 'radiance-ui';

import {
  desktopOnly,
  mobileOnly,
  tabletOnly,
} from 'styles/mixins/displayMobileDesktop';

import { CarouselContainer } from './Carousel.style';

export interface CarouselProps {
  /** List of JSX.Elements */
  items: JSX.Element[];
  desktopCardSize: 'Large' | 'Medium' | 'Small';
}

export const Carousel = ({
  items,
  desktopCardSize = 'Medium',
}: CarouselProps) => (
  <CarouselContainer>
    <div css={desktopOnly('block', 'lgUp')}>
      <RadianceCarousel
        numCardsVisible={desktopCardSize === 'Large' ? 2 : 3}
        bottomRightAlignedArrows
      >
        {items}
      </RadianceCarousel>
    </div>
    <div css={tabletOnly('block', 'mdUp', 'lgUp')}>
      <RadianceCarousel
        numCardsVisible={desktopCardSize === 'Small' ? 3 : 2}
        bottomRightAlignedArrows
      >
        {items}
      </RadianceCarousel>
    </div>
    <div css={tabletOnly('block', 'smUp', 'mdUp')}>
      <RadianceCarousel
        numCardsVisible={2}
        bottomRightAlignedArrows
        centerMode={false}
      >
        {items}
      </RadianceCarousel>
    </div>
    <div css={mobileOnly('block', 'smUp')}>
      <RadianceCarousel
        numCardsVisible={1}
        bottomRightAlignedArrows
        centerMode={false}
      >
        {items}
      </RadianceCarousel>
    </div>
  </CarouselContainer>
);
