import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const PurchaseTypeToggle = styled.div`
  align-items: flex-start;
  background: ${LOCAL_COLORS.surfaceWhite};
  border-top: 1px solid ${LOCAL_COLORS.surfaceDarkBold};
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0 ${SPACER.medium} ${SPACER.medium};
  position: fixed;
  width: 100vw;
  z-index: 999;

  ${MEDIA_QUERIES.mdUp} {
    border-top: none;
    padding: 0 ${SPACER.medium};
    position: relative;
    width: auto;
  }
`;

export const PurchaseTypeLine = styled.div`
  padding: ${SPACER.small} 0;
  width: 100%;

  div {
    display: flex;
  }

  &:first-of-type {
    border-bottom: 1px solid ${LOCAL_COLORS.surfaceDarkBold};
  }

  ${MEDIA_QUERIES.mdUp} {
    padding: ${SPACER.medium} 0;
  }
`;

export const StyledRadio = styled.input`
  appearance: none;
  margin-right: ${SPACER.small};
  width: 20px;
  height: 20px;
  border: 2px solid ${LOCAL_COLORS.surfaceDarkBold};
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  margin-top: ${SPACER.xsmall};

  &:checked::after {
    background: ${LOCAL_COLORS.surfaceOrange};
  }

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 2px;
    cursor: pointer;
  }
`;
export const OptionText = styled.div`
  font-weight: 700;
  color: ${LOCAL_COLORS.textDefault};
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StrikeThroughText = styled.div`
  text-decoration: line-through;
  color: ${LOCAL_COLORS.textGreyedOut};
  margin-right: ${SPACER.xsmall};
`;

export const SavingsText = styled.div`
  font-size: 12px;
  color: ${LOCAL_COLORS.textDefault};
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${MEDIA_QUERIES.mdUp} {
    padding-left: 28px;
  }

  div:first-of-type {
    color: ${LOCAL_COLORS.surfaceBrandBolder};
    display: block;
  }
`;

export const ctaStyles = css`
  max-width: unset;
  width: 100% !important;
  height: 48px;

  button {
    height: 48px !important;
  }
`;
