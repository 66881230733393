import React, { useState } from 'react';
import { Text, INLINES, NodeData } from '@contentful/rich-text-types';
import type { Options } from '@contentful/rich-text-react-renderer';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';
import { ContentfulModal } from 'components/global/modal/ContentfulModal';
import { MainModalBoxStyles } from 'components/global/modal/ContentfulModal.style';
import { Modal } from 'components/global/modal';

const OpenModal = ({ data, content }: { data: NodeData; content: Text }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <React.Fragment>
      <button
        onClick={() => setModalIsOpen(true)}
        css={{ textDecoration: 'underline', fontWeight: 700 }}
      >
        {content.value}
      </button>

      {modalIsOpen && (
        <Modal
          children={<ContentfulModal {...data.target.fields} />}
          backgroundColor={'#fff'}
          styles={() => MainModalBoxStyles}
          onClose={() => setModalIsOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

export const CUSTOM_RICH_TEXT_OPTIONS: Options = {
  renderNode: {
    ...RICH_TEXT_OPTIONS.renderNode,
    [INLINES.ENTRY_HYPERLINK]: ({ data, content }) => {
      if (content[0].nodeType !== 'text') return;
      return <OpenModal data={data} content={content[0]} />;
    },
  },
};
