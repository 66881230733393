import React, { useState } from 'react';
import { Ingredient, IngredientProps } from './components/Ingredient';
import {
  CardHolder,
  PrescriptionInfoContainer,
  InnerContainer,
  HeaderContainer,
  MainContent,
  LeftContainer,
  ProductImage,
  IngredientTitle,
  IngredientCards,
  Percentages,
  Percentage,
} from './PrescriptionInfo.style';
import { Disclaimer } from 'components/global/StickyCta.style';
import { ProviderCard, ProviderCardProps } from './components/ProviderCard';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

type PrescriptionInfoProps = {
  headline: string;
  backgroundColor?: string;
  productImage: React.ReactNode;
  ingredientSectionTitle?: string;
  ingredientCards: IngredientProps[];
  providerCard: ProviderCardProps;
  disclaimer?: React.ReactNode;
  ingredientPercentages?: string[];
};

const moduleName = 'prescriptionInfo';

export const PrescriptionInfo = ({
  headline,
  productImage,
  ingredientSectionTitle,
  ingredientCards,
  providerCard,
  backgroundColor,
  disclaimer,
  ingredientPercentages,
}: PrescriptionInfoProps) => {
  const [activeStep, setActiveStep] = useState(1);

  return (
    <PrescriptionInfoContainer
      data-module={moduleName}
      backgroundColor={backgroundColor}
    >
      <InnerContainer>
        <HeaderContainer>{headline}</HeaderContainer>
        <MainContent>
          <LeftContainer>
            <ProviderCard {...providerCard} />
            {ingredientSectionTitle && (
              <IngredientTitle>{ingredientSectionTitle}</IngredientTitle>
            )}
            <CardHolder>
              <IngredientCards>
                {ingredientCards.map((ingredientCard, index) => {
                  const ingredientProp = {
                    index,
                    isActive: index === activeStep,
                    setIsActive: (isActive: boolean) =>
                      setActiveStep(isActive ? index : 1),
                  };
                  return (
                    <Ingredient
                      key={index}
                      {...ingredientCard}
                      {...ingredientProp}
                    />
                  );
                })}
                <Percentages>
                  {ingredientPercentages?.map((ingredientPercentage, index) => {
                    return (
                      <Percentage key={index}>
                        {ingredientPercentage}
                      </Percentage>
                    );
                  })}
                </Percentages>
              </IngredientCards>
              <ProductImage css={mobileOnly()}>{productImage}</ProductImage>
            </CardHolder>

            <Disclaimer>{disclaimer}</Disclaimer>
          </LeftContainer>
          <div>
            <ProductImage css={desktopOnly()}>{productImage}</ProductImage>
          </div>
        </MainContent>
      </InnerContainer>
    </PrescriptionInfoContainer>
  );
};
