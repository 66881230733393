import React from 'react';

import type { BlogTagBasicEntry } from 'schema';

import {
  BodyContainer,
  linkStyles,
  listItemStyles,
  listStyles,
} from './Breadcrumb.style';
import { BreadcrumbSchema } from './BreadcrumbSchema';

type BreadcrumbProps = {
  baseURL: string;
  mainCategory?: BlogTagBasicEntry;
};

export const Breadcrumb = ({ baseURL, mainCategory }: BreadcrumbProps) => {
  const delimiter = ' > ';
  const categoryPath = baseURL.includes('blog')
    ? `${baseURL}tag/${mainCategory?.slug}/`
    : `${baseURL}${mainCategory?.slug}/`;
  const baseText = baseURL.replace(/\//g, '');

  const breadcrumbList = [
    { name: baseText, item: baseURL },
    { name: mainCategory?.title, item: categoryPath },
  ];

  return (
    <React.Fragment>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <BodyContainer>
        <ol css={listStyles}>
          <li css={listItemStyles}>
            <a css={linkStyles} href={baseURL}>
              {baseText}
            </a>
          </li>
          {mainCategory && (
            <li css={listItemStyles}>
              {delimiter}
              <a css={linkStyles} href={categoryPath}>
                {mainCategory.title}
              </a>
            </li>
          )}
        </ol>
      </BodyContainer>
    </React.Fragment>
  );
};
