import { SmartImage } from 'components/global/SmartImage';
import React from 'react';
import type { ProductsPopEntry } from 'schema';
import { ProductsPop } from './';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';
import { ProductTreatments } from './content';

export const ContentfulProductsPop = ({
  products,
  ...props
}: ProductsPopEntry) => {
  return (
    <ProductsPop
      {...props}
      products={products.map(
        ({ image, name, price, treatments, ...props }) => ({
          ...props,
          image: <SmartImage {...image} />,
          name: documentToReactComponents(name, RICH_TEXT_OPTIONS),
          price: price && documentToReactComponents(price, RICH_TEXT_OPTIONS),
          treatments: treatments as ProductTreatments[],
        }),
      )}
    />
  );
};
