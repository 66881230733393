import { ContentfulImage } from 'components/global/ContentfulImage';
import { ContentfulEmbeddedYoutubeVideo } from 'components/modules/EmbeddedYoutubeVideo';
import { PAGE_URLS } from 'consts/pageUrls';
import type {
  ArticleAuthorEntry,
  BlogTagBasicEntry,
  EmbeddedYoutubeVideoEntry,
  ImageAsset,
} from 'schema';
import { LOCAL_COLORS } from 'styles/constants';

import { Authors } from './components/Authors';
import { Breadcrumb } from './components/Breadcrumb';
import {
  AuthorContainer,
  AuthorLgScreenContainer,
  AuthorLgScreenOuterContainer,
  AuthorOuterContainer,
  HeaderSubtitle,
  HeaderTitle,
  HeroAsset,
  HeroContainer,
  HeroOuterContainer,
  MedicalReviewerContainer,
  TitleContainer,
} from './Header.style';

type HeaderProps = {
  articleAuthors: ArticleAuthorEntry[];
  featuredArticleAuthors?: ArticleAuthorEntry[];
  medicalReviewer?: ArticleAuthorEntry;
  title: string;
  subtitle: string;
  date: string;
  readTimeInMinutes?: number;
  image: ImageAsset;
  video?: EmbeddedYoutubeVideoEntry;
  mainCategory?: BlogTagBasicEntry;
};

export const Header = ({
  articleAuthors,
  featuredArticleAuthors,
  medicalReviewer,
  title,
  subtitle,
  date,
  readTimeInMinutes,
  image,
  video,
  mainCategory,
}: HeaderProps) => {
  const formatName = (person: ArticleAuthorEntry) => {
    return person.credentials
      ? [person.name, ...person.credentials].join(', ')
      : person.name;
  };

  return (
    <HeroOuterContainer id="hero">
      <HeroContainer>
        <TitleContainer>
          <Breadcrumb
            baseURL={PAGE_URLS.blog.home}
            mainCategory={mainCategory}
          />
          <HeaderTitle>{title}</HeaderTitle>
          <HeaderSubtitle>{subtitle}</HeaderSubtitle>
          <AuthorOuterContainer>
            <AuthorContainer>
              <Authors
                articleAuthors={articleAuthors}
                featuredArticleAuthors={featuredArticleAuthors}
                date={date}
                readTimeInMinutes={readTimeInMinutes}
              />
            </AuthorContainer>
            <MedicalReviewerContainer>
              {!!medicalReviewer && (
                <div>
                  Medically reviewed by{' '}
                  <span css={{ color: LOCAL_COLORS.corePurple600 }}>
                    {formatName(medicalReviewer)}
                  </span>
                </div>
              )}
            </MedicalReviewerContainer>
          </AuthorOuterContainer>
        </TitleContainer>
        {image && !video && (
          <HeroAsset>
            <ContentfulImage alt={image.title} image={image} />
          </HeroAsset>
        )}
        {video && (
          <HeroAsset>
            <ContentfulEmbeddedYoutubeVideo {...video} />
          </HeroAsset>
        )}
      </HeroContainer>
      <AuthorLgScreenOuterContainer>
        <AuthorLgScreenContainer>
          <Authors
            articleAuthors={articleAuthors}
            featuredArticleAuthors={featuredArticleAuthors}
            date={date}
            readTimeInMinutes={readTimeInMinutes}
          />
        </AuthorLgScreenContainer>
        <MedicalReviewerContainer>
          {!!medicalReviewer && (
            <div css={{ marginTop: !featuredArticleAuthors ? '6px' : '0' }}>
              Medically reviewed by{' '}
              <span css={{ color: LOCAL_COLORS.corePurple600 }}>
                {formatName(medicalReviewer)}
              </span>
            </div>
          )}
        </MedicalReviewerContainer>
      </AuthorLgScreenOuterContainer>
    </HeroOuterContainer>
  );
};
