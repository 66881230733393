import React, { ReactNode } from 'react';
import {
  OuterContainer,
  BannerText,
  ProductImage,
} from './ProductBanner.style';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';
import { ImageAsset } from 'schema';
import { imageAssetToGatsbyImageData } from 'utils/contentful/imageAssetToGatsbyImageData';
import { GatsbyImage } from 'gatsby-plugin-image';

type ProductBannerProps = {
  bannerText: ReactNode;
  productImage: ImageAsset;
  device?: 'Desktop Only' | 'Mobile Only' | 'All Devices';
  backgroundColor?: string;
};

const moduleName = 'productBanner';

export const ProductBanner = ({
  bannerText,
  productImage,
  device,
  backgroundColor,
}: ProductBannerProps) => (
  <OuterContainer
    data-module={moduleName}
    backgroundColor={backgroundColor}
    css={
      device === 'Desktop Only'
        ? desktopOnly('block', 'lgUp')
        : device === 'Mobile Only'
        ? mobileOnly('block', 'lgUp')
        : {}
    }
  >
    <BannerText>{bannerText}</BannerText>
    <ProductImage>
      <GatsbyImage
        alt={productImage.title}
        image={imageAssetToGatsbyImageData(productImage)}
        imgStyle={{ width: '100%', height: 'auto' }}
      />
    </ProductImage>
  </OuterContainer>
);
