import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import type { StepsProps } from '..';

export const STEPS_MALE_CONTENT: StepsProps = {
  header: 'Do less. Good skin in 3 no-effort steps',
  step1Image: (
    <StaticImage
      src="../images/male/step1.png"
      width={208}
      alt="phone taking a selfie"
    />
  ),
  step1Text: 'Share skin selfies (no sweat—they’re confidential).',
  step2Image: (
    <StaticImage
      src="../images/male/step2.png"
      width={208}
      alt="patient holding Curology Custom Formula"
    />
  ),
  step2Text: 'Get a custom bottle—delivered.',
  step3Image: (
    <StaticImage
      src="../images/male/step3.png"
      width={208}
      alt="patient applying Curology Custom Formula"
    />
  ),
  step3Text: 'Apply nightly and rock confident skin.',
};
