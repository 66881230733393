import React from 'react';
import { Avatar } from 'radiance-ui';

import type { ArticleAuthorEntry } from 'schema';

import { BylineText, PersonContainer, PersonName } from './Person.style';
import defaultAvatarImage from './images/defaultAvatar.png';

export type PersonProps = ArticleAuthorEntry & { byline: string };

export const Person = ({ avatar, byline, name, credentials }: PersonProps) => (
  <PersonContainer>
    <Avatar
      size="large"
      src={avatar?.url ?? defaultAvatarImage}
      alt={avatar?.title ?? 'Default avatar'}
    />
    <div>
      <BylineText>{byline}</BylineText>
      <PersonName>
        {credentials ? [name, ...credentials].join(', ') : name}
      </PersonName>
    </div>
  </PersonContainer>
);
