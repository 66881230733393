import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { primaryTheme } from 'radiance-ui/lib/constants';
import { CheckmarkIcon } from 'radiance-ui/lib/icons';

import { SPLIT_CONTENT_LAYOUT, type SplitContentProps } from '../SplitContent';

export const SPLIT_CONTENT_CUSTOM_FORMULA_CONTENT: SplitContentProps = {
  header: `Try Curology risk-free. Get your $ back if you don’t love it`,
  body: 'Try your Custom Formula—plus your choice of other expert-made skincare essentials—risk-free.  Love your results in 90 days, or we’ll refund you. ',
  details: [
    'Cancel anytime',
    'Swap or remove products anytime',
    'Easy bi-monthly deliveries',
  ],
  detailsIcon: <CheckmarkIcon />,
  ctaButton: {
    buttonText: 'Get Started',
    disclaimerText: 'Subject to consultation.',
  },
  ctaButtonDisplay: ['Show on desktop'],
  media: (
    <StaticImage
      src="../images/customFormula@2x.jpg"
      alt="woman applying curology custom formula"
      objectPosition="center"
    />
  ),
  layout: {
    desktop: SPLIT_CONTENT_LAYOUT.desktop.imageLast,
    mobile: SPLIT_CONTENT_LAYOUT.mobile.imageLast,
  },
  backgroundColor: primaryTheme.COLORS.tertiaryTint3,
};
