import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import LOCAL_COLORS from 'styles/constants/colors';

export const WhatWeTreatMainContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${LOCAL_COLORS.surfaceBrandBolder};
  margin: auto;
  z-index: 1;
  max-width: 180rem;
`;

export const WhatWeTreatInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACER.xlarge} ${SPACER.medium};
  width: 100%;
  ${MEDIA_QUERIES.mdUp} {
    padding: 0 ${SPACER.xlarge};
  }
  ${MEDIA_QUERIES.xlUp} {
    max-width: 81rem;
    padding: 0 4.5rem;
  }
  ${MEDIA_QUERIES.xxlUp} {
    padding: 0;
  }
`;

export const WhatWeTreatHeaderContainer = styled.h2`
  color: ${LOCAL_COLORS.textInverse};
  font-size: 2rem;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.64px;
  padding-bottom: ${SPACER.large};
  width: 75%;
  ${MEDIA_QUERIES.xlUp} {
    font-size: 3.5rem;
    max-width: 56.25rem;
    width: 100%;
  }
`;

export const WhatWeTreatCardsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  ${MEDIA_QUERIES.lgUp} {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const whatWeTreatbuttonStyles = css`
  max-width: 100%;
  button {
    margin-top: ${SPACER.medium};
  }
  ${MEDIA_QUERIES.smUp} {
    margin: 0;
    width: 17rem;
  }
`;

export const WhatWeTreatHairPromoBadgeContainer = styled.div`
  display: inline-block;
  position: relative;
`;

export const WhatWeTreatHairPromoPercentageText = styled.div`
  color: ${LOCAL_COLORS.textInverse};
  font-size: 1.5rem;
  font-weight: 700;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  ${MEDIA_QUERIES.mdUp} {
    font-size: 2rem;
  }
`;

export const WhatWeTreatHairPromoForJustText = styled.div`
  color: ${LOCAL_COLORS.textInverse};
  font-weight: 700;
  font-size: 0.6rem;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 55%;
  transform: translate(-50%, -50%);
  div {
    line-height: 0.25rem;
  }
  span:last-of-type {
    font-size: 1.125rem;
    vertical-align: sub;
    ${MEDIA_QUERIES.mdUp} {
      font-size: 1.725rem;
      line-height: 2.725rem;
    }
  }
  ${MEDIA_QUERIES.mdUp} {
    font-size: 0.9rem;
  }
`;
