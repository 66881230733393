import React from 'react';
import { Helmet } from 'react-helmet';

import type { QuestionAnswerPair } from '..';

export const FaqSchema = ({ faqs }: { faqs: QuestionAnswerPair[] }) => {
  const schemaObject = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.map(({ question, answerString }) => ({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answerString,
      },
    })),
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaObject)}</script>
    </Helmet>
  );
};
