import { EVENTS } from 'consts/metricsTrackingEvents';
import {
  DISCLAIMER_CONSULTATION_CANCEL,
  CTA_SHOP_NOW,
  CTA_START_YOUR_SKIN_QUIZ,
  CTA_TRY_PRESCRIPTION_SKINCARE,
  CTA_GET_ROUTINE_ESSENTIALS,
} from 'consts/copy';
import APP_URLS from 'consts/appUrls';
import ECOMMERCE_URLS from 'consts/eCommerceUrls';

import { ButtonType } from './CtaPanel';

export const PRESCRIPTION_SKINCARE_PANEL_DEFAULT = {
  buttonType: 'primary' as ButtonType,
  ctaButton: {
    link: APP_URLS.welcome,
    buttonText: CTA_START_YOUR_SKIN_QUIZ,
  },
  disclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
  event: EVENTS.skinQuizCtaClicked,
  headerText: CTA_TRY_PRESCRIPTION_SKINCARE,
};

export const ROUTINE_ESSENTIALS_PANEL_DEFAULT = {
  buttonType: 'secondary' as ButtonType,
  ctaButton: {
    link: ECOMMERCE_URLS.main,
    buttonText: CTA_SHOP_NOW,
  },
  event: EVENTS.shopCtaClicked,
  headerText: CTA_GET_ROUTINE_ESSENTIALS,
};

export const DEFAULT_SIDE_PANEL_CONTENT = [
  PRESCRIPTION_SKINCARE_PANEL_DEFAULT,
  ROUTINE_ESSENTIALS_PANEL_DEFAULT,
];
