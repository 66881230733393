import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { formatDate } from 'utils/formatDate';
import type { LegalPageEntry } from 'schema';
import { RICH_TEXT_OPTIONS } from './LegalPage.richText';
import { LegalPage } from '.';

export const LegalPageContentful = ({
  _updatedAt,
  bodyContent,
  ...props
}: LegalPageEntry) => (
  <LegalPage
    {...props}
    datePublished={formatDate(new Date(_updatedAt))}
    bodyContent={documentToReactComponents(bodyContent, RICH_TEXT_OPTIONS)}
  />
);
