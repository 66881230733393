import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';
import { css } from '@emotion/react';

import { ReactComponent as Splash } from './images/splash.svg';
import { ReactComponent as SplashSmall } from './images/splashSmall.svg';
import { ReactComponent as Wordmark } from './images/wordmark.svg';

export const StyledLink = styled(Link)`
  text-decoration: none;
  position: relative;
  display: flex;
  border-radius: ${SPACER.small};
  border: 1px solid ${({ theme }) => theme.COLORS.border};
  background-color: ${LOCAL_COLORS.surfaceBrandBold};
  overflow: hidden;
`;

export const ImageBackground = styled.div`
  position: absolute;
`;

const splashBackground = css`
  position: absolute;
  top: -2px;
  right: 0;
`;

export const SplashBackground = styled(Splash)`
  ${splashBackground}
`;

export const SplashBackgroundSmall = styled(SplashSmall)`
  ${splashBackground}
`;

export const TextBox = styled.div`
  z-index: 2;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${SPACER.large} ${SPACER.medium};
  gap: ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    padding: ${SPACER.large};
    flex: 2;
  }
`;

export const CtaText = css`
  color: ${LOCAL_COLORS.surfaceWhite};
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${SPACER.large};
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const WordmarkContainer = styled(Wordmark)`
  margin-left: ${SPACER.small};
`;

export const ButtonContainer = styled.div`
  button {
    padding-top: 0;
    padding-bottom: 0;
    span {
      height: auto;
    }
  }
`;
