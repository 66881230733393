import {
  ECOMMERCE_CLEANSER,
  ECOMMERCE_MOISTURIZER,
  ECOMMERCE_EMERGENCY_SPOT_PATCH,
} from './cards/products';
import type { FlexibleCardsCarouselProps } from '../FlexibleCardsCarousel';

export const FLEXIBLE_CARDS_CAROUSEL_ECOMMERCE_PRODUCTS_CONTENT: FlexibleCardsCarouselProps =
  {
    header: 'Complete your routine',
    cards: [
      ECOMMERCE_CLEANSER,
      ECOMMERCE_MOISTURIZER,
      ECOMMERCE_EMERGENCY_SPOT_PATCH,
    ],
    desktopCardSize: 'Medium',
  };
