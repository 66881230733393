import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SPACER, ANIMATION, type ThemeType } from 'radiance-ui/lib/constants';

export const MainBox = styled.section`
  display: flex;
  flex-direction: column;
`;

export const headerText = (theme: ThemeType) => css`
  padding-bottom: ${SPACER.small};
  border-bottom: 1px solid ${theme.COLORS.primary};
  font-size: ${theme.TYPOGRAPHY.fontSize.title};
`;

export const StyledLink = styled(Link)`
  line-height: 24px;
  padding: ${SPACER.small} 0;
  color: ${({ theme }) => theme.COLORS.primary};
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.primaryTint4};
  text-decoration: underline transparent;
  transition: all ${ANIMATION.defaultTiming};

  &:hover {
    text-decoration: underline ${({ theme }) => theme.COLORS.primary};
  }
`;
