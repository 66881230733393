import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS, LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';

export const PrescriptionInfoContainer = styled.section<{
  backgroundColor?: string;
}>`
  padding: ${SPACER.large} ${SPACER.large};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? LOCAL_COLORS.surfaceWhite};
  overflow: hidden;

  ${MEDIA_QUERIES.xlUp} {
    padding: ${SPACER.x5large} ${SPACER.large} ${SPACER.large};
  }

  ${MEDIA_QUERIES.xlUp} {
    padding: ${SPACER.x5large} 72px;
  }
`;

export const InnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: auto;

  ${MEDIA_QUERIES.smUp} {
    padding: 0 ${SPACER.xlarge};
  }

  ${MEDIA_QUERIES.xlUp} {
    padding: 0;
  }
`;

export const HeaderContainer = styled.div`
  font-size: ${SPACER.x2large};
  font-weight: 700;
  line-height: 130%;
  padding-bottom: ${SPACER.large};

  ${MEDIA_QUERIES.xlUp} {
    font-size: ${SPACER.x4large};
    width: 100%;
    padding-bottom: ${SPACER.xlarge};
  }
`;

export const MainContent = styled.div`
  display: flex;
  position: relative;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACER.large};
  position: relative;

  ${MEDIA_QUERIES.xlUp} {
    min-width: 65%;
  }
`;

export const IngredientTitle = styled.h2`
  font-size: ${SPACER.large};
  font-weight: 700;
  line-height: 130%;
`;

export const IngredientCards = styled.div`
  display: flex;
  gap: 0;
  flex-direction: column;
  position: relative;

  ${MEDIA_QUERIES.xlUp} {
    flex-direction: row;
  }
`;

export const CardHolder = styled.div`
  display: flex;
  position: relative;
`;

export const ProductImage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${SPACER.large};
  width: 400px;
  right: -70px;
  top: -150px;
  position: absolute;
  bottom: 0;
  pointer-events: none;

  ${MEDIA_QUERIES.mdUp} {
    top: 150px;
    height: 600px;
    width: 400px;
    right: 0px;
    pointer-events: auto;

    > div {
      height: 100%;
    }
  }

  ${MEDIA_QUERIES.xlUp} {
    margin-top: ${SPACER.x5large};
    right: unset;
    top: unset;
    position: relative;
  }
`;

export const Percentages = styled.div`
  width: 70vw;
  display: flex;
  flex-direction: column;
  gap: 86px;
  position: absolute;
  padding-top: 32px;

  ${MEDIA_QUERIES.smUp} {
    width: calc(100vw - 250px);
  }

  ${MEDIA_QUERIES.xlUp} {
    position: relative;
    width: 18vw;
    padding-top: 0;
    gap: ${SPACER.large};
  }

  @media (min-width: 1335px) {
    width: 20vw;
  }

  ${MEDIA_QUERIES.xxlUp} {
    position: relative;
    width: 330px;
    padding-top: 0;
    gap: ${SPACER.large};
  }
`;

export const Percentage = styled.div`
  border-bottom: 2px solid black;
  z-index: 0;
  left: 100%;
  text-align: right;
  padding-right: 100px;
  font-weight: 700;

  ${MEDIA_QUERIES.smUp} {
    padding-right: 150px;
  }

  ${MEDIA_QUERIES.xxlUp} {
    text-align: center;
    padding-right: 0;

    &:nth-of-type(1) {
      padding-right: 200px;
    }

    &:nth-of-type(2) {
      padding-right: 100px;
    }

    &:nth-of-type(3) {
      padding-left: 50px;
    }
  }
`;
