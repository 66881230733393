import React from 'react';

import { ReactComponent as SqueezeSVG } from './images/squeezeDivider.svg';
import { SqueezeDividerContainer } from './SqueezeDivider.style';
import { LOCAL_COLORS } from 'styles/constants';
import { mobileOnly, desktopOnly } from 'styles/mixins/displayMobileDesktop';

interface SqueezeDividerProps {
  orientation?: string;
  sectionColor?: string;
  showOnDesktop?: boolean;
  showOnMobile?: boolean;
  squeezeColor: string;
}

const moduleName = 'squeezeDivider';

export const SqueezeDivider = ({
  squeezeColor = LOCAL_COLORS.surfaceBrandBold,
  sectionColor = 'transparent',
  orientation = 'normal',
  showOnDesktop = true,
  showOnMobile = true,
}: SqueezeDividerProps) => (
  <SqueezeDividerContainer
    data-module={moduleName}
    orientation={orientation}
    sectionColor={sectionColor}
    showOnDesktop={showOnDesktop}
    showOnMobile={showOnMobile}
    squeezeColor={squeezeColor}
  >
    <SqueezeSVG viewBox="0 0 1440 130" css={desktopOnly()} />
    <SqueezeSVG viewBox="0 0 700 130" css={mobileOnly()} />
  </SqueezeDividerContainer>
);
