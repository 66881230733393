import React, { ReactNode } from 'react';

import { CtaButton, CtaButtonProps } from 'components/global/CtaButton';
import { IngredientsTabsContainer } from './components/IngredientsTabsContainer';

import {
  IngredientsTabsBody,
  IngredientsTabsContent,
  IngredientsTabsCopyAndCtaContainer,
  IngredientsTabsCopyContainer,
  IngredientsTabsSectionContainer,
  IngredientsTabsTitle,
  TabsRootDesktop,
  TabsRootMobile,
} from './IngredientsTabs.style';

export interface Ingredient {
  detailsText?: string;
  iconImage: ReactNode;
  introText?: string;
  shortName?: string;
  tags?: string[];
  title: string;
}
export interface IngredientsTabsProps {
  backgroundColor: string;
  body?: ReactNode;
  ctaButton?: CtaButtonProps;
  heading: ReactNode;
  ingredients: Ingredient[];
  ingredientsTagHeading?: string;
}

const moduleName = 'ingredientsTabs';

export const IngredientsTabs = ({
  backgroundColor,
  body,
  ctaButton,
  heading,
  ingredients,
  ingredientsTagHeading,
}: IngredientsTabsProps) => (
  <IngredientsTabsSectionContainer
    background={backgroundColor}
    data-module={moduleName}
  >
    <IngredientsTabsContent>
      <IngredientsTabsCopyAndCtaContainer>
        <IngredientsTabsCopyContainer background={backgroundColor}>
          <IngredientsTabsTitle background={backgroundColor}>
            {heading}
          </IngredientsTabsTitle>
          {body && <IngredientsTabsBody>{body}</IngredientsTabsBody>}
          <TabsRootMobile
            defaultValue={ingredients[0].shortName || ingredients[0].title}
          >
            <IngredientsTabsContainer
              ingredients={ingredients}
              tagHeading={ingredientsTagHeading}
            />
          </TabsRootMobile>
        </IngredientsTabsCopyContainer>
        {ctaButton && <CtaButton {...ctaButton} module={moduleName} />}
      </IngredientsTabsCopyAndCtaContainer>
      <TabsRootDesktop
        defaultValue={ingredients[0].shortName || ingredients[0].title}
      >
        <IngredientsTabsContainer
          ingredients={ingredients}
          tagHeading={ingredientsTagHeading}
        />
      </TabsRootDesktop>
    </IngredientsTabsContent>
  </IngredientsTabsSectionContainer>
);
