import React from 'react';
import PropTypes from 'prop-types';

import { METHOD_TYPE_ICONS } from '../../../../../methodTypes';

const ResultItem = ({ result }) => (
  <React.Fragment>
    {METHOD_TYPE_ICONS[result.type]}
    {result.short || result.title}
  </React.Fragment>
);

ResultItem.propTypes = {
  result: PropTypes.shape({
    short: PropTypes.string,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

export default ResultItem;
