/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Helmet } from 'react-helmet';
import type { ImageDataLike } from 'gatsby-plugin-image';
import { getSrc } from 'gatsby-plugin-image';

import { StaticImageType } from 'types/images';
import { SITE_URL } from 'consts/environmental';

interface StepType {
  image: StaticImageType;
  text: string;
}

export interface StepsSchemaProps {
  name: string;
  steps: StepType[];
}

export const StepsSchema = ({ name, steps }: StepsSchemaProps) => {
  const getImageUrl = (image: ImageDataLike) => {
    const imagePath = getSrc(image);
    return `${SITE_URL}${imagePath}`;
  };

  const schemaObject = {
    '@context': 'https://schema.org',
    '@type': 'HowTo',
    name,
    step: steps.map((step, index) => {
      const image = step.image.props?.__imageData;
      return {
        '@type': 'HowToStep',
        name: `Step ${index + 1}`,
        text: step.text,
        image: image && getImageUrl(image),
      };
    }),
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaObject)}</script>
    </Helmet>
  );
};
