import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS, LOCAL_SPACING } from 'styles/constants';
import getBirthControlEffectColor from '../../../getBirthControlEffectColor';
import type { MethodEffectsType } from '../../../methodEffects';

export const MainContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: ${SPACER.large};
`;

export const SvgContainer = styled.div<{ effect: MethodEffectsType }>`
  width: 50px;
  height: 50px;
  min-width: 50px;
  border: none;
  padding: 0 ${LOCAL_SPACING.xxsmall} 0 0;

  svg {
    fill: ${({ effect, theme }) => getBirthControlEffectColor(effect, theme)};
  }

  ${MEDIA_QUERIES.xlUp} {
    padding: ${LOCAL_SPACING.xxsmall};
    margin-right: ${LOCAL_SPACING.base};
    border: 1px solid;
    border-radius: 50%;
    border-color: ${({ effect, theme }) =>
      getBirthControlEffectColor(effect, theme)};
  }
`;

export const MethodInfo = styled.div`
  width: 100%;
`;

export const MethodTitle = styled.h4`
  color: ${({ theme }) => theme.COLORS.primary};
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.caption};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};
  margin-bottom: ${SPACER.xsmall};
`;

export const MethodBar = styled.div`
  background: ${LOCAL_COLORS.birthControlBarDefault};
  display: flex;
  height: 35px;
  width: 100%;
`;

export const BarFill = styled.span<{
  effect: MethodEffectsType;
  fillPercentage: number;
}>`
  font-family: ${({ theme }) => theme.FONTS.headerFont};
  color: ${({ theme }) => theme.COLORS.white};
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.caption};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: ${SPACER.xsmall};
  width: ${({ fillPercentage }) => `${fillPercentage + 3}%`};
  background: ${({ effect, theme }) =>
    getBirthControlEffectColor(effect, theme)};
`;
