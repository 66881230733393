import React from 'react';

import {
  WhatWeTreatGlyphContainer,
  WhatWeTreatImageContainer,
  WhatWeTreatNameContainer,
} from './WhatWeTreatGlyph.style';
import { WhatWeTreatGlyphProps } from './WhatWeTreatPopCard';

export const WhatWeTreatGlyph = ({
  content,
}: {
  content: WhatWeTreatGlyphProps;
}) => {
  const { name, glyph } = content;

  return (
    <WhatWeTreatGlyphContainer>
      <WhatWeTreatImageContainer>{glyph}</WhatWeTreatImageContainer>
      <WhatWeTreatNameContainer>{name}</WhatWeTreatNameContainer>
    </WhatWeTreatGlyphContainer>
  );
};
