import React from 'react';

import { ResponsiveText, mobileOnly } from 'styles/mixins/displayMobileDesktop';
import { CtaButton, type CtaButtonProps } from 'components/global/CtaButton';
import type { StaticImageType } from 'types/images';

import {
  disclaimerStyles,
  MainBox,
  Header,
  StepsBox,
  StepBox,
  StepCircle,
  StepText,
  Text,
  ctaButtonStyles,
} from './Steps.style';
import { StepsSchema } from './components/StepsSchema';

export interface StepsProps {
  header: string;
  step1Image: StaticImageType;
  step1Text: string;
  step2Image: StaticImageType;
  step2Text: string;
  step3Image: StaticImageType;
  step3Text: string;
  step4Image?: StaticImageType;
  step4Text?: string;
  ctaButton?: CtaButtonProps;
  disclaimer?: string;
}

export const Steps = ({
  header,
  step1Image,
  step1Text,
  step2Image,
  step2Text,
  step3Image,
  step3Text,
  step4Image,
  step4Text,
  ctaButton,
  disclaimer,
}: StepsProps) => {
  const steps = [
    { image: step1Image, text: step1Text },
    { image: step2Image, text: step2Text },
    { image: step3Image, text: step3Text },
  ];
  if (step4Image && step4Text)
    steps.push({ image: step4Image, text: step4Text });
  const moduleName = 'steps';

  return (
    <React.Fragment>
      <StepsSchema name={header} steps={steps} />
      <MainBox id="steps">
        <Header>{header}</Header>
        {disclaimer && <small css={disclaimerStyles}>{disclaimer}</small>}
        <StepsBox>
          {steps.map((step, index) => {
            return (
              <StepBox key={`stepImage${index + 1}`}>
                <StepCircle>{step.image as React.ReactElement}</StepCircle>
                <StepText>
                  <h2 css={mobileOnly()}>0{index + 1}</h2>
                  <Text>
                    <ResponsiveText desktop>{index + 1}.&nbsp;</ResponsiveText>
                    <span>{step.text}</span>
                  </Text>
                </StepText>
              </StepBox>
            );
          })}
        </StepsBox>
        {ctaButton && (
          <CtaButton
            {...ctaButton}
            module={moduleName}
            css={ctaButtonStyles()}
          />
        )}
      </MainBox>
    </React.Fragment>
  );
};
