import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import type { StepsProps } from '..';

export const STEPS_ANTI_AGING_MARKETING_SAFE_CONTENT: StepsProps = {
  header: 'Custom skincare in 3 steps',
  step1Image: (
    <StaticImage
      src="../images/anti-aging/step1.png"
      width={208}
      alt="phone showing Curology's skin quiz"
    />
  ),
  step1Text: 'Take a skin quiz and share photos',
  step2Image: (
    <StaticImage
      src="../images/anti-aging/step2.png"
      width={208}
      alt="patient holding Curology Custom Formula"
    />
  ),
  step2Text: 'Your custom formula is created specifically for your skin',
  step3Image: (
    <StaticImage
      src="../images/anti-aging/step3.png"
      width={208}
      alt="patient applying Curology Custom Formula"
    />
  ),
  step3Text: 'Apply nightly for happy, healthy skin',
};
