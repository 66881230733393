import React, { ReactNode, useEffect, useRef, useState } from 'react';

import {
  IngredientContainer,
  IngredientName,
  IngredientImage,
  IngredientTag,
  IngredientTags,
  IngredientDetails,
  IngredientTitle,
  TitleContainer,
  MobileDetailsContainer,
  DetailsContainer,
  CloseButton,
} from './Ingredient.style';
import { BREAKPOINTS } from 'radiance-ui/lib/constants';
import { CrossIcon } from 'radiance-ui/lib/icons';

export interface IngredientProps {
  shortName?: string;
  title: string;
  detailsText?: string;
  iconImage: ReactNode;
  tags?: string[];
  detailsAuthor?: string;
  isActive?: boolean;
  setIsActive?: (isActive: boolean) => void;
}

export const Ingredient = ({
  shortName,
  title,
  detailsText,
  iconImage,
  tags = [],
  detailsAuthor,
  isActive = false,
  setIsActive = () => undefined,
}: IngredientProps) => {
  const hasMounted = useRef(false);
  const element = useRef<HTMLLIElement>(null);
  const [isMobileActive, setMobileActive] = useState(false);

  useEffect(() => {
    // This prevents scrolling to a default active step on page load
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }

    setMobileActive(isActive);

    if (!isActive || window.innerWidth >= BREAKPOINTS.md) {
      return;
    }

    setTimeout(() => {
      element.current?.scrollIntoView({
        behavior: 'smooth',
      });
    });
  }, [isActive]);

  const handleOnClick = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    setMobileActive(false);
  };

  const handleMouseEnter = () => {
    if (window.innerWidth < BREAKPOINTS.md) return;

    setIsActive(true);
  };

  return (
    <IngredientContainer
      onClick={handleOnClick}
      active={isActive}
      onMouseEnter={handleMouseEnter}
    >
      <TitleContainer>
        <IngredientTitle>
          <IngredientName>
            {shortName && <span>{shortName}</span>}
            <span>{title}</span>
          </IngredientName>
          <IngredientImage>{iconImage}</IngredientImage>
        </IngredientTitle>
        <IngredientTags>
          {tags.map((tag, index) => {
            return <IngredientTag key={index}>{tag}</IngredientTag>;
          })}
        </IngredientTags>
      </TitleContainer>
      <MobileDetailsContainer active={isMobileActive}>
        <CloseButton onClick={handleClose}>
          <CrossIcon />
        </CloseButton>
        <IngredientDetails>{detailsText}</IngredientDetails>
        {detailsAuthor && <p>-{detailsAuthor}</p>}
      </MobileDetailsContainer>
      <DetailsContainer active={isActive}>
        <IngredientDetails>{detailsText}</IngredientDetails>
        {detailsAuthor && <p>-{detailsAuthor}</p>}
      </DetailsContainer>
    </IngredientContainer>
  );
};
