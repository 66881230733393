import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import type { VideoProps } from '..';

export const VIDEO_MOMS_OF_TEENS_CONTENT: VideoProps = {
  header: 'The top 5 (surprising) reasons your teen is breaking out',
  headerStyle: 'H3',
  body: (
    <p>
      Check out our expert-backed{' '}
      <a href="/blog/teen-acne/" target="_blank">
        blog
      </a>{' '}
      for even more sneaky causes to watch out for.
    </p>
  ),
  youtubeUrl: 'https://youtu.be/ZT_akAYloqg',
  thumbnailOverride: (
    <StaticImage
      src="../images/videoThumbnail@2x.jpg"
      alt="Surprised teen using cell phone"
    />
  ),
};
