import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { STEPS_HEADER_START_TRIAL } from 'consts/copy';

import type { StepsProps } from '..';

export const STEPS_BRAND_RESPONSE_CONTENT: StepsProps = {
  header: STEPS_HEADER_START_TRIAL,
  step1Image: (
    <StaticImage
      src="../images/brand-response/step1.jpg"
      width={208}
      alt="phone showing Curology's skin quiz"
    />
  ),
  step1Text:
    'Tell us about your skin goals, snap a few selfies, and get ready for real results',
  step2Image: (
    <StaticImage
      src="../images/brand-response/step2.jpg"
      width={208}
      alt="provider holding Curology Custom Formula"
    />
  ),
  step2Text:
    'Your provider prescribes a Custom Formula mixed with clinically-proven ingredients',
  step3Image: (
    <StaticImage
      src="../images/brand-response/step3.jpg"
      width={208}
      alt="patient applying Curology Custom Formula"
    />
  ),
  step3Text:
    'Start your treatment plan and ping your provider with questions, at any time',
};
