/* eslint-disable prefer-destructuring */
import React from 'react';
import { v4 } from 'uuid';

import { TableOfContentsLink } from './components/TableOfContentsLink';
import { TOC_NODE_TYPES } from '../../utils/tableOfContents';

const getHierarchyLevel = (node) => {
  if (!node) {
    return -1;
  }

  return Array.from(TOC_NODE_TYPES).indexOf(node.nodeType);
};

export const createTableOfContentsTree = (nodes) => {
  const rootNode = {
    value: 'root',
    parent: null,
    children: [],
  };

  rootNode.children = nodes.reduce((carry, current) => {
    const currentNode = {
      value: current,
      parent: rootNode,
      children: [],
    };

    let lastNode = carry[carry.length - 1];
    if (!lastNode) {
      carry.push(currentNode);
      return carry;
    }

    // Find the last element in the table of contents, i.e. the rightmost node in the tree.
    while (lastNode.children.length !== 0) {
      lastNode = lastNode.children[lastNode.children.length - 1];
    }

    if (
      getHierarchyLevel(currentNode.value) > getHierarchyLevel(lastNode.value)
    ) {
      currentNode.parent = lastNode;
      lastNode.children.push(currentNode);
    }

    if (
      getHierarchyLevel(currentNode.value) === getHierarchyLevel(lastNode.value)
    ) {
      currentNode.parent = lastNode.parent;

      if (lastNode.parent.value !== 'root') {
        lastNode.parent.children.push(currentNode);
      } else {
        carry.push(currentNode);
      }
    }

    if (
      getHierarchyLevel(currentNode.value) < getHierarchyLevel(lastNode.value)
    ) {
      let parent = lastNode.parent;

      while (
        getHierarchyLevel(currentNode.value) <= getHierarchyLevel(parent.value)
      ) {
        parent = parent.parent;
      }

      currentNode.parent = parent;

      if (parent.value !== 'root') {
        parent.children.push(currentNode);
      } else {
        carry.push(currentNode);
      }
    }

    return carry;
  }, []);

  return rootNode;
};

export const createNestedList = ({ nodes, pathname }) => (
  <ul key={v4()}>
    {nodes.map((node) =>
      node.children.length === 0 ? (
        <TableOfContentsLink key={v4()} node={node.value} pathname={pathname} />
      ) : (
        <div key={v4()}>
          <TableOfContentsLink node={node.value} pathname={pathname} />
          {createNestedList({ nodes: node.children, pathname })}
        </div>
      ),
    )}
  </ul>
);

export const parseTOCIndex = (index) => {
  if (!index.length) {
    return -1;
  }
  return parseInt(index.split('-')[1], 10);
};
