import React from 'react';

import PillsSection from './pillsSection';
import GraphSection from './graphSection';

export const BirthControlAndAcne = () => (
  <React.Fragment>
    <PillsSection />
    <GraphSection />
  </React.Fragment>
);
