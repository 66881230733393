import React from 'react';

import { ContentfulImage } from 'components/global/ContentfulImage';
import { Quote } from '.';
import type { QuoteEntry } from 'schema';

export const ContentfulQuote = ({ author, ...props }: QuoteEntry) => (
  <Quote
    {...props}
    author={{
      name: author.name,
      credentials: author.credentials,
      avatar: author.avatar ? (
        <ContentfulImage image={author.avatar} />
      ) : undefined,
      bio: author.bio,
    }}
  />
);
