import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';
import { Typography } from 'radiance-ui';

import { LOCAL_COLORS } from 'styles/constants';

export const PersonContainer = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  grid-column-gap: ${SPACER.medium};
  margin-top: ${SPACER.xlarge};
  align-items: center;
`;

export const PersonName = styled(Typography.Caption)`
  color: ${LOCAL_COLORS.corePurple600};
  font-weight: bold;
  margin-top: -${SPACER.small};
  margin-bottom: ${SPACER.small};
`;

export const BylineText = styled(Typography.Label)`
  font-size: 14px;
  margin-bottom: 4px;
`;
