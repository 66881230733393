import React from 'react';

import { WhatWeTreatPop } from '.';
import type { WhatWeTreatPopEntry } from 'schema';
import { SmartImage } from 'components/global/SmartImage';

import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';
import { useHairRxPromoVariant } from 'utils/hooks/useHairPromoVariation';
import { WhatWeTreatHairPromoBadge } from './components/WhatWeTreatHairPromoBadge';

export const ContentfulWhatWeTreatPop = ({
  services,
  ...props
}: WhatWeTreatPopEntry) => {
  const chiaWelcomeOfferVariant = useHairRxPromoVariant();

  return (
    <WhatWeTreatPop
      services={services.map(
        ({
          promo,
          treatmentCategoryId,
          desktopImage,
          mobileImage,
          glyphs,
          ...props
        }) => ({
          promo:
            chiaWelcomeOfferVariant && props.headline === 'Hair growth' ? (
              <WhatWeTreatHairPromoBadge />
            ) : (
              promo && <SmartImage {...promo} />
            ),
          image: (
            <React.Fragment>
              <SmartImage css={desktopOnly()} {...desktopImage} />
              <SmartImage css={mobileOnly()} {...mobileImage} />
            </React.Fragment>
          ),
          glyphs: glyphs.map(({ media, header }) => ({
            glyph: <SmartImage {...media} />,
            name: header,
          })),
          treatmentCategoryId,
          ...props,
        }),
      )}
      {...props}
    />
  );
};
