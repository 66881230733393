import React from 'react';

import { CtaButton, CtaButtonProps } from 'components/global/CtaButton';
import { SYNTHETIC_TEST_IDS } from 'consts/syntheticTestIds';

import {
  whatWeTreatbuttonStyles,
  WhatWeTreatCardsContainer,
  WhatWeTreatHeaderContainer,
  WhatWeTreatInnerContainer,
  WhatWeTreatMainContainer,
} from './WhatWeTreatPop.style';
import {
  WhatWeTreatPopCard,
  WhatWeTreatPopCardProps,
} from './components/WhatWeTreatPopCard';

export interface WhatWeTreatPopProps {
  header?: string;
  services: WhatWeTreatPopCardProps[];
  ctaButton?: CtaButtonProps;
}
const moduleName = 'whatWeTreatPop';
export const WhatWeTreatPop = ({
  header,
  services,
  ctaButton,
}: WhatWeTreatPopProps) => {
  return (
    <WhatWeTreatMainContainer data-module={moduleName}>
      <WhatWeTreatInnerContainer>
        {header && (
          <WhatWeTreatHeaderContainer>{header}</WhatWeTreatHeaderContainer>
        )}
        <WhatWeTreatCardsContainer>
          {services.map((serviceContent, index) => (
            <WhatWeTreatPopCard
              {...serviceContent}
              key={index}
              module={moduleName}
            />
          ))}
        </WhatWeTreatCardsContainer>
        {ctaButton && (
          <CtaButton
            {...ctaButton}
            module={moduleName}
            css={whatWeTreatbuttonStyles}
            data-synthetics-id={SYNTHETIC_TEST_IDS.bodyCta}
          />
        )}
      </WhatWeTreatInnerContainer>
    </WhatWeTreatMainContainer>
  );
};
