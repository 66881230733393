import React from 'react';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';

import { IngredientsTabs } from '.';
import type { IngredientsTabsEntry } from 'schema';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { SmartImage } from 'components/global/SmartImage';

export const ContentfulIngredientsTabs = ({
  body,
  heading,
  ingredients,
  ...props
}: IngredientsTabsEntry) => (
  <IngredientsTabs
    body={body && documentToReactComponents(body, RICH_TEXT_OPTIONS)}
    heading={documentToReactComponents(heading, RICH_TEXT_OPTIONS)}
    ingredients={ingredients.map(
      ({ iconImage, ingredientTitle, ...props }) => ({
        ...props,
        iconImage: iconImage && <SmartImage {...iconImage} />,
        title: ingredientTitle,
      }),
    )}
    {...props}
  />
);
