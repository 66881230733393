import {
  CARD_CAROUSEL_FREE_MONTH,
  CTA_UNLOCK_YOUR_OFFER,
  DISCLAIMER_CLINIAL_TRIAL_RETAIL,
} from 'consts/copy';
import { LOCAL_COLORS } from 'styles/constants';

import { AARON_RETAIL, CELSEY_RETAIL, YESSICA_RETAIL } from './cards/people';
import { CUSTOM_ROUTINE, PERSONALIZED_FORMULA } from './cards/subscription';
import type { FlexibleCardsCarouselProps } from '../FlexibleCardsCarousel';

export const FLEXIBLE_CARDS_CAROUSEL_RETAIL_OFFER_CONTENT: FlexibleCardsCarouselProps[] =
  [
    {
      header: 'What to expect',
      body: CARD_CAROUSEL_FREE_MONTH,
      ctaButton: {
        buttonText: CTA_UNLOCK_YOUR_OFFER,
      },
      cards: [CUSTOM_ROUTINE, PERSONALIZED_FORMULA],
      desktopCardSize: 'Large',
      backgroundColor: LOCAL_COLORS.surfaceBrandLightest,
      mobileLayout: 'Carousel',
      ctaButtonDisplay: ['Show on desktop', 'Show on mobile'],
    },
    {
      header: 'Real people, real results',
      body: 'Results don’t happen overnight, but with your personalized prescription formula you can achieve radiant skin–faster. Join the 90% who see their acne improve in just 3 weeks*.',
      ctaButton: {
        buttonText: CTA_UNLOCK_YOUR_OFFER,
        disclaimerText: DISCLAIMER_CLINIAL_TRIAL_RETAIL,
      },
      cards: [CELSEY_RETAIL, AARON_RETAIL, YESSICA_RETAIL],
      desktopCardSize: 'Medium',
      backgroundColor: LOCAL_COLORS.surfaceBrandLightest,
      mobileLayout: 'Carousel',
      ctaButtonDisplay: ['Show on desktop', 'Show on mobile'],
    },
  ];
