import React from 'react';

import { MainBox, StyledLink, headerText } from './ArticleList.style';

interface ArticleListProps {
  id?: string;
  header: string;
  articles: { title: string; path: string }[];
}

export const ArticleList = ({ id, header, articles }: ArticleListProps) => (
  <MainBox id={id}>
    <h4 css={headerText}>{header}</h4>
    {articles.map(({ title, path }) => (
      <StyledLink key={path} to={path}>
        {title}
      </StyledLink>
    ))}
  </MainBox>
);
