import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { LOCAL_COLORS } from 'styles/constants';
import { CTA_UNLOCK_YOUR_OFFER } from 'consts/copy';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

import { SPLIT_CONTENT_LAYOUT, type SplitContentProps } from '../SplitContent';

export const SPLIT_CONTENT_RETAIL_OFFER_CONTENT: SplitContentProps = {
  header: `Custom solutions for your skin’s unique needs`,
  body: 'No drugstore product is this customized. Your Curology dermatology provider prescribes a personalized formula with up to 3 active ingredients. From dark spots to acne, our simple once-a-day cream has you covered.',
  ctaButton: {
    buttonText: CTA_UNLOCK_YOUR_OFFER,
  },
  media: (
    <React.Fragment>
      <StaticImage
        src="../images/retailOfferDesktop@2x.jpg"
        alt="Curology custom formula with list of treatments: acne, dark spots, clogged pores, wrinkles, texture, redness"
        width={569}
        css={desktopOnly()}
      />
      <StaticImage
        src="../images/retailOfferMobile@2x.jpg"
        alt="Curology custom formula with list of treatments: acne, dark spots, clogged pores, wrinkles, texture, redness"
        width={767}
        css={mobileOnly()}
      />
    </React.Fragment>
  ),
  layout: {
    desktop: SPLIT_CONTENT_LAYOUT.desktop.imageLast,
    mobile: SPLIT_CONTENT_LAYOUT.mobile.imageLast,
  },
  ctaButtonDisplay: ['Show on desktop', 'Show on mobile'],
  backgroundColor: LOCAL_COLORS.surfaceBrandLight,
  fullWidthImageOnMobile: true,
};
