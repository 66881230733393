import React, { ReactNode } from 'react';
import {
  WhatWeTreatCardContainer,
  WhatWeTreatCardImageContainer,
  WhatWeTreatCardBadgeContainer,
  WhatWeTreatCardHeadline,
  WhatWeTreatCardGlyphContainer,
  WhatWeTreatCardPromoContainer,
  WhatWeTreatCardContent,
} from './WhatWeTreatPopCard.style';

import { WhatWeTreatGlyph } from './WhatWeTreatGlyph';
import { navExternalWithQueryString } from 'utils/marketingSafeDomain';
import { trackEvent } from 'utils/metricsTracking';
import { EVENTS } from 'consts/metricsTrackingEvents';
import { TREATMENT_CATEGORIES } from 'consts/treatmentCategories';

export interface WhatWeTreatGlyphProps {
  name: string;
  glyph: ReactNode;
}

export interface WhatWeTreatPopCardProps {
  image: ReactNode;
  treatmentCategoryId: number;
  module?: string;
  badge?: string;
  promo?: ReactNode;
  glyphs?: WhatWeTreatGlyphProps[];
  headline?: string;
  link?: string;
}

export const WhatWeTreatPopCard = ({
  headline,
  link,
  image,
  badge,
  promo,
  glyphs,
  module,
  treatmentCategoryId,
}: WhatWeTreatPopCardProps) => {
  const handleClick = () => {
    trackEvent(EVENTS.cardClicked, {
      path: window.location.pathname,
      module,
      treatmentCategories: TREATMENT_CATEGORIES[treatmentCategoryId],
      treatmentCategoryId,
    });

    navExternalWithQueryString(link);
  };

  return (
    <WhatWeTreatCardContainer onClick={handleClick}>
      <WhatWeTreatCardContent>
        <WhatWeTreatCardImageContainer>{image}</WhatWeTreatCardImageContainer>
        {badge && (
          <WhatWeTreatCardBadgeContainer>{badge}</WhatWeTreatCardBadgeContainer>
        )}
        {promo && (
          <WhatWeTreatCardPromoContainer>{promo}</WhatWeTreatCardPromoContainer>
        )}
        {headline && (
          <WhatWeTreatCardHeadline hasBadge={!!badge}>
            {headline}
          </WhatWeTreatCardHeadline>
        )}
      </WhatWeTreatCardContent>
      {glyphs && (
        <WhatWeTreatCardGlyphContainer>
          {glyphs.map((glyphContent: WhatWeTreatGlyphProps) => (
            <WhatWeTreatGlyph key={glyphContent.name} content={glyphContent} />
          ))}
        </WhatWeTreatCardGlyphContainer>
      )}
    </WhatWeTreatCardContainer>
  );
};
