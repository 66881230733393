import React from 'react';

import { PAGE_URLS } from 'consts/pageUrls';

import * as Style from './SideNav.style';

export const SideNav = () => (
  <Style.NavContainer>
    <Style.StyledLink to={PAGE_URLS.terms}>Terms of Service</Style.StyledLink>
    <Style.StyledLink to={PAGE_URLS.disclaimer}>Disclaimer</Style.StyledLink>
    <Style.StyledLink to={PAGE_URLS.privacy}>Privacy Notice</Style.StyledLink>
    <Style.NestedNavList>
      <Style.NestedNavItem>
        <Style.StyledLink to={PAGE_URLS.statePrivacyNoticeAddenda}>
          State Privacy Notice Addenda
        </Style.StyledLink>
      </Style.NestedNavItem>
      <Style.NestedNavItem>
        <Style.StyledLink to={PAGE_URLS.washingtonHealthPrivacy}>
          Washington Health Privacy Notice
        </Style.StyledLink>
      </Style.NestedNavItem>
      <Style.NestedNavItem>
        <Style.StyledLink to={PAGE_URLS.nevadaHealthPrivacy}>
          Nevada Health Privacy Notice
        </Style.StyledLink>
      </Style.NestedNavItem>
    </Style.NestedNavList>
    <Style.StyledLink to={PAGE_URLS.telehealthConsent}>
      Telehealth Consent
    </Style.StyledLink>
    <Style.StyledLink to={PAGE_URLS.guardianTelehealthConsent}>
      Parent/Guardian Telehealth Consent
    </Style.StyledLink>
  </Style.NavContainer>
);
