import React, { ReactNode } from 'react';
import { ReactComponent as Arrow } from './images/arrow.svg';
import {
  Categories,
  Category,
  CategoryHeadline,
  CategoryImage,
  CategoryTag,
  Headline,
  InnerContainer,
  OuterContainer,
  Subheadline,
  ctaStyles,
} from './MultiCategoryHero.style';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

import { LOCAL_COLORS } from 'styles/constants';
import { HOMEPAGE_MULTICATEGORY_CONTENT } from './content';
import { trackEvent } from 'utils/metricsTracking';
import { EVENTS } from 'consts/metricsTrackingEvents';
import { Link } from 'gatsby';
import { CtaButton, CtaButtonProps } from 'components/global/CtaButton';

export interface MultiCategoryHeroProps {
  headline?: string;
  subheadline?: React.ReactNode;
  ctaButton?: CtaButtonProps;
  categories?: {
    desktopImage: ReactNode;
    mobileImage: ReactNode;
    headline: string;
    link: string;
    tag?: string;
  }[];
  backgroundColor?: string;
}

const moduleName = 'multiCategoryHero';

export const MultiCategoryHero = ({
  headline = HOMEPAGE_MULTICATEGORY_CONTENT.headline,
  subheadline = HOMEPAGE_MULTICATEGORY_CONTENT.subheadline,
  ctaButton = HOMEPAGE_MULTICATEGORY_CONTENT.ctaButton,
  categories = HOMEPAGE_MULTICATEGORY_CONTENT.categories,
  backgroundColor = LOCAL_COLORS.surfaceBrandBold,
}: MultiCategoryHeroProps) => {
  const handleRxProductClick = () => {
    trackEvent(EVENTS.linkClicked, { productName: name, module: moduleName });
  };

  return (
    <OuterContainer backgroundColor={backgroundColor} data-module={moduleName}>
      <InnerContainer>
        <div>
          <Headline>{headline}</Headline>
          <Subheadline>{subheadline}</Subheadline>
          {ctaButton && (
            <CtaButton
              {...ctaButton}
              className={'multi-category-hero-cta'}
              module={moduleName}
              css={ctaStyles}
            />
          )}
        </div>
        <Categories>
          {categories?.map(
            ({ desktopImage, mobileImage, headline, tag, link }, index) => (
              <Link key={index} to={link} onClick={handleRxProductClick}>
                <Category>
                  <CategoryImage css={[desktopOnly('block', 'lgUp')]}>
                    {desktopImage}
                  </CategoryImage>
                  <CategoryImage css={[mobileOnly('block', 'lgUp')]}>
                    {mobileImage}
                  </CategoryImage>
                  <CategoryHeadline>
                    {headline} <Arrow />
                  </CategoryHeadline>
                  {tag && <CategoryTag>{tag}</CategoryTag>}
                </Category>
              </Link>
            ),
          )}
        </Categories>
      </InnerContainer>
    </OuterContainer>
  );
};
