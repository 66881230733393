import React from 'react';

import type { MethodEffectsType } from './methodEffects';
import METHOD_EFFECTS from './methodEffects';
import type { MethodTypesType } from './methodTypes';
import { METHOD_TYPES } from './methodTypes';
import type { BrandsType } from './brandsData';
import BRANDS from './brandsData';

export interface BirthControlType {
  name: string;
  type: MethodTypesType;
  brands: Array<BrandsType>;
  ingredients?: string;
  examples: string;
  percentages: Array<number>;
  width: number;
  description: Array<React.ReactElement>;
}
export const METHODS_DATA: Record<
  MethodEffectsType,
  Array<BirthControlType> | undefined
> = {
  [METHOD_EFFECTS.BETTER]: [
    {
      name: 'DRSP+EE Pill',
      type: METHOD_TYPES.PILL,
      brands: [BRANDS.YAZ, BRANDS.OCELLA, BRANDS.YASMIN],
      ingredients: 'Drospirenone, Ethinyl estradiol',
      examples:
        'Beyaz, Gianvi, Loryna, Nikki, Ocella, Safyral, Syeda, Vestura, Yasmin, Yaz, Zarah',
      percentages: [3, 31, 40, 26],
      width: 90,
      description: [
        <p key={0}>
          Birth control pills containing drospirenone and ethinyl estradiol
          appear to be the most effective contraceptives for improving acne.
          Drospirenone works against the male-like hormones that can make acne
          worse and has low androgenicity (male-hormone-like effects), so it can
          significantly improve hormonal acne in some women.
        </p>,
        <p key={1}>
          Birth control pills are not suitable for all women, especially those
          who are over 35 and smoke or have a history of stroke or blood clots.
        </p>,
      ],
    },
    {
      name: 'NGM+EE Pill',
      type: METHOD_TYPES.PILL,
      brands: [BRANDS.MONO_NESSA, BRANDS.TRI_NESSA],
      ingredients: 'Norgestimate, Ethinyl estradiol',
      examples:
        'Ortho Tri-Cyclen, Ortho Tri-Cyclen Lo, Sprintec, Tri-Sprintec, TriNessa, Mononessa, MonoLinyah, Tri-Linyah, Ortho Cyclen, Previfem, Tri-Previfem, Tri-Estarylla',
      percentages: [6, 41, 36, 17],
      width: 78,
      description: [
        <p key={0}>
          Birth control pills that contain norgestimate and ethinyl estradiol
          are fairly good for acne, as norgestimate has minimal
          male-hormone-like effects, and ethinyl estradiol can help block the
          production of male hormones. The only birth control pills that tend to
          be better for acne are those containing the drospirenone (Yaz, Yazmin,
          etc.). When there is an option between triphasic (as in Ortho
          Tri-Cyclen) and monophasic (Ortho-Cyclen) progestin doses, acne
          sufferers may benefit more from a triphasic pill, although these
          monophasic pills can also be very helpful for acne.
        </p>,
        <p key={1}>
          Birth control pills are not suitable for all women, especially those
          who are over 35 and smoke or have a history of stroke or blood clots.
        </p>,
      ],
    },
    {
      name: 'DSG+EE Pill',
      type: METHOD_TYPES.PILL,
      brands: [BRANDS.APRI, BRANDS.RECLIPSEN],
      ingredients: 'Desogestrel, Ethinyl estradiol',
      examples:
        'Apri, Reclipsen, Azurette, Mircette, Novynette, Velivet, Desogen, Emoquette, Enskyse, Marvelon, Ortho-Cept 28, Kariva, Pimtrea, Viorele',
      percentages: [2, 53, 29, 15],
      width: 70,
      description: [
        <p key={0}>
          Birth control pills that contain desogestrel and ethinyl estradiol are
          neither the best nor the worst for the skin. In some women, these
          pills can help acne, though generally, not as much as pills with
          drospirenone.
        </p>,
        <p key={1}>
          Birth control pills are not suitable for all women, especially those
          who are over 35 and smoke or have a history of stroke or blood clots.
        </p>,
      ],
    },
    {
      name: 'Vaginal Ring',
      type: METHOD_TYPES.RING,
      brands: [BRANDS.NUVA_RING],
      ingredients: 'Etonogestrel, Ethinyl estradiol',
      examples: 'NuvaRing',
      percentages: [4, 62, 19, 14],
      width: 60,
      description: [
        <p key={0}>
          The vaginal ring (NuvaRing) contains etonorgestrel and ethinyl
          estradiol. It is inserted into the vagina, where the hormones are
          released over three weeks, and then it is removed for one week.
          Etonorgestrel has moderate male-hormone-like effects, so the NuvaRing
          tends to help the skin more than norethindrone- and
          levonorgestrel-containing pills, but not as much as drospirenone-,
          norgestimate-, and desogestrel-containing pills (which do not tend to
          have male-hormone-like effects).
        </p>,
        <p key={1}>
          NuvaRing may not be suitable for all women, especially those who are
          over 35 and smoke or have a history of stroke or blood clots.
        </p>,
      ],
    },
  ],
  [METHOD_EFFECTS.NONE]: [
    {
      name: 'NE+EE Pill',
      type: METHOD_TYPES.PILL,
      brands: [BRANDS.MICROGESTIN, BRANDS.LO_LOESTRIN_FE],
      ingredients: 'Norethindrone, Ethinyl estradiol',
      examples:
        'Microgestin, Loestrin, Loestrin Lo, Junel, Gildess, Larin, Lomedia, Generess, Balziva, Zenchent, Necon, Cyclafem, Nortrel, Ortho-Novum, Alyacen, Tri-Norinyl, Tilia, Tri-Legest',
      percentages: [8, 62, 22, 8],
      width: 69,
      description: [
        <p key={0}>
          Birth control pills that contain norethindrone and ethinyl estradiol
          are neither the worst nor the best for the skin. Norethindrone can
          help acne in some women, though they can also make it worse. Pills
          that contain a different progestin, such as drospirenone or
          norgestimate, can be much better for the skin.
        </p>,
        <p key={1}>
          Birth control pills are not suitable for all women, especially those
          who are over 35 and smoke or have a history of stroke or blood clots.
        </p>,
      ],
    },
    {
      name: 'Patch',
      type: METHOD_TYPES.PATCH,
      brands: [BRANDS.ORTHO_EVRA],
      ingredients: 'Norelgestromin, Ethinyl estradiol',
      examples: 'Ortho Evra, Xulane',
      percentages: [],
      width: 73,
      description: [
        <p key={0}>
          The transdermal patch (Ortho Evra, now discontinued, and Xulane)
          contains norelgestromin and ethinyl estradiol.
        </p>,
        <p key={1}>
          The number of patients using the transdermal patch in this study was
          too small to allow for detailed analysis of its effect on acne.
          However, based on this relatively small sample size, it appears that
          the patch has little (or at times, positive) effect on acne.
        </p>,
        <p key={2}>
          The Xulane patch is not suitable for all women, especially those who
          are over 35 and smoke or have a history of stroke or blood clots.
        </p>,
      ],
    },
    {
      name: 'Non-Hormonal Contraception',
      type: METHOD_TYPES.CONDOM,
      brands: [BRANDS.TROJAN, BRANDS.DUREX],
      examples:
        'Condoms, copper IUD (Paragard), vasectomy (male sterilization), tubal ligation (female sterilization)',
      percentages: [],
      width: 77,
      description: [
        <p key={0}>
          Non-hormonal intrauterine devices (IUDs), such as the copper IUD
          ParaGard, do not release any hormones, so they have no known effect,
          positive or negative, on acne. Similarly, other non-hormonal birth
          control methods such as condoms have no effect on acne. Some women may
          notice a change in their skin when they switch to a non-hormonal birth
          control method, however these types of changes are generally due to
          stopping the previous method of contraception.
        </p>,
      ],
    },
    {
      name: 'NE-Only Pill',
      type: METHOD_TYPES.PILL,
      brands: [BRANDS.JOLIVETTE],
      ingredients: 'Norethindrone',
      examples:
        'Ortho Micronor, Jolivette, Camila, Errin, Heather, Jencycla, Lyza, Micronor, Nor-QD, Nora-BE',
      percentages: [],
      width: 81,
      description: [
        <p key={0}>
          As progestin-only pills (or “minipills”) do not contain an estrogen,
          they do not tend to have a helpful effect on acne. They most commonly
          contain the progestin norethindrone, which can worsen acne due to its
          male-hormone-like effects.
        </p>,
        <p key={1}>
          The number of patients using progestin-only pills in this study was
          too small to allow for detailed analysis of their effect on acne.
          However, based on this relatively small sample size, it appears that
          they have little (or at times, negative) effect on acne.
        </p>,
      ],
    },
    {
      name: 'LNG+EE Pill',
      type: METHOD_TYPES.PILL,
      brands: [BRANDS.LEVORA, BRANDS.LUTERA],
      ingredients: 'Levonorgestrel, Ethinyl estradiol',
      examples:
        'Levora, Lutera, Amethyst, Amethia, Amethia Lo, Camrese, Camrese Lo, Daysee, Introvale, Quasense, Seasonale, Seasonique, Quartette, Alesse, Aubra, Aviane, Falmina, Lessina, Orsythia, Sronyx, Altavera, Chateal, Jolessa, Kurvelo, Levlen, Macrogynon, Microgynon 30, Neovletta, Nordette, Portia, Enpresse, Trivora',
      percentages: [10, 65, 19, 6],
      width: 85,
      description: [
        <p key={0}>
          Birth control pills that contain levonorgestrel and ethinyl estradiol
          can make acne worse because levonorgestrel can have male-hormone-like
          effects in the skin. Pills that contain a different progestin, such as
          drospirenone or norgestimate, can be much better for the skin.
        </p>,
        <p key={1}>
          Birth control pills are not suitable for all women, especially those
          who are over 35 and smoke or have a history of stroke or blood clots.
        </p>,
      ],
    },
  ],
  [METHOD_EFFECTS.WORSE]: [
    {
      name: 'Depot Injection',
      type: METHOD_TYPES.SHOT,
      brands: [BRANDS.DEPO_PROVERA],
      ingredients: 'Medroxyprogesterone acetate',
      examples: 'Depo-Provera',
      percentages: [27, 63, 5, 5],
      width: 50,
      description: [
        <p key={0}>
          The depot injection (Depo-Provera) is a progestin-only contraceptive
          injection that slowly releases the progestin medroxyprogesterone over
          a 12 week period. As medroxyprogesterone is one of the more androgenic
          (male-hormone-like) progestins, it often worsens acne in women,
          especially if used over time.
        </p>,
      ],
    },
    {
      name: 'Hormonal IUD',
      type: METHOD_TYPES.IUD,
      brands: [BRANDS.SKYLA, BRANDS.MIRENA],
      ingredients: 'Levonorgestrel',
      examples: 'Mirena, Liletta, Skyla',
      percentages: [36, 54, 7, 3],
      width: 62,
      description: [
        <p key={0}>
          Hormonal intrauterine devices (IUDs), such as Mirena, Liletta, and
          Skyla, are progestin-only contraceptives that steadily release the
          progestin levonorgestrel for up to 3 to 5 years, depending on the
          device. As levonorgestrel is one of the more androgenic
          (male-hormone-like) progestins, these hormonal IUDs often worsens acne
          in many people, and sometimes it happens gradually and almost
          imperceptibly.
        </p>,
      ],
    },
    {
      name: 'Subdermal Implant',
      type: METHOD_TYPES.IMPLANT,
      brands: [BRANDS.IMPLANON, BRANDS.NEXPLANON],
      ingredients: 'Etonorgestrel',
      examples: 'Nexplanon, Implanon (discontinued)',
      percentages: [33, 60, 7, 1],
      width: 67,
      description: [
        <p key={0}>
          Subdermal implants, such as Nexplanon, are progestin-only
          contraceptives that are inserted into the upper arm and steadily
          release the progestin etonorgestrel for up to 3 years. Like other
          progestin-only contraceptives, such as Mirena and Depo-Provera,
          subdermal implants tend to worsen acne due to their male-hormone-like
          effects in the skin.
        </p>,
      ],
    },
  ],
  [METHOD_EFFECTS.UNSET]: undefined,
};

export const METHODS_SEARCH_DATA: Array<{
  title: string;
  short?: string;
  index: number;
  methodEffect: MethodEffectsType;
  type: MethodTypesType;
}> = [
  {
    title: 'Drospirenone + Ethinyl Estradiol',
    short: 'DRSP + EE',
    index: 0,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Beyaz',
    index: 0,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Gianvi',
    index: 0,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Loryna',
    index: 0,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Nikki',
    index: 0,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Ocella',
    index: 0,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Safyral',
    index: 0,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Syeda',
    index: 0,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Vestura',
    index: 0,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Yasmin',
    index: 0,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Yaz',
    index: 0,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Zarah',
    index: 0,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Norgestimate + Ethinyl Estradiol',
    short: 'NGM + EE',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Ortho Tri-Cyclen',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Ortho Tri-Cyclen Lo',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Sprintec',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Tri-Sprintec',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'TriNessa',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Mononessa',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'MonoLinyah',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Tri-Linyah',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Ortho Cyclen',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Previfem',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Tri-Previfem',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Tri-Estarylla',
    index: 1,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Desogestrel + Ethinyl Estradiol',
    short: 'DSG + EE',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Apri',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Reclipsen',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Azurette',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Mircette',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Novynette',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Velivet',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Desogen',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Emoquette',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Enskyse',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Marvelon',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Ortho-Cept 28',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Kariva',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Pimtrea',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Viorele',
    index: 2,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'NuvaRing',
    index: 3,
    methodEffect: METHOD_EFFECTS.BETTER,
    type: METHOD_TYPES.RING,
  },
  {
    title: 'Norethindrone + Ethinyl Estradiol',
    short: 'NE + EE',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Microgestin',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Loestrin',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Loestrin Lo',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Junel',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Gildess',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Larin',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Lomedia',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Generess',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Balziva',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Zenchent',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Necon',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Cyclafem',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Nortrel',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Ortho-Novum',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Alyacen',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Tri-Norinyl',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Tilia',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Tri-Legest',
    index: 0,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Ortho Evra',
    index: 1,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PATCH,
  },
  {
    title: 'Xulane',
    index: 1,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PATCH,
  },
  {
    title: 'Condoms',
    index: 2,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.CONDOM,
  },
  {
    title: 'Copper IUD (Paragard)',
    index: 2,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.CONDOM,
  },
  {
    title: 'Vasectomy',
    index: 2,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.CONDOM,
  },
  {
    title: 'Tubal ligation',
    index: 2,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.CONDOM,
  },
  {
    title: 'Norethindrone Only',
    short: 'NE-ONLY',
    index: 3,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Ortho Micronor',
    index: 3,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Jolivette',
    index: 3,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Camila',
    index: 3,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Errin',
    index: 3,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Heather',
    index: 3,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Jencycla',
    index: 3,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Lyza',
    index: 3,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Micronor',
    index: 3,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Nor-QD',
    index: 3,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Nora-BE',
    index: 3,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Levonorgestrel + Ethinyl Estradiol',
    short: 'LNG + EE',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Levora',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Lutera',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Amethyst',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Amethia',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Amethia Lo',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Camrese',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Camrese Lo',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Daysee',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Introvale',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Quasense',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Seasonale',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Seasonique',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Quartette',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Alesse',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Aubra',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Aviane',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Falmina',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Lessina',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Orsythia',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Sronyx',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Altavera',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Chateal',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Jolessa',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Kurvelo',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Levlen',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Macrogynon',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Microgynon 30',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Neovletta',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Nordette',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Portia',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Enpresse',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Trivora',
    index: 4,
    methodEffect: METHOD_EFFECTS.NONE,
    type: METHOD_TYPES.PILL,
  },
  {
    title: 'Depo-Provera',
    index: 0,
    methodEffect: METHOD_EFFECTS.WORSE,
    type: METHOD_TYPES.SHOT,
  },
  {
    title: 'Mirena',
    index: 1,
    methodEffect: METHOD_EFFECTS.WORSE,
    type: METHOD_TYPES.IUD,
  },
  {
    title: 'Liletta',
    index: 1,
    methodEffect: METHOD_EFFECTS.WORSE,
    type: METHOD_TYPES.IUD,
  },
  {
    title: 'Skyla',
    index: 1,
    methodEffect: METHOD_EFFECTS.WORSE,
    type: METHOD_TYPES.IUD,
  },
  {
    title: 'Nexplanon',
    index: 2,
    methodEffect: METHOD_EFFECTS.WORSE,
    type: METHOD_TYPES.IMPLANT,
  },
  {
    title: 'Implanon',
    index: 2,
    methodEffect: METHOD_EFFECTS.WORSE,
    type: METHOD_TYPES.IMPLANT,
  },
];
