import React, { useState } from 'react';

import {
  QuestionContainer,
  AccordionToggleLarge,
  AccordionToggle,
  AnswerContainer,
} from './FaqItem.style';

interface FaqItemProps {
  question: string;
  answer: React.ReactNode;
}

export const FaqItem = ({ question, answer }: FaqItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <QuestionContainer onClick={() => setIsOpen(!isOpen)}>
        {question}
        <AccordionToggleLarge isOpen={isOpen} />
        <AccordionToggle isOpen={isOpen} />
      </QuestionContainer>
      <AnswerContainer isOpen={isOpen}>{answer}</AnswerContainer>
    </div>
  );
};
