import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';

export const WhatWeTreatGlyphContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${SPACER.small};
  white-space: nowrap;

  &:nth-of-type(1n + 5) {
    display: none;

    ${MEDIA_QUERIES.mdUp} {
      display: flex;
    }
  }
`;

export const WhatWeTreatImageContainer = styled.div`
  width: 2rem;
`;

export const WhatWeTreatNameContainer = styled.div`
  color: ${LOCAL_COLORS.textDefault};
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 130%;
  text-align: center;
`;
