import React from 'react';
import { v4 } from 'uuid';
import { useTheme } from '@emotion/react';

import { CtaButton, type CtaButtonProps } from 'components/global/CtaButton';

import {
  OuterContainer,
  InnerContainer,
  CopyContainer,
  ImageContainer,
  DetailItem,
  ctaButtonStyles,
  textStyles,
} from './SplitContent.style';
import { LoudCrowdCta } from 'components/global/LoudCrowdCta';

export const SPLIT_CONTENT_LAYOUT = {
  desktop: {
    imageFirst: 'Image first (desktop only)',
    imageLast: 'Image last (desktop only)',
  },
  mobile: {
    imageFirst: 'Image first (mobile only)',
    imageLast: 'Image last (mobile only)',
  },
} as const;

export const DESKTOP_LAYOUTS = [
  SPLIT_CONTENT_LAYOUT.desktop.imageFirst,
  SPLIT_CONTENT_LAYOUT.desktop.imageLast,
] as const;

export const MOBILE_LAYOUTS = [
  SPLIT_CONTENT_LAYOUT.mobile.imageFirst,
  SPLIT_CONTENT_LAYOUT.mobile.imageLast,
] as const;

export type SplitContentLayout = {
  desktop: (typeof DESKTOP_LAYOUTS)[number];
  mobile: (typeof MOBILE_LAYOUTS)[number];
};

export const CTA_BUTTON_DISPLAY_TYPES = [
  'Show on desktop',
  'Show on mobile',
] as const;

export type CtaButtonDisplayType = (typeof CTA_BUTTON_DISPLAY_TYPES)[number][];

export interface SplitContentProps {
  /** 50 characters max, preferred ~30 */
  header: string;
  /** 180 characters max, preferred ~100 */
  body: React.ReactNode;
  details?: React.ReactNode[];
  detailsIcon?: JSX.Element;
  /** Button text is required. Disclaimer text is optional */
  ctaButton?: CtaButtonProps;
  ctaButtonDisplay?: CtaButtonDisplayType;
  /** Overrides click behavior to open a LoudCrowd modal */
  loudCrowdIntegration?: boolean;
  /** Must be an image */
  media: JSX.Element;
  /** Determines the arrangement of the module content */
  layout: SplitContentLayout;
  backgroundColor?: string;
  fullWidthImageOnMobile?: boolean;
}

export const SplitContent = ({
  header,
  body,
  details,
  detailsIcon,
  ctaButton,
  ctaButtonDisplay,
  loudCrowdIntegration,
  media,
  backgroundColor,
  layout,
  fullWidthImageOnMobile = false,
}: SplitContentProps) => {
  const moduleName = 'splitContent';
  const theme = useTheme();

  const formattedBody =
    typeof body === 'string' ? (
      <p css={textStyles}>{body}</p>
    ) : (
      <div css={textStyles}>{body}</div>
    );

  const ctaButtonIsShownOnMobile = ctaButtonDisplay?.includes('Show on mobile');
  const ctaButtonIsShownOnDesktop =
    ctaButtonDisplay?.includes('Show on desktop');

  return (
    <OuterContainer
      data-module={moduleName}
      backgroundColor={backgroundColor || theme.COLORS.white}
    >
      <InnerContainer
        layout={layout.desktop}
        layoutMobile={layout.mobile}
        fullWidthImageOnMobile={fullWidthImageOnMobile}
      >
        <CopyContainer>
          {header && <h2 css={textStyles}>{header}</h2>}
          {formattedBody}
          {details && (
            <React.Fragment>
              <ul>
                {details.map((detail) => (
                  <DetailItem key={v4()}>
                    {detailsIcon}
                    <li>{detail}</li>
                  </DetailItem>
                ))}
              </ul>
            </React.Fragment>
          )}

          {loudCrowdIntegration && <LoudCrowdCta module={moduleName} />}
          {!loudCrowdIntegration && ctaButton && ctaButtonDisplay && (
            <CtaButton
              {...ctaButton}
              module={moduleName}
              css={ctaButtonStyles(
                ctaButtonIsShownOnMobile,
                ctaButtonIsShownOnDesktop,
              )}
            />
          )}
        </CopyContainer>
        <ImageContainer fullWidthImageOnMobile={fullWidthImageOnMobile}>
          {media}
        </ImageContainer>
      </InnerContainer>
    </OuterContainer>
  );
};
