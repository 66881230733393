import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import type { FlexibleCardProps } from 'components/global/FlexibleCard';

const retailPeopleCardMaxWidth = 457;

export const CELSEY_RETAIL: FlexibleCardProps = {
  title: 'Celsey',
  subtitle: '6 months on Curology',
  body: (
    <React.Fragment>
      <strong>Skin goals:</strong> breakouts, redness, clogged pores
    </React.Fragment>
  ),
  media: (
    <StaticImage
      src="../../images/people/celseyRetail@2x.jpg"
      alt="before and after of Celsey after 6 months"
      width={retailPeopleCardMaxWidth}
    />
  ),
};

export const AARON_RETAIL: FlexibleCardProps = {
  title: 'Aaron',
  subtitle: '8 months on Curology',
  body: (
    <React.Fragment>
      <strong>Skin goals:</strong> breakouts, dark spots, texture
    </React.Fragment>
  ),
  media: (
    <StaticImage
      src="../../images/people/aaronRetail@2x.jpg"
      alt="before and after of Aaron after 8 months"
      width={retailPeopleCardMaxWidth}
    />
  ),
};

export const YESSICA_RETAIL: FlexibleCardProps = {
  title: 'Yessica',
  subtitle: '7 months on Curology',
  body: (
    <React.Fragment>
      <strong>Skin goals:</strong> breakouts, redness, texture
    </React.Fragment>
  ),
  media: (
    <StaticImage
      src="../../images/people/yessicaRetail@2x.jpg"
      alt="before and after of Yessica after 7 months"
      width={retailPeopleCardMaxWidth}
    />
  ),
};
