import { BLOCKS, type Document } from '@contentful/rich-text-types';

export const TOC_NODE_TYPES = new Set([BLOCKS.HEADING_2]);

export const getTableOfContentsNodes = ({ content }: Document) => {
  return content.filter(({ nodeType }) => TOC_NODE_TYPES.has(nodeType));
};

export const enrichBodyContent = (bodyContent: Document) => {
  const enrichedContent = bodyContent.content.map((node, index) => {
    return TOC_NODE_TYPES.has(node?.nodeType)
      ? { ...node, data: { ...node?.data, tableOfContentsId: `toc-${index}` } }
      : node;
  });

  return {
    ...bodyContent,
    content: enrichedContent,
  };
};
