import styled from '@emotion/styled';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';
import { GRID, MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

export const MainContainer = styled.div<{ shouldCenterContent: boolean }>`
  padding: 39px ${SPACER.xlarge} ${SPACER.x4large} ${SPACER.xlarge};
  margin: 0 auto;
  max-width: ${({ shouldCenterContent }) =>
    shouldCenterContent ? '1000px' : GRID.xlContentMaxWidth};

  ${MEDIA_QUERIES.smUp} {
    padding-top: 26px;
  }

  ${MEDIA_QUERIES.mdUp} {
    padding-right: ${SPACER.xlarge};
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  ${MEDIA_QUERIES.mdUp} {
    flex-direction: row;
  }
`;

export const BodyContainer = styled.div<{ extraPadding: boolean }>`
  flex: 4;
  width: 100%;

  ${MEDIA_QUERIES.mdUp} {
    ${({ extraPadding }) => extraPadding && 'padding: 0 10px'}
  }
`;

export const Heading = styled.h2`
  ${({ theme }) => TYPOGRAPHY_STYLE.heading(theme)};
  margin-top: ${SPACER.x2large};
`;

export const SubHeading = styled.h3`
  ${({ theme }) => TYPOGRAPHY_STYLE.title(theme)};
  margin-top: ${SPACER.xlarge};
`;

export const Paragraph = styled.p`
  ${({ theme }) => TYPOGRAPHY_STYLE.body(theme)};
  margin: ${SPACER.medium} 0 ${SPACER.x2large} 0;
  word-break: break-word;

  em {
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: 0.25px;
  }
`;

export const UnorderedList = styled.ul`
  padding-left: ${SPACER.large};
  list-style: disc;
  margin: ${SPACER.medium} 0 0 ${SPACER.medium};

  li {
    margin: ${SPACER.medium} 0 0;
    text-indent: 0;

    > p {
      display: inline;
      margin: unset;
    }
  }
`;

export const OrderedList = styled.ol`
  margin: ${SPACER.large} 0 0 ${SPACER.large};
  counter-reset: item 0;
  padding: 0;

  li {
    text-indent: -${SPACER.large};
    list-style-type: none;
    counter-increment: item 1;
    padding: 0 0 0 ${SPACER.large};
    margin: ${SPACER.medium} 0 0;

    :before {
      display: inline-block;
      padding-right: ${SPACER.medium};
      font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};
      text-align: right;
      content: counter(item) '.';
      width: ${SPACER.large};
    }

    > p {
      display: inline;
      margin: unset;
    }
  }
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-radius: ${({ theme }) => theme.BORDER_RADIUS.medium};
  border-style: hidden;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.COLORS.default};
  margin: 1px;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.default};
`;

const BaseCell = styled.td`
  padding: ${SPACER.small} ${SPACER.medium};
  border-right: 1px solid ${({ theme }) => theme.COLORS.default};
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.default};
  min-width: 200px;
  box-sizing: border-box;

  &:last-child {
    border-right: none;
  }

  > * {
    margin: 0;
  }
`;

export const TableCell = styled(BaseCell)``;

export const TableHeaderCell = styled(BaseCell)`
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};
`;
