import React from 'react';

import { PrescriptionInfo } from '.';
import type { PrescriptionInfoEntry } from 'schema';
import { SmartImage } from 'components/global/SmartImage';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';

export const ContentfulPrescriptionInfo = ({
  providerCard,
  ingredientCards,
  productImage,
  disclaimer,
  ...props
}: PrescriptionInfoEntry) => {
  const { providerImage, cardBody, ...providerCardProps } = providerCard;
  return (
    <PrescriptionInfo
      providerCard={{
        ...providerCardProps,
        cardBody:
          cardBody && documentToReactComponents(cardBody, RICH_TEXT_OPTIONS),
        providerImage: providerImage && <SmartImage {...providerImage} />,
      }}
      productImage={<SmartImage {...productImage} />}
      ingredientCards={ingredientCards.map(
        ({ iconImage, ingredientTitle, ...props }) => ({
          ...props,
          title: ingredientTitle,
          iconImage: iconImage && <SmartImage {...iconImage} />,
        }),
      )}
      disclaimer={
        disclaimer && documentToReactComponents(disclaimer, RICH_TEXT_OPTIONS)
      }
      {...props}
    />
  );
};
