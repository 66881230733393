import React from 'react';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';

import { HowToUse } from './HowToUse';
import type { HowToUseEntry } from 'schema';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const ContentfulHowToUse = ({
  video: { youtubeUrl, youtubeUrlWithAudioDescription, transcript },
  disclaimer,
  ...props
}: HowToUseEntry) => (
  <HowToUse
    {...props}
    disclaimer={
      disclaimer && documentToReactComponents(disclaimer, RICH_TEXT_OPTIONS)
    }
    youtubeUrl={youtubeUrl}
    youtubeUrlWithAudioDescription={youtubeUrlWithAudioDescription}
    transcript={
      transcript && documentToReactComponents(transcript, RICH_TEXT_OPTIONS)
    }
  />
);
