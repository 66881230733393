import styled from '@emotion/styled';

import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import {
  LOCAL_COLORS,
  LOCAL_CONTENT_MAX_WIDTH,
  LOCAL_SPACING,
} from 'styles/constants';
import { desktopOnly } from 'styles/mixins/displayMobileDesktop';

export const OuterContainer = styled.div`
  background-color: ${({ theme }) => theme.COLORS.white};
`;

export const InnerBox = styled.div`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: auto;
  padding: ${SPACER.x3large} 0 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  ${MEDIA_QUERIES.mdUp} {
    padding: 80px ${SPACER.x5large} ${LOCAL_SPACING.desktopMainPadding}
      ${SPACER.medium};
    display: flex;
    padding: ${SPACER.x5large};
    flex-direction: row;
  }
  ${MEDIA_QUERIES.xlUp} {
    padding: 80px ${LOCAL_SPACING.desktopMainPadding}
      ${LOCAL_SPACING.desktopMainPadding};
  }
`;

export const LeftContainer = styled.div`
  padding: 0 ${SPACER.large};
  ${MEDIA_QUERIES.mdUp} {
    padding: 0;
    margin-right: ${SPACER.large};
  }
`;

export const RightContainer = styled.div`
  padding: 0 ${SPACER.large};
  width: 100%;
  margin-top: ${SPACER.large};
  ${MEDIA_QUERIES.mdUp} {
    padding: 0;
    margin-right: ${SPACER.large};
    margin-top: 0;
    width: 667px;
  }
`;

export const HeaderContainer = styled.div`
  padding: 0 ${SPACER.large};
  font-size: 36px;
  font-weight: 700;
  line-height: 160%;
  ${MEDIA_QUERIES.mdUp} {
    padding: 0;
    margin-right: ${SPACER.large};
  }
`;

export const StepsContainer = styled.div`
  padding: 0 ${SPACER.large};
  font-size: 16px;
  font-weight: 400;
  line-height: 132%;
  color: ${LOCAL_COLORS.textSubtle};
  ${MEDIA_QUERIES.mdUp} {
    padding: 0;
    margin-right: ${SPACER.large};
  }
  ol {
    list-style: decimal;
    li {
      margin-left: 25px;
    }
  }
`;

export const Step = styled.div`
  padding: 0 ${SPACER.large};
  ${MEDIA_QUERIES.mdUp} {
    padding: 0;
    margin-right: ${SPACER.large};
  }
`;

export const Disclaimer = styled.div`
  font-size: 12px;
  line-height: 132%;
  gap: 0;
`;

export const DesktopBar = styled.div`
  ${desktopOnly()};
  margin: ${SPACER.large} 0px;
  border-bottom: 2px solid black;
  width: 35px;
`;
