import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { LandingPage } from './LandingPage';
import { VariationContainerEntry } from 'schema/variationContainerSchema';
import NotFound from 'pages/404';

export const VariationContainer = ({
  variationCookie,
  variations,
}: VariationContainerEntry) => {
  const [activeVariation, setVariation] = useState('');

  const updateVariation = () => {
    const variant = Cookies.get(variationCookie);
    if (variant) {
      setVariation(variant);
      clearTimeout(failsafe);
    }
  };

  // Failsafe to ensure we always show a variation, shows control after 1 second of no action
  const failsafe = setTimeout(() => {
    if (activeVariation === '') setVariation('v0');
  }, 1000);

  useEffect(() => {
    updateVariation();

    window.addEventListener('applyVariation', updateVariation);

    return () => {
      window.removeEventListener('applyVariation', updateVariation);
    };
  }, []);

  if (
    variations[1]._type !== 'pageLandingPage' ||
    variations[0]._type !== 'pageLandingPage'
  ) {
    return <NotFound />;
  }

  return (
    <>
      {activeVariation === 'v1' && <LandingPage {...variations[1]} />}
      {activeVariation === 'v0' && <LandingPage {...variations[0]} />}
    </>
  );
};
