import React, { useEffect, useState } from 'react';
import {
  PurchaseTypeToggle,
  PurchaseTypeLine,
  SavingsText,
  StyledRadio,
  OptionText,
  ctaStyles,
  StrikeThroughText,
} from './PurchaseToggle.style';
import { CtaButton } from 'components/global/CtaButton';
import useCart from 'utils/eCommerce/cart/useCart';
import { CTA_START_YOUR_SKIN_QUIZ } from 'consts/copy';
import { trackEvent } from 'utils/metricsTracking';
import { EVENTS } from 'consts/metricsTrackingEvents';

export interface PurchaseToggleProps {
  netsuiteId:
    | 'Large Superbottle'
    | 'Large Curology Future-Proof'
    | 'Small Superbottle'
    | 'Small Curology Future-Proof';
  oneTimePrice: string;
  oneTimeText?: string;
  recurringPrice: string;
  recurringSavings: string;
  recurringFrequency: string;
}

const moduleName = 'purchaseToggle';

export const PurchaseToggle = ({
  netsuiteId,
  oneTimePrice,
  oneTimeText,
  recurringPrice,
  recurringSavings,
  recurringFrequency,
}: PurchaseToggleProps) => {
  const [purchaseType, setPurchaseType] = useState('onetime');
  const { addRxProduct } = useCart();

  useEffect(() => {
    const moduleViewedPayload = {
      path: window.location.pathname,
      module: moduleName,
    };

    trackEvent(EVENTS.moduleViewed, moduleViewedPayload);
  }, []);

  const clickEvent = (netsuiteId: string) => {
    const payload = {
      path: window.location.pathname,
      module: moduleName,
      subscription_type: '1x Rx',
    };

    trackEvent(EVENTS.atcClicked, payload);
    addRxProduct(netsuiteId);
  };

  return (
    <PurchaseTypeToggle data-module={moduleName}>
      <PurchaseTypeLine>
        <OptionText>
          <div>
            <StyledRadio
              type="radio"
              name="purchase_type"
              value={'onetime'}
              defaultChecked
              onChange={() => setPurchaseType('onetime')}
            />
            One-time purchase
          </div>
          <div>{oneTimePrice}</div>
        </OptionText>
        {oneTimeText && (
          <SavingsText>
            <div dangerouslySetInnerHTML={{ __html: oneTimeText }} />
          </SavingsText>
        )}
      </PurchaseTypeLine>
      <PurchaseTypeLine>
        <OptionText>
          <div>
            <StyledRadio
              type="radio"
              name="purchase_type"
              value={'recurring'}
              onChange={() => setPurchaseType('recurring')}
            />
            Subscribe and save
          </div>
          <div>
            <StrikeThroughText>{recurringPrice}</StrikeThroughText>
            {recurringSavings}
          </div>
        </OptionText>
        <SavingsText>
          <div dangerouslySetInnerHTML={{ __html: recurringFrequency }} />
        </SavingsText>
      </PurchaseTypeLine>
      {purchaseType === 'onetime' ? (
        <CtaButton
          buttonText={'Add to cart'}
          overrideClick={() => clickEvent(netsuiteId)}
          css={ctaStyles}
        />
      ) : (
        <CtaButton
          buttonText={CTA_START_YOUR_SKIN_QUIZ}
          css={ctaStyles}
          module={moduleName}
        />
      )}
    </PurchaseTypeToggle>
  );
};
