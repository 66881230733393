import React from 'react';

import { Steps } from './Steps';
import type { StepsEntry } from 'schema';
import { SmartImage } from 'components/global/SmartImage';

export const ContentfulSteps = ({
  step1Image,
  step2Image,
  step3Image,
  step4Image,
  ...props
}: StepsEntry) => (
  <Steps
    {...props}
    step1Image={<SmartImage {...step1Image} />}
    step2Image={<SmartImage {...step2Image} />}
    step3Image={<SmartImage {...step3Image} />}
    step4Image={step4Image && <SmartImage {...step4Image} />}
  />
);
