import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { EVENTS } from 'consts/metricsTrackingEvents';
import { trackEvent } from 'utils/metricsTracking';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';
import {
  Typography,
  Button,
  ButtonMeta,
} from '@curology/ui-components-web/react';
import { CtaButtonProps } from 'components/global/CtaButton';
import { getFormattedLink } from 'utils/getFormattedLink';
import { ARTICLE_CONVERSION_CTA_BUTTON } from 'consts/copy';
import { ContentfulImage } from 'components/global/ContentfulImage';
import { ReactComponent as Logo } from './images/invertedLogo.svg';

import {
  ButtonContainer,
  TextBox,
  LogoContainer,
  SplashBackground,
  SplashBackgroundSmall,
  ImageContainer,
  Container,
  CtaText,
  StyledLink,
  ImageBackground,
  WordmarkContainer,
} from './ArticleConversionCta.style';
import { ImageAsset } from 'schema';

interface ArticleConversionCtaProps {
  linkText: string;
  linkUrl: string;
  disclaimerText?: string;
  ctaButton?: CtaButtonProps;
  desktopImage?: ImageAsset;
  mobileImage?: ImageAsset;
}

const moduleName = 'ArticleConversionCta';

export const ArticleConversionCta = ({
  linkText,
  ctaButton,
  linkUrl,
  desktopImage,
  mobileImage,
}: ArticleConversionCtaProps) => {
  const handleCtaClick = () => {
    trackEvent(EVENTS.ctaClicked, {
      path: window.location.pathname,
      module: moduleName,
    });
  };

  const link =
    ctaButton && ctaButton.link ? getFormattedLink(ctaButton.link) : linkUrl;
  const buttonText = ctaButton?.buttonText || ARTICLE_CONVERSION_CTA_BUTTON;

  const needsFallbackImages = !desktopImage && !mobileImage;
  const imageForDesktop = desktopImage || mobileImage;
  const imageForMobile = mobileImage || desktopImage;

  return (
    <StyledLink
      data-module={moduleName}
      to={link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleCtaClick}
    >
      {needsFallbackImages ? (
        <>
          <SplashBackground css={desktopOnly()} />
          <SplashBackgroundSmall css={mobileOnly()} />
        </>
      ) : (
        <>
          {imageForDesktop && (
            <ImageBackground>
              <ContentfulImage
                image={imageForDesktop}
                alt={imageForDesktop.title}
                loading="eager"
                css={desktopOnly()}
              />
            </ImageBackground>
          )}
          {imageForMobile && (
            <ImageBackground>
              <ContentfulImage
                image={imageForMobile}
                alt={imageForMobile.title}
                loading="eager"
                css={mobileOnly()}
              />
            </ImageBackground>
          )}
        </>
      )}
      <Container>
        <TextBox>
          <LogoContainer>
            <Logo />
            <WordmarkContainer />
          </LogoContainer>
          <Typography size="h1" css={[desktopOnly(), CtaText]}>
            {linkText}
          </Typography>
          <Typography size="h2" css={[mobileOnly(), CtaText]}>
            {linkText}
          </Typography>
          <ButtonContainer>
            <Button buttonType="primary" state="dark" size="sm">
              {buttonText}
              {ctaButton?.disclaimerText && (
                <ButtonMeta>{ctaButton.disclaimerText}</ButtonMeta>
              )}
            </Button>
          </ButtonContainer>
        </TextBox>
        {needsFallbackImages && (
          <ImageContainer>
            <StaticImage
              src="./images/curologyBottleMobile@2x.png"
              alt="curology bottle"
              width={63}
              css={mobileOnly()}
            />
            <StaticImage
              src="./images/curologyBottleDesktop@2x.png"
              alt="curology bottle"
              width={90}
              layout="fixed"
              css={desktopOnly()}
            />
          </ImageContainer>
        )}
      </Container>
    </StyledLink>
  );
};
