import React from 'react';

import { CtaButton, type CtaButtonProps } from 'components/global/CtaButton';
import { LOCAL_COLORS } from 'styles/constants';

import {
  headerStyles,
  InnerContainer,
  OuterContainer,
} from './TextOnlyHero.style';

export interface TextOnlyHeroProps {
  header: string;
  backgroundColor?: string;
  ctaButton?: CtaButtonProps;
}

export const TextOnlyHero = ({
  header,
  backgroundColor = LOCAL_COLORS.surfaceWhite,
  ctaButton,
}: TextOnlyHeroProps) => {
  const moduleName = 'textOnlyHero';

  return (
    <OuterContainer data-module={moduleName} backgroundColor={backgroundColor}>
      <InnerContainer>
        <h1 css={headerStyles(backgroundColor)} className="typography-size-d1">
          {header}
        </h1>
        {ctaButton && <CtaButton {...ctaButton} module={moduleName} />}
      </InnerContainer>
    </OuterContainer>
  );
};
