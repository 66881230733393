import React from 'react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER, Z_SCALE } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE, Typography } from 'radiance-ui';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import type { Options } from '@contentful/rich-text-react-renderer';

import { ContentfulImage } from 'components/global/ContentfulImage';
import { DynamicComponent } from 'components/modules/DynamicComponent';
import { TextLink } from './components/TextLink';
import { DOT } from './constants';
import { CtaButton as Button } from 'components/global/CtaButton';

export const AdditionalInfo = styled.section`
  width: 100%;
  height: auto;
  margin-top: ${SPACER.x3large};
  z-index: ${Z_SCALE.e20};

  ${MEDIA_QUERIES.lgUp} {
    margin-top: ${SPACER.xlarge};
    width: 287px;
  }

  section:nth-of-type(1) {
    margin-bottom: ${SPACER.xlarge};
  }

  section:nth-of-type(2) {
    margin-bottom: ${SPACER.x2large};
  }
`;

export const DisclaimerText = styled.div`
  display: flex;
  margin: ${SPACER.large} ${SPACER.large} 0;
  ${({ theme }) => TYPOGRAPHY_STYLE.caption(theme)};

  ${MEDIA_QUERIES.mdUp} {
    justify-content: center;
  }
`;

export const TableOfContentsContainer = styled.div`
  max-width: 1313px;
  margin: 0 auto;
  padding: 0 ${SPACER.large};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1313px;
  margin: ${SPACER.small} auto ${SPACER.x2large};
  padding: 0 ${SPACER.large};
  word-wrap: break-word;

  ${MEDIA_QUERIES.lgUp} {
    flex-direction: row;
  }
`;

export const Body = styled.div`
  flex: 1;

  ${MEDIA_QUERIES.lgUp} {
    margin-right: ${SPACER.x5large};
  }
`;

const Heading1 = styled.h2`
  font-size: 1.5rem;
  margin-top: ${SPACER.x4large};

  ${MEDIA_QUERIES.smUp} {
    font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.display};
  }
`;

const Heading2 = styled(Typography.Heading)`
  font-size: 1.35rem;
  margin-top: ${SPACER.x3large};

  ${MEDIA_QUERIES.smUp} {
    font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.heading};
  }
`;

const Heading3 = styled(Typography.Title)`
  font-size: 1.25rem;
  margin-top: ${SPACER.x2large};

  ${MEDIA_QUERIES.smUp} {
    font-size: 1.35rem;
  }
`;

const Heading4 = styled.h4`
  font-size: 1rem;
  margin-top: ${SPACER.xlarge};
  color: ${({ theme }) => theme.COLORS.primary};

  ${MEDIA_QUERIES.smUp} {
    font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.title};
  }
`;

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.title};
  color: ${({ theme }) => theme.COLORS.primaryTint1};
  line-height: 1.5;
  margin-top: ${SPACER.xlarge};
  white-space: pre-wrap;
`;

const OrderedList = styled.ol`
  list-style-type: numbered;
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.title};
  margin: ${SPACER.large} 0 0;
  padding-left: ${SPACER.x3large};
`;

const UnorderedList = styled.ul`
  list-style-image: url(${DOT});
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.title};
  margin: ${SPACER.large} 0 0;
  padding-left: ${SPACER.xlarge};
`;

const ListItem = styled.li`
  margin-bottom: ${SPACER.small};
  color: ${({ theme }) => theme.COLORS.primaryTint1};

  > * {
    margin: 0;
    padding: 0;
  }

  > p {
    margin: unset;
  }
`;

export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    position: relative;
  }
`;

const Divider = styled.div`
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.label};
  letter-spacing: ${({ theme }) => theme.TYPOGRAPHY.fontSize.label};
  margin: ${SPACER.x4large} 0 ${SPACER.x2large};
  text-align: center;
`;
export const CtaButton = styled(Button)`
  z-index: 1000;
  position: fixed;
  right: 20px;
  bottom: 20px;
`;

export const RICH_TEXT_OPTIONS: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: ({ data }, children) => (
      <Heading1 id={data.tableOfContentsId}>{children}</Heading1>
    ),
    [BLOCKS.HEADING_2]: ({ data }, children) => (
      <Heading2 id={data.tableOfContentsId}>{children}</Heading2>
    ),
    [BLOCKS.HEADING_3]: ({ data }, children) => (
      <Heading3 id={data.tableOfContentsId}>{children}</Heading3>
    ),
    [BLOCKS.HEADING_4]: (_node, children) => <Heading4>{children}</Heading4>,
    [BLOCKS.PARAGRAPH]: (_node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.OL_LIST]: (_node, children) => (
      <OrderedList>{children}</OrderedList>
    ),
    [BLOCKS.UL_LIST]: (_node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),
    [BLOCKS.LIST_ITEM]: (_node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.HR]: () => <Divider>• • •</Divider>,
    [BLOCKS.EMBEDDED_ASSET]: ({ data }) => (
      <ContentfulImage
        image={data.target}
        css={{ marginTop: SPACER.x4large }}
      />
    ),
    [BLOCKS.EMBEDDED_ENTRY]: ({ data }) => (
      <DynamicComponent entry={data.target} />
    ),
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <TextLink href={data.uri}>{children}</TextLink>
    ),
  },
};
