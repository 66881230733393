import styled from '@emotion/styled';

import { LOCAL_COLORS } from 'styles/constants';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

export const Container = styled.div`
  padding: ${SPACER.small} ${SPACER.base};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${LOCAL_COLORS.surfaceDarkBold};
`;

export const Header = styled.p`
  font-weight: bold;
  color: ${LOCAL_COLORS.textInverse};
`;

export const Subheader = styled.p`
  font-weight: bold;
  color: ${LOCAL_COLORS.surfaceBrandLight};
`;

export const IconContainer = styled.div`
  padding: ${SPACER.xsmall};
  border-radius: 100%;
  background-color: ${LOCAL_COLORS.surfaceWhite};
  margin: 0 ${SPACER.base};
`;

export const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${MEDIA_QUERIES.mdUp} {
    flex-direction: row;
  }
`;
