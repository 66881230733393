import type { PageProps } from 'gatsby';

import {
  DynamicComponent,
  type ModuleData,
} from 'components/modules/DynamicComponent';

const ContentfulRoute = ({ pageContext }: PageProps<object, ModuleData>) => (
  <DynamicComponent entry={pageContext} />
);

export default ContentfulRoute;
