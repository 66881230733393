import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export type TableOfContentsLinkProps = {
  node: {
    content: {
      value: string;
    }[];
    data: {
      tableOfContentsId: string;
    };
    nodeType: string;
  };
  pathname: string;
  isActive: boolean;
};

export const TableOfContentsLink = ({
  node,
  pathname,
}: TableOfContentsLinkProps) => {
  const {
    data: { tableOfContentsId },
    content,
  } = node;
  const tableOfContentsTitle = content.map(({ value }) => value).join();

  return (
    <AnchorLink
      to={`${pathname}#${tableOfContentsId}`}
      title={`Jump to ${tableOfContentsTitle}`}
    >
      {tableOfContentsTitle}
    </AnchorLink>
  );
};
