import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS, LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';

export const Categories = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACER.medium};
  width: 100%;

  ${MEDIA_QUERIES.lgUp} {
    flex-direction: row;
  }
`;

export const Category = styled.div`
  background-color: ${LOCAL_COLORS.surfaceDarkBold};
  border-radius: ${SPACER.medium};
  cursor: pointer;
  flex: 1;
  position: relative;
`;

export const CategoryHeadline = styled.h3`
  color: ${LOCAL_COLORS.surfaceWhite};
  font-size: 20px;
  line-height: 125%;
  font-weight: 700;
  position: absolute;
  right: ${SPACER.medium};
  text-align: right;
  top: 20%;

  svg {
    display: none;
  }

  ${MEDIA_QUERIES.lgUp} {
    bottom: ${SPACER.medium};
    font-size: 18px;
    left: ${SPACER.small};
    text-align: left;
    top: unset;

    svg {
      display: inline-block;
    }

    br {
      display: none;
    }
  }

  ${MEDIA_QUERIES.xlUp} {
    left: ${SPACER.large};
  }
`;

export const CategoryImage = styled.div`
  height: 100%;

  & > div {
    height: 100%;
    border-radius: 16px;
  }

  img {
    object-fit: cover !important;
  }
`;

export const CategoryTag = styled.div`
  background: ${LOCAL_COLORS.surfaceOrange};
  border-radius: ${SPACER.medium} 0 0 0;
  color: ${LOCAL_COLORS.surfaceDarkBold};
  font-weight: 700;
  left: 0px;
  padding: ${SPACER.xsmall};
  position: absolute;
  top: 0px;
`;

export const Headline = styled.h1`
  color: ${LOCAL_COLORS.surfaceWhite};
  font-size: ${SPACER.x2large};
  line-height: 96%;
  letter-spacing: -1.2px;
  font-weight: 700;
  margin-bottom: ${SPACER.xlarge};
  max-width: 400px;
  text-align: center;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 60px;
    margin-bottom: ${SPACER.medium};
    text-align: left;

    span {
      font-family: var(--header-font);
    }
  }
`;

export const InnerContainer = styled.div`
  color: ${LOCAL_COLORS.surfaceWhite};
  display: flex;
  flex-direction: column;
  padding: ${SPACER.xlarge} ${SPACER.xlarge};
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: auto;
  width: 100%;

  ${MEDIA_QUERIES.lgUp} {
    padding: ${SPACER.x4large} ${SPACER.xlarge};
    flex-direction: row;
  }

  ${MEDIA_QUERIES.xlUp} {
    padding: ${SPACER.x4large} 0;
  }
`;

export const OuterContainer = styled.div<{ backgroundColor?: string }>`
  ${({ backgroundColor }) => `background: ${backgroundColor};`};
`;

export const Subheadline = styled.h2`
  color: ${LOCAL_COLORS.surfaceWhite};
  display: none;
  font-size: ${SPACER.large};

  ${MEDIA_QUERIES.lgUp} {
    display: block;
  }
`;

export const ctaStyles = css`
  font-size: ${SPACER.medium};
  padding: ${SPACER.medium} 0;
`;
