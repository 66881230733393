import { ContentfulImage } from 'components/global/ContentfulImage';
import type { ImageLinkEntry } from 'schema';
import { ImageLink } from '.';

export const ContentfulImageLink = ({ image, url }: ImageLinkEntry) => (
  <ImageLink
    url={url}
    title={image.title}
    image={<ContentfulImage image={image} />}
  />
);
