import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import {
  CTA_GET_STARTED,
  CUSTOM_FORMULA_PRICE_FOR_60_DAYS,
  DISCLAIMER_CONSULTATION,
} from 'consts/copy';
import type { ProductHeroProps } from '..';

export const PRODUCT_HERO_CUSTOM_FORMULA_CONTENT: ProductHeroProps = {
  productImage: (
    <StaticImage
      src="../images/customFormula@2x.jpg"
      alt="Custom Formula - 93% report effective"
      quality={100}
      width={560}
      height={560}
      loading="eager"
    />
  ),
  title: (
    <React.Fragment>
      Custom Formula<sup>RX</sup>
    </React.Fragment>
  ),
  price: CUSTOM_FORMULA_PRICE_FOR_60_DAYS,
  description:
    'Take on your skin goals with a powerful, personalized cream, made for you by a dermatology provider. Get a personalized acne or anti-aging formula tailored to your needs—from clearing acne and clogged pores to fading fine lines and dark spots.',
  valueProps: [
    'Clinically proven ingredients',
    'Custom acne and anti-aging treatments',
    'Free online access to a dermatology provider',
  ],
  targets: [
    'Breakouts',
    'Dark Spots',
    'Clogged Pores',
    'Firmness',
    'Wrinkles',
    'Skin Texture',
  ],
  ctaButton: {
    buttonText: CTA_GET_STARTED,
    disclaimerText: DISCLAIMER_CONSULTATION,
  },
};
