import styled from '@emotion/styled';
import { MEDIA_QUERIES } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';

import { ReactComponent as AccordionSvg } from '../images/accordion.svg';
import { ReactComponent as AccordionLargeSvg } from '../images/accordion-large.svg';

export const QuestionContainer = styled.div`
  align-items: center;
  border-top: 1px solid ${LOCAL_COLORS.textDefault};
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 8px 0 16px 0;

  &:hover {
    cursor: pointer;
  }

  ${MEDIA_QUERIES.lgUp} {
    border-top-width: 2px;
    font-size: 24px;
    padding: 16px 0 20px 0;
  }
`;

export const AccordionToggleLarge = styled(AccordionLargeSvg)<{
  isOpen: boolean;
}>`
  display: none;

  ${MEDIA_QUERIES.lgUp} {
    display: block;
    min-height: 32px;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'none')};
    margin-left: 6px;
    min-width: 32px;
  }
`;

export const AccordionToggle = styled(AccordionSvg)<{ isOpen: boolean }>`
  min-height: 24px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'none')};
  margin-left: 4px;
  min-width: 24px;

  ${MEDIA_QUERIES.lgUp} {
    display: none;
  }
`;

export const AnswerContainer = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  font-size: 12px;
  padding-bottom: 16px;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 16px;
    padding-bottom: 32px;
  }
`;
