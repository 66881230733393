import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { RICH_TEXT_OPTIONS } from 'consts/contentful';
import type { VideoEntry } from 'schema';
import { Video } from '.';

export const ContentfulVideo = ({
  body,
  video: { youtubeUrl, youtubeUrlWithAudioDescription, transcript },
  ...props
}: VideoEntry) => (
  <Video
    {...props}
    body={body && documentToReactComponents(body, RICH_TEXT_OPTIONS)}
    youtubeUrl={youtubeUrl}
    youtubeUrlWithAudioDescription={youtubeUrlWithAudioDescription}
    transcript={
      transcript && documentToReactComponents(transcript, RICH_TEXT_OPTIONS)
    }
  />
);
