import React, { useEffect } from 'react';
import { EVENTS } from 'consts/metricsTrackingEvents';
import { trackEvent } from 'utils/metricsTracking';
import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 325px;
  margin: auto;

  ${MEDIA_QUERIES.mdUp} {
    margin: 0;
    width: 272px;
    margin: 0;
  }

  button {
    font-weight: bold;
    font-size: 14px;
    border-radius: ${SPACER.x3large};
    background-color: ${LOCAL_COLORS.surfaceBrandBold};
    width: 100%;

    div {
      margin: 0;
    }
  }
`;

export const LoudCrowdCta = ({ module }: { module: string }) => {
  useEffect(() => {
    if (document.getElementById('loudcrowd-script')) return;

    const script = document.createElement('script');
    script.setAttribute('id', 'loudcrowd-script');
    script.setAttribute(
      'src',
      'https://pub.loudcrowd.com/launch.js?hid=cHJvZ3JhbV9ob3N0OjQ1Ng',
    );
    script.setAttribute('async', 'true');
    document.body.appendChild(script);
  }, []);

  const handleClick = () =>
    trackEvent(EVENTS.loudCrowdCtaClicked, {
      path: window.location.pathname,
      module,
    });

  return (
    <ButtonContainer
      onClick={handleClick}
      className="lc-signup-container"
      data-lc-button-text="Sign Up"
    />
  );
};
