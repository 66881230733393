import React from 'react';

import {
  QuoteContainer,
  InnerQuoteContainer,
  SideDesign,
  Bar,
  Dot,
  QuoteIcon,
  QuoteText,
  QuoteTextInline,
  QuoteInlineContainer,
  AuthorContainer,
  AuthorInfo,
  AuthorName,
  CredentialsContainer,
  AuthorCredentials,
  AuthorBioInlineContainer,
  AuthorBioInline,
  AuthorBio,
  BarContainer,
  AvatarContainer,
} from './Quote.style';

interface QuoteProps {
  quote: string;
  author: {
    name: string;
    credentials?: string[];
    avatar?: React.ReactNode;
    bio?: string;
  };
}

export const Quote = ({ quote, author }: QuoteProps) => {
  const hasAvatar = !!author.avatar;
  const hasBio = !!author.bio;
  const hasCredentials = !!author.credentials?.length;

  let credentials;
  if (hasCredentials && hasAvatar && hasBio) {
    credentials = author.credentials?.map((cred) => (
      <AuthorCredentials hasAvatar={hasAvatar} hasBio={hasBio} key={cred}>
        {cred}
      </AuthorCredentials>
    ));
  }
  if (hasCredentials && (!hasAvatar || !hasBio)) {
    credentials = (
      <AuthorCredentials hasAvatar={hasAvatar} hasBio={hasBio}>
        {author.credentials && author.credentials[0]}
      </AuthorCredentials>
    );
  }

  return (
    <QuoteContainer>
      <SideDesign>
        <BarContainer>
          <Bar />
        </BarContainer>
        <Dot />
      </SideDesign>
      <InnerQuoteContainer>
        <QuoteIcon>“</QuoteIcon>
        <QuoteText hasBio={hasBio}>{quote}</QuoteText>
        <AuthorContainer hasAvatar={hasAvatar} hasBio={hasBio}>
          {hasAvatar && (
            <AvatarContainer hasBio={hasBio}>{author.avatar}</AvatarContainer>
          )}
          <QuoteInlineContainer hasAvatar={hasAvatar} hasBio={hasBio}>
            <QuoteTextInline
              hasAvatar={hasAvatar}
              hasBio={hasBio}
              charCount={quote.length}
            >
              {quote}
            </QuoteTextInline>
            <AuthorInfo hasAvatar={hasAvatar} hasBio={hasBio}>
              <AuthorName hasBio={hasBio}>{author.name}</AuthorName>
              <AuthorBioInlineContainer>
                <CredentialsContainer hasAvatar={hasAvatar} hasBio={hasBio}>
                  {credentials}
                </CredentialsContainer>
                {hasAvatar && hasBio && (
                  <AuthorBioInline>{author.bio}</AuthorBioInline>
                )}
              </AuthorBioInlineContainer>
            </AuthorInfo>
          </QuoteInlineContainer>
        </AuthorContainer>
        {hasAvatar && hasBio && <AuthorBio>{author.bio}</AuthorBio>}
      </InnerQuoteContainer>
    </QuoteContainer>
  );
};
