import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';

import { LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';

import { SPLIT_CONTENT_LAYOUT } from './SplitContent';

export const OuterContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow: hidden;
`;

export const InnerContainer = styled.div<{
  layout: string;
  layoutMobile: string;
  fullWidthImageOnMobile: boolean;
}>`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: 0 auto;
  display: flex;
  flex-direction: ${({ layoutMobile }) =>
    layoutMobile === SPLIT_CONTENT_LAYOUT.mobile.imageFirst
      ? 'column-reverse'
      : 'column'};
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: ${SPACER.large} 0
    ${({ fullWidthImageOnMobile }) =>
      fullWidthImageOnMobile ? 0 : SPACER.x2large}
    0;
  gap: ${SPACER.xlarge};

  ${MEDIA_QUERIES.mdUp} {
    gap: ${SPACER.x3large};
    padding: 4.5rem 4.5rem ${SPACER.x5large} 4.5rem;
    flex-direction: ${({ layout }) =>
      layout === SPLIT_CONTENT_LAYOUT.desktop.imageFirst
        ? 'row-reverse'
        : 'row'};
  }
`;

export const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    max-width: 464px;
    padding: 0;
  }
`;

export const ImageContainer = styled.div<{ fullWidthImageOnMobile: boolean }>`
  --aspect-ratio-mobile: 325 / 325;
  --aspect-ratio-desktop: 569 / 569;
  flex: 1;
  width: 100%;
  aspect-ratio: var(--aspect-ratio-mobile);
  max-height: ${({ fullWidthImageOnMobile }) =>
    fullWidthImageOnMobile ? `767px` : `325px`};
  max-width: ${({ fullWidthImageOnMobile }) =>
    fullWidthImageOnMobile ? `767px` : `325px`};

  ${MEDIA_QUERIES.mdUp} {
    aspect-ratio: var(--aspect-ratio-desktop);
    max-width: 569px;
    max-height: 569px;
    overflow: hidden;
  }

  .gatsby-image-wrapper,
  img {
    aspect-ratio: var(--aspect-ratio-mobile);
    object-fit: cover;
    margin-top: ${SPACER.x2small};

    ${MEDIA_QUERIES.mdUp} {
      aspect-ratio: var(--aspect-ratio-desktop);
    }
  }
`;

export const DetailItem = styled.div`
  display: flex;
  margin-bottom: ${SPACER.medium};
  color: ${({ theme }) => theme.COLORS.primary};

  & > svg {
    margin-top: ${SPACER.xsmall};
    margin-right: ${SPACER.medium};
    flex-shrink: 0;
  }
`;

export const ctaButtonStyles = (
  isShownOnMobile?: boolean,
  isShownOnDesktop?: boolean,
) => css`
  display: ${isShownOnMobile ? 'grid' : 'none'};
  text-align: left;

  ${MEDIA_QUERIES.mdUp} {
    display: ${isShownOnDesktop ? 'grid' : 'none'};
  }
`;

export const textStyles = (theme: ThemeType) => css`
  color: ${theme.COLORS.primary};
  margin-bottom: ${SPACER.medium};
`;
