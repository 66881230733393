import React, { useState } from 'react';

import type { MethodEffectsType } from '../methodEffects';
import METHOD_EFFECTS from '../methodEffects';

import {
  MainContainer,
  InnerContainer,
  AxisContainer,
  AxisLabelContainer,
  AxisLabel,
  AxisBar,
} from './style';
import SearchSection from './components/searchSection';
import MethodsSection from './components/methodsSection';
import MethodDetailsModal from './components/methodDetailsModal';

interface ActiveMethodType {
  effect: MethodEffectsType;
  index: number;
}

const GraphSection = () => {
  const [methodDetailsModalIsOpen, setMethodDetailsModalIsOpen] =
    useState(false);
  const [activeMethod, setActiveMethod] = useState<ActiveMethodType>();

  const openMethodInfoModal = (method: ActiveMethodType) => {
    if (!method.effect) {
      return;
    }

    setActiveMethod(method);
    setMethodDetailsModalIsOpen(true);
  };

  const closeMethodInfoModal = () => setMethodDetailsModalIsOpen(false);

  const BETTER_TITLE = 'Improves Acne';
  const NONE_TITLE = 'Little Effect';
  const WORSE_TITLE = 'Worsens Acne';

  return (
    <React.Fragment>
      <MainContainer>
        <InnerContainer>
          <SearchSection clickHandler={openMethodInfoModal} />
          <AxisLabelContainer>
            <AxisBar />
            <AxisLabel effect={METHOD_EFFECTS.WORSE}>{WORSE_TITLE}</AxisLabel>
            <AxisLabel effect={METHOD_EFFECTS.NONE}>{NONE_TITLE}</AxisLabel>
            <AxisLabel effect={METHOD_EFFECTS.BETTER}>{BETTER_TITLE}</AxisLabel>
          </AxisLabelContainer>
          <AxisContainer>
            <MethodsSection
              effect={METHOD_EFFECTS.BETTER}
              title={BETTER_TITLE}
              clickHandler={openMethodInfoModal}
              alignSelf="flex-end"
              alignItems="flex-start"
            />
            <MethodsSection
              effect={METHOD_EFFECTS.NONE}
              title={NONE_TITLE}
              clickHandler={openMethodInfoModal}
              alignSelf="flex-end"
              alignItems="center"
            />
            <MethodsSection
              effect={METHOD_EFFECTS.WORSE}
              title={WORSE_TITLE}
              clickHandler={openMethodInfoModal}
              alignSelf="flex-start"
              alignItems="flex-end"
            />
          </AxisContainer>
        </InnerContainer>
      </MainContainer>
      {methodDetailsModalIsOpen && (
        <MethodDetailsModal
          activeMethod={activeMethod}
          onClose={closeMethodInfoModal}
        />
      )}
    </React.Fragment>
  );
};

export default GraphSection;
