import React from 'react';

import { ReactComponent as PillSVG } from './images/methods/pill.svg';
import { ReactComponent as IudSVG } from './images/methods/iud.svg';
import { ReactComponent as ImplantSVG } from './images/methods/implant.svg';
import { ReactComponent as RingSVG } from './images/methods/ring.svg';
import { ReactComponent as ShotSVG } from './images/methods/shot.svg';
import { ReactComponent as OtherSVG } from './images/methods/other.svg';
import { ReactComponent as CondomSVG } from './images/methods/condom.svg';
import { ReactComponent as PatchSVG } from './images/methods/patch.svg';

const PILL = 'pill';
const IUD = 'iud';
const IMPLANT = 'implant';
const RING = 'ring';
const SHOT = 'shot';
const OTHER = 'other';
const CONDOM = 'condom';
const PATCH = 'patch';

export const METHOD_TYPES = {
  PILL,
  IUD,
  IMPLANT,
  RING,
  SHOT,
  OTHER,
  CONDOM,
  PATCH,
} as const;

export type MethodTypesType = valueof<typeof METHOD_TYPES>;

export const METHOD_TYPE_ICONS: Record<MethodTypesType, React.ReactElement> = {
  [PILL]: <PillSVG />,
  [IUD]: <IudSVG />,
  [IMPLANT]: <ImplantSVG />,
  [RING]: <RingSVG />,
  [SHOT]: <ShotSVG />,
  [OTHER]: <OtherSVG />,
  [CONDOM]: <CondomSVG />,
  [PATCH]: <PatchSVG />,
};
