import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import SchemaError from 'components/global/errors/SchemaError';
import type { EmbeddedYoutubeVideoEntry } from 'schema';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';

import { EmbeddedYoutubeVideo } from './EmbeddedYoutubeVideo';

export const ContentfulEmbeddedYoutubeVideo = (
  props: EmbeddedYoutubeVideoEntry,
) => {
  if (props._type === 'schemaError') {
    return <SchemaError {...props} />;
  }

  const { transcript } = props;

  return (
    <EmbeddedYoutubeVideo
      {...props}
      transcript={
        transcript && documentToReactComponents(transcript, RICH_TEXT_OPTIONS)
      }
    />
  );
};
