import React, { ReactNode } from 'react';

import { trackEvent } from 'utils/metricsTracking';
import { EVENTS } from 'consts/metricsTrackingEvents';

interface ImageLinkProps {
  image: ReactNode;
  title: string;
  url: string;
}

export const ImageLink = ({ image, title, url }: ImageLinkProps) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    trackEvent(EVENTS.blogLinkClicked, {
      path: window.location.pathname,
      linkText: title,
      linkUrl: event.currentTarget.href,
      type: 'Image',
    });

    window.location.href = event.currentTarget.href;
  };

  return (
    <a href={url} onClick={handleClick}>
      {image}
    </a>
  );
};
