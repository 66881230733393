import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@gatsbyjs/reach-router';

import { PAGE_URLS } from 'consts/pageUrls';
import type { ArticleAuthorEntry } from 'schema';

export interface ArticleSchemaProps {
  articleTitle: string;
  imageSrc?: string;
  datePublished: string;
  dateModified: string;
  authors?: ArticleAuthorEntry[];
  pageUrl?: string;
}

export const ArticleSchema = ({
  articleTitle,
  imageSrc,
  datePublished,
  dateModified,
  authors,
  pageUrl,
}: ArticleSchemaProps) => {
  const formatAuthors = (authors: ArticleAuthorEntry[]) => {
    return authors
      .map(({ name, credentials }) =>
        !credentials ? name : [name, ...credentials].join(', '),
      )
      .join(', ');
  };

  const formattedAuthors = authors?.length
    ? formatAuthors(authors)
    : 'Curology Team';
  const formattedDatePublished = new Date(datePublished).toISOString();
  const formattedDateModified = new Date(dateModified).toISOString();
  const currentPageUrl = pageUrl || PAGE_URLS.baseUrl + useLocation().pathname;

  const schemaObject = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': currentPageUrl,
    },
    headline: articleTitle,
    image: [imageSrc],
    datePublished: formattedDatePublished,
    dateModified: formattedDateModified,
    author: {
      '@type': 'Person',
      name: formattedAuthors,
    },
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaObject)}</script>
    </Helmet>
  );
};
