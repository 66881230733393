import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import type { WhatWeTreatPopProps } from '../';
import APP_URLS from 'consts/appUrls';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';
import { WhatWeTreatHairPromoBadge } from '../components/WhatWeTreatHairPromoBadge';
import { TREATMENT_CATEGORY_IDS } from 'consts/treatmentCategories';

export const WHAT_WE_TREAT_POP_CONTENT: WhatWeTreatPopProps = {
  header: 'Our dermatology providers can treat:',
  services: [
    {
      link: APP_URLS.signUpSkin,
      headline: 'Face and body skincare',
      treatmentCategoryId: TREATMENT_CATEGORY_IDS.skin,
      image: (
        <React.Fragment>
          <StaticImage
            css={mobileOnly()}
            src="../images/skinServiceMobile.jpg"
            alt=""
            role="presentation"
          />
          <StaticImage
            css={desktopOnly()}
            src="../images/skinService.png"
            alt=""
            role="presentation"
          />
        </React.Fragment>
      ),
      glyphs: [
        {
          name: 'Acne',
          glyph: <StaticImage src="../images/glyphs/skin/acne.jpg" alt="" />,
        },
        {
          name: 'Clogged pores',
          glyph: (
            <StaticImage src="../images/glyphs/skin/cloggedPores.jpg" alt="" />
          ),
        },
        {
          name: 'Dark spots',
          glyph: (
            <StaticImage src="../images/glyphs/skin/darkSpots.jpg" alt="" />
          ),
        },
        {
          name: 'Fine lines',
          glyph: (
            <StaticImage src="../images/glyphs/skin/finelines.jpg" alt="" />
          ),
        },
        {
          name: 'Rosacea',
          glyph: <StaticImage src="../images/glyphs/skin/rosacea.jpg" alt="" />,
        },
        {
          name: 'Wrinkles',
          glyph: (
            <StaticImage src="../images/glyphs/skin/wrinkles.jpg" alt="" />
          ),
        },
      ],
    },
    {
      headline: 'Hair growth',
      link: APP_URLS.signUpHair,
      treatmentCategoryId: TREATMENT_CATEGORY_IDS.hair,
      image: (
        <React.Fragment>
          <StaticImage
            css={mobileOnly()}
            src="../images/hairServiceMobile.jpg"
            alt=""
            role="presentation"
          />
          <StaticImage
            css={desktopOnly()}
            src="../images/hairService.png"
            alt=""
            role="presentation"
          />
        </React.Fragment>
      ),
      badge: 'New!',
      promo: <WhatWeTreatHairPromoBadge />,
      glyphs: [
        {
          name: 'Thinning',
          glyph: (
            <StaticImage src="../images/glyphs/hair/thinning.jpg" alt="" />
          ),
        },
        {
          name: 'Bald spots',
          glyph: (
            <StaticImage src="../images/glyphs/hair/baldSpots.jpg" alt="" />
          ),
        },
        {
          name: 'Shedding',
          glyph: (
            <StaticImage src="../images/glyphs/hair/shedding.jpg" alt="" />
          ),
        },
        {
          name: 'Wide part',
          glyph: (
            <StaticImage src="../images/glyphs/hair/widePart.jpg" alt="" />
          ),
        },
        {
          name: 'Thinning ponytail',
          glyph: (
            <StaticImage
              src="../images/glyphs/hair/thinningPonytail.jpg"
              alt=""
            />
          ),
        },
        {
          name: 'Hair breakage',
          glyph: (
            <StaticImage src="../images/glyphs/hair/hairBreakage.jpg" alt="" />
          ),
        },
      ],
    },
  ],
};
