import React from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';

import { METHODS_SEARCH_DATA } from '../../../methodsData';

import {
  MainContainer,
  SearchInput,
  SearchResultsContainer,
  SearchResult,
  NoResultsMessage,
} from './style';
import ResultItem from './components/resultItem';

const NO_RESULTS = 'NO_RESULTS';

class SearchSection extends React.Component {
  static propTypes = {
    clickHandler: PropTypes.func.isRequired,
  };

  state = { searchInput: '', searchResults: [] };

  handleSearchInputChange = ({ target }) => {
    const { value } = target;

    const cleanedValue = value.toLowerCase().replace(/-|\s/g, '');

    const searchOptions = { keys: ['title', 'type'] };
    const fuzzy = new Fuse(METHODS_SEARCH_DATA, searchOptions);

    let fuzzyResult = fuzzy.search(cleanedValue).slice(0, 3);

    if (fuzzyResult.length === 0 && cleanedValue.length > 0) {
      fuzzyResult = [{ title: NO_RESULTS }];
    }

    this.setState({
      searchInput: cleanedValue,
      searchResults: fuzzyResult,
    });
  };

  render() {
    const { searchInput, searchResults } = this.state;
    const { clickHandler } = this.props;

    return (
      <MainContainer>
        <SearchInput
          type="text"
          placeholder="Search for your birth control!"
          maxLength="50"
          value={searchInput}
          onChange={this.handleSearchInputChange}
        />
        <SearchResultsContainer role="list">
          {searchResults.map((result) => (
            <SearchResult
              role="listitem"
              key={result.title}
              effect={result.methodEffect}
              onClick={() =>
                clickHandler({
                  effect: result.methodEffect,
                  index: result.index,
                })
              }
            >
              {result.title === NO_RESULTS ? (
                <NoResultsMessage>
                  We don’t have any data on {searchInput}
                </NoResultsMessage>
              ) : (
                <ResultItem result={result} />
              )}
            </SearchResult>
          ))}
        </SearchResultsContainer>
      </MainContainer>
    );
  }
}

export default SearchSection;
