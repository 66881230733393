import React, { useState } from 'react';
import { v4 } from 'uuid';
import type { Block } from '@contentful/rich-text-types';

import { TrustBadge } from 'components/modules/TrustModule';

import { TableOfContentsLink } from './components/TableOfContentsLink';
import {
  createTableOfContentsTree,
  parseTOCIndex,
} from './TableOfContents.utils';
import {
  MainContainer,
  TableOfContentsHeader,
  TableOfContentsContainer,
  Content,
  Vector,
  ContentToggle,
  Chevron,
  BadgeContainer,
} from './TableOfContents.style';

export const TableOfContents = ({
  nodes,
  pathname,
  currentTOCIndex,
  isScrollingDown,
}: {
  nodes: Block[];
  pathname: string;
  currentTOCIndex: string;
  isScrollingDown: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { children } = createTableOfContentsTree(nodes);

  return (
    <>
      <MainContainer>
        <TableOfContentsHeader>In this article</TableOfContentsHeader>
        <TableOfContentsContainer>
          {children.map((child, index) => {
            const { value } = child;
            const {
              data: { tableOfContentsId },
            } = value;

            const currentTOCIndexNumber = parseTOCIndex(currentTOCIndex);
            const tocIndexNumberOfNode = parseTOCIndex(tableOfContentsId);
            const shouldBeActive = isScrollingDown
              ? currentTOCIndexNumber >= tocIndexNumberOfNode
              : currentTOCIndexNumber > tocIndexNumberOfNode;

            return (
              <Content key={v4()} isExpanded={isExpanded} index={index}>
                <Vector isActive={shouldBeActive} />
                <TableOfContentsLink
                  key={v4()}
                  node={value}
                  pathname={pathname}
                  isActive={shouldBeActive}
                />
              </Content>
            );
          })}
          {children.length > 3 && (
            <ContentToggle onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? 'Less' : 'More'}
              <Chevron isExpanded={isExpanded} />
            </ContentToggle>
          )}
        </TableOfContentsContainer>
      </MainContainer>
      <BadgeContainer>
        <TrustBadge fill />
      </BadgeContainer>
    </>
  );
};
