import { SmartImage } from 'components/global/SmartImage';
import React from 'react';
import type { StepsPopEntry } from 'schema';
import { StepsPop } from './';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';

export const ContentfulStepsPop = ({
  subheader,
  steps,
  ...props
}: StepsPopEntry) => (
  <StepsPop
    {...props}
    subheader={
      subheader && documentToReactComponents(subheader, RICH_TEXT_OPTIONS)
    }
    steps={steps.map(({ mobileImage, desktopImage, body, ...props }) => ({
      ...props,
      mobileImage: <SmartImage {...mobileImage} />,
      desktopImage: <SmartImage {...desktopImage} />,
      body: body && documentToReactComponents(body, RICH_TEXT_OPTIONS),
    }))}
  />
);
