import React, { ReactNode } from 'react';
import { Chip } from 'radiance-ui';
import { CheckmarkIcon } from 'radiance-ui/lib/icons';
import { CtaButton, CtaButtonProps } from 'components/global/CtaButton';
import { ImportantSafetyInfoLink } from 'components/global/ImportantSafetyInfoLink';
import {
  OuterContainer,
  InfoPanel,
  InnerContainer,
  ImageContainer,
  ContentContainer,
  ValuePropsList,
  ValuePropListItem,
  checkmarkIconStyles,
  TargetsContainer,
  TargetsList,
  ChipContainer,
  titleStyles,
  priceStyles,
  PriceContainer,
  descriptionStyles,
  ctaStyles,
} from './ProductHero.style';
import { LOCAL_COLORS } from 'styles/constants';
import { ImageAsset } from 'schema';
import { ImageCarousel } from './components/ImageCarousel';

import {
  PurchaseToggle,
  PurchaseToggleProps,
} from './components/PurchaseToggle';

export type ProductHeroProps = {
  productImage?: ReactNode;
  isRx?: boolean;
  title: ReactNode;
  price?: ReactNode;
  description: ReactNode;
  valueProps?: string[];
  targets?: string[];
  ctaButton?: CtaButtonProps;
  importantSafetyInformationUrl?: string;
  theme?: string;
  valuePropCheckColor?: string;
  valuePropHeader?: string;
  targetLabelColor?: string;
  disclaimer?: string;
  images?: ImageAsset[];
  banner?: ReactNode;
  backgroundColor?: string;
  purchaseTypeToggle?: PurchaseToggleProps;
};

const moduleName = 'productHero';

export const ProductHero = ({
  productImage,
  title,
  price,
  description,
  valueProps,
  targets,
  ctaButton,
  importantSafetyInformationUrl,
  theme = 'primary',
  valuePropCheckColor,
  valuePropHeader = 'Proven to tackle',
  targetLabelColor = LOCAL_COLORS.surfaceWhite,
  disclaimer,
  images,
  banner,
  backgroundColor,
  purchaseTypeToggle,
}: ProductHeroProps) => {
  const CTA = () => {
    if (purchaseTypeToggle) return <PurchaseToggle {...purchaseTypeToggle} />;

    if (ctaButton)
      return <CtaButton module={moduleName} css={ctaStyles} {...ctaButton} />;

    return <></>;
  };

  return (
    <OuterContainer data-module={moduleName} backgroundColor={backgroundColor}>
      <InnerContainer>
        {images ? (
          <ImageCarousel images={images} />
        ) : (
          <ImageContainer>{productImage}</ImageContainer>
        )}
        <ContentContainer>
          <InfoPanel>
            <div css={titleStyles}>{title}</div>
            {price && (
              <PriceContainer>
                <div css={priceStyles} className="smallP">
                  {price}
                </div>
              </PriceContainer>
            )}
            <div css={descriptionStyles}>{description}</div>
            {theme !== 'primary' && <CTA />}
            {targets && (
              <TargetsContainer>
                <h4>{valuePropHeader}</h4>
                <TargetsList>
                  {targets.map((target) => (
                    <ChipContainer
                      key={target}
                      targetLabelColor={targetLabelColor}
                    >
                      <Chip text={target} />
                    </ChipContainer>
                  ))}
                </TargetsList>
              </TargetsContainer>
            )}
            {valueProps && (
              <ValuePropsList>
                {valueProps.map((valueProp) => (
                  <ValuePropListItem key={valueProp} themeType={theme}>
                    <CheckmarkIcon
                      css={checkmarkIconStyles}
                      color={valuePropCheckColor}
                      stroke={valuePropCheckColor}
                    />
                    {valueProp}
                  </ValuePropListItem>
                ))}
              </ValuePropsList>
            )}
            {theme === 'primary' && <CTA />}
            {disclaimer && <small>{disclaimer}</small>}
            {importantSafetyInformationUrl && (
              <ImportantSafetyInfoLink url={importantSafetyInformationUrl} />
            )}
          </InfoPanel>
          {banner}
        </ContentContainer>
      </InnerContainer>
    </OuterContainer>
  );
};
