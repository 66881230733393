import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  CTA_UNLOCK_YOUR_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';
import type { ProductHeroProps } from '..';

export const PRODUCT_HERO_CUSTOM_FORMULA_ACNE_CONTENT: ProductHeroProps = {
  productImage: (
    <StaticImage
      src="../images/customFormula@2x.jpg"
      alt="Custom Formula - 93% report effective"
      width={560}
      height={560}
      loading="eager"
    />
  ),
  isRx: true,
  title: (
    <React.Fragment>
      Custom Formula<sup>Rx</sup>
      <br />
      for acne
    </React.Fragment>
  ),
  description:
    'Help clear your skin with a personalized, Rx acne cream—prescribed for you by a dermatology provider. Your Curology Custom Formula is made with acne-busting ingredients picked for you, which can include tretinoin, azelaic acid, clindamycin, and more.',
  valueProps: [
    'Clinically proven ingredients',
    'Free online access to a dermatology provider',
    'Easy bi-monthly deliveries of your routine',
  ],
  targets: [
    'Breakouts',
    'Clogged pores',
    'Whiteheads',
    'Redness',
    'Blackheads',
  ],
  ctaButton: {
    buttonText: CTA_UNLOCK_YOUR_OFFER,
    disclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
  },
  importantSafetyInformationUrl:
    'https://support.curology.com/en_us/curology-custom-formularx-important-safety-information-H1SpxQeQa',
  purchaseTypeToggle: {
    netsuiteId: 'Small Superbottle',
    oneTimePrice: '$34.95',
    oneTimeText: 'Try it with no commitment!',
    recurringPrice: '$29.95/mo',
    recurringSavings: '$14.95',
    recurringFrequency:
      'Save 50% and get a <strong>free routine starter set!</strong>',
  },
};
