import { WorkItOutEntry } from 'schema/workItOutSchema';
import { WorkItOut } from './WorkItOut';
import { SmartImage } from 'components/global/SmartImage';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';

export const ContentfulWorkItOut = ({
  mobileImage,
  desktopImage,
  header,
  body,
  disclaimer,
  ...props
}: WorkItOutEntry) => (
  <WorkItOut
    mobileImage={<SmartImage {...mobileImage} />}
    desktopImage={<SmartImage {...desktopImage} />}
    header={documentToReactComponents(header, RICH_TEXT_OPTIONS)}
    body={body && documentToReactComponents(body, RICH_TEXT_OPTIONS)}
    disclaimer={
      disclaimer && documentToReactComponents(disclaimer, RICH_TEXT_OPTIONS)
    }
    {...props}
  />
);
