import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const APRI = 'apri';
const DEPO_PROVERA = 'depoProvera';
const DUREX = 'durex';
const IMPLANON = 'implanon';
const JOLIVETTE = 'jolivette';
const LEVORA = 'levora';
const LO_LOESTRIN_FE = 'loLoestrinFe';
const LUTERA = 'lutera';
const MICROGESTIN = 'microgestin';
const MIRENA = 'mirena';
const MONO_NESSA = 'monoNessa';
const NEXPLANON = 'nexplanon';
const NUVA_RING = 'nuvaRing';
const OCELLA = 'ocella';
const ORTHO_EVRA = 'orthoEvra';
const RECLIPSEN = 'reclipsen';
const SKYLA = 'skyla';
const TRI_NESSA = 'triNessa';
const TROJAN = 'trojan';
const YASMIN = 'yasmin';
const YAZ = 'yaz';

const BRANDS = {
  APRI,
  DEPO_PROVERA,
  DUREX,
  IMPLANON,
  JOLIVETTE,
  LEVORA,
  LO_LOESTRIN_FE,
  LUTERA,
  MICROGESTIN,
  MIRENA,
  MONO_NESSA,
  NEXPLANON,
  NUVA_RING,
  OCELLA,
  ORTHO_EVRA,
  RECLIPSEN,
  SKYLA,
  TRI_NESSA,
  TROJAN,
  YASMIN,
  YAZ,
} as const;

export type BrandsType = valueof<typeof BRANDS>;

export const brandImages: Array<{ name: BrandsType; image: JSX.Element }> = [
  {
    name: 'apri',
    image: (
      <StaticImage src="./images/brands/apri.jpg" alt="apri logo" width={35} />
    ),
  },
  {
    name: 'depoProvera',
    image: (
      <StaticImage
        src="./images/brands/depoProvera.jpg"
        alt="depoProvera logo"
        width={101}
      />
    ),
  },
  {
    name: 'durex',
    image: (
      <StaticImage
        src="./images/brands/durex.jpg"
        alt="durex logo"
        width={44}
      />
    ),
  },
  {
    name: 'implanon',
    image: (
      <StaticImage
        src="./images/brands/implanon.jpg"
        alt="implanon logo"
        width={64}
      />
    ),
  },
  {
    name: 'jolivette',
    image: (
      <StaticImage
        src="./images/brands/jolivette.jpg"
        alt="jolivette logo"
        width={80}
      />
    ),
  },
  {
    name: 'levora',
    image: (
      <StaticImage
        src="./images/brands/levora.jpg"
        alt="levora logo"
        width={60}
      />
    ),
  },
  {
    name: 'loLoestrinFe',
    image: (
      <StaticImage
        src="./images/brands/loLoestrinFe.jpg"
        alt="loLoestrinFe logo"
        width={70}
      />
    ),
  },
  {
    name: 'lutera',
    image: (
      <StaticImage
        src="./images/brands/lutera.jpg"
        alt="lutera logo"
        width={60}
      />
    ),
  },
  {
    name: 'microgestin',
    image: (
      <StaticImage
        src="./images/brands/microgestin.jpg"
        alt="microgestin logo"
        width={73}
      />
    ),
  },
  {
    name: 'mirena',
    image: (
      <StaticImage
        src="./images/brands/mirena.jpg"
        alt="mirena logo"
        width={70}
      />
    ),
  },
  {
    name: 'monoNessa',
    image: (
      <StaticImage
        src="./images/brands/monoNessa.jpg"
        alt="monoNessa logo"
        width={68}
      />
    ),
  },
  {
    name: 'nexplanon',
    image: (
      <StaticImage
        src="./images/brands/nexplanon.jpg"
        alt="nexplanon logo"
        width={69}
      />
    ),
  },
  {
    name: 'nuvaRing',
    image: (
      <StaticImage
        src="./images/brands/nuvaRing.jpg"
        alt="nuvaRing logo"
        width={91}
      />
    ),
  },
  {
    name: 'ocella',
    image: (
      <StaticImage
        src="./images/brands/ocella.jpg"
        alt="ocella logo"
        width={38}
      />
    ),
  },
  {
    name: 'orthoEvra',
    image: (
      <StaticImage
        src="./images/brands/orthoEvra.jpg"
        alt="orthoEvra logo"
        width={107}
      />
    ),
  },
  {
    name: 'reclipsen',
    image: (
      <StaticImage
        src="./images/brands/reclipsen.jpg"
        alt="reclipsen logo"
        width={64}
      />
    ),
  },
  {
    name: 'skyla',
    image: (
      <StaticImage
        src="./images/brands/skyla.jpg"
        alt="skyla logo"
        width={52}
      />
    ),
  },
  {
    name: 'triNessa',
    image: (
      <StaticImage
        src="./images/brands/triNessa.jpg"
        alt="triNessa logo"
        width={55}
      />
    ),
  },
  {
    name: 'trojan',
    image: (
      <StaticImage
        src="./images/brands/trojan.jpg"
        alt="trojan logo"
        width={72}
      />
    ),
  },
  {
    name: 'yasmin',
    image: (
      <StaticImage
        src="./images/brands/yasmin.jpg"
        alt="yasmin logo"
        width={58}
      />
    ),
  },
  {
    name: 'yaz',
    image: (
      <StaticImage src="./images/brands/yaz.jpg" alt="yaz logo" width={41} />
    ),
  },
];

export default BRANDS;
