import React from 'react';

export const LeapingBunnyBadge = () => {
  return (
    <svg
      role="img"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Leaping Bunny Certification badge</title>
      <desc>
        No new animal tests were used in the development of our products
      </desc>
      <path
        d="M0.5 35C0.5 15.9491 15.9601 0.5 34.9759 0.5C54.0403 0.5 69.5 15.9495 69.5 35C69.5 54.05 53.9927 69.5 34.9759 69.5C15.9601 69.5 0.5 54.0509 0.5 35Z"
        stroke="#1D1D44"
      />
      <g clipPath="url(#clip0_7_2268)">
        <path
          d="M54.0987 30.2054L57.1128 30.1485C55.5015 27.5936 53.3957 25.3285 50.8982 23.4641C48.0368 21.3489 44.7203 19.8097 41.1651 18.9471C37.61 18.0845 33.8961 17.9178 30.2658 18.4579C36.6911 18.1531 43.012 20.0379 48.0084 23.7486C50.4595 25.5354 52.5249 27.7252 54.0987 30.2054Z"
          fill="#332E54"
        />
        <path
          d="M22.7145 44.5697L19.8558 45.4515C23.7749 48.7329 28.6925 50.8444 33.9545 51.5049C39.2165 52.1654 44.5735 51.3438 49.3129 49.1493C43.2944 51.2216 36.6491 51.1709 30.6692 49.007C27.73 48.011 25.029 46.5042 22.7145 44.5697V44.5697Z"
          fill="#332E54"
        />
        <path
          d="M34.3048 26.0817C35.0816 26.0249 35.5166 25.712 37.5985 26.0817C37.5985 26.0533 40.4572 25.9395 45.7085 30.4906C47.2622 31.8559 48.1322 34.0746 48.9712 34.3022C49.6859 34.5013 53.0417 34.6719 53.2592 35.895C53.4146 36.919 51.1152 38.967 48.3497 38.7964C48.8469 38.1137 50.4627 37.943 51.2395 36.1226C50.3695 35.639 49.0022 35.5821 47.7283 35.9235C45.3046 30.7751 38.22 27.2195 35.7652 26.9635C33.7455 26.7075 32.9997 27.0204 31.6946 26.9635C32.5647 26.7075 33.8697 26.0817 34.3048 26.0817Z"
          fill="#332E54"
        />
        <path
          d="M25.4502 19.8531C26.4134 19.8531 26.7552 19.8246 27.7185 20.3935L25.4502 20.5642C25.388 20.5642 22.7779 21.19 21.6593 23.1526C22.1254 23.4939 22.0943 23.5793 22.5915 23.6077C23.3683 23.6077 25.2948 21.2753 28.6196 21.2753C29.02 21.3018 29.416 21.3685 29.8004 21.4744C30.1422 21.6735 30.0179 21.9864 30.0179 22.4131C30.0179 22.4131 27.9049 26.9073 24.2073 26.9073H23.3994C25.7609 26.3953 27.4389 24.3757 28.6818 22.4699L26.227 23.1242C24.518 23.5793 22.6226 25.0015 22.0011 25.0015C21.2864 25.0015 20.7893 24.4326 18.8317 24.4326C17.3091 24.4326 15.569 25.2006 14.7611 25.7126C15.134 26.4522 15.9108 26.1108 16.5323 26.0539C16.5012 26.0824 16.3148 26.7366 14.4504 26.8788C14.6819 27.7585 15.1522 28.5705 15.8176 29.2397C16.9673 30.2921 18.7384 30.8895 19.3288 30.8895C19.4162 31.7103 19.6915 32.505 20.1367 33.2219C23.0576 38.3988 24.6423 34.1321 28.1535 39.2806V39.8779C26.2581 38.4272 23.3062 37.9152 21.6904 37.6877C20.1231 37.494 18.5345 37.494 16.9673 37.6877C16.1594 37.8583 13.9532 38.2566 13.9532 38.285C15.6933 36.3508 19.8881 36.2655 20.696 35.9526C17.9306 33.421 18.2724 32.5961 17.9306 32.2264C17.682 31.9135 13.6736 31.5153 13.7046 26.4522C13.7046 24.6317 17.4955 23.2664 18.7384 23.2664C20.9446 23.3233 21.7214 19.8815 25.4502 19.8531V19.8531Z"
          fill="#332E54"
        />
        <path
          d="M19.4836 25.2298C20.0119 25.1729 19.9187 25.1445 20.6333 25.4005L20.6644 26.0262C19.9497 25.884 18.9554 25.9978 17.8989 25.9125C18.4582 25.5427 18.6136 25.2867 19.4836 25.2298V25.2298Z"
          fill="#332E54"
        />
        <path
          d="M12.4921 32.0535C14.0768 32.0535 16.0655 33.2482 16.283 33.3904C15.2265 33.4473 15.1022 33.2766 12.6786 33.4473C11.4685 33.5201 10.2646 33.6626 9.0741 33.874C10.1927 32.4802 9.97521 32.0251 12.4921 32.0535V32.0535Z"
          fill="#332E54"
        />
        <path
          d="M43.006 42.0968L45.6161 43.2346C48.6923 44.5999 51.6132 41.016 52.7318 40.3617C53.229 42.6373 55.6837 47.2453 59.226 48.127C58.7289 48.6675 58.1385 48.6106 57.4549 48.6106C55.7459 48.5821 52.4832 46.0506 52.4832 44.0879C52.4832 44.0879 52.39 42.6088 52.4211 42.2959C51.4578 44.031 49.4381 45.1404 47.7291 45.1404C46.424 45.1688 46.1443 45.055 44.9946 44.5715C44.7461 44.4577 38.5626 41.0159 38.2208 38.5697C38.5315 38.6835 41.6699 41.6133 43.006 42.0968V42.0968Z"
          fill="#332E54"
        />
        <path
          d="M54.0058 50.0615L52.9804 50.2037C50.4946 49.8339 50.0285 46.5059 50.7432 45.5957C50.9701 45.2367 51.2412 44.9027 51.5511 44.6001C51.5677 45.7094 51.703 46.8146 51.955 47.8997C52.39 48.8099 51.6443 48.4117 54.0058 50.0615V50.0615Z"
          fill="#332E54"
        />
        <path
          d="M20.1979 44.5972L18.7374 43.118L16.7177 43.8292C17.0595 43.2318 17.5256 42.7198 17.8674 42.1225C17.4324 41.6105 16.9663 41.0985 16.5002 40.5865C17.1838 40.7287 17.9917 40.8994 18.6753 41.0701L19.825 39.3918C19.9182 40.0176 19.9493 40.7003 20.0114 41.3545L22.1554 41.8949L20.0736 42.6629L20.1979 44.5972Z"
          fill="#332E54"
        />
        <path
          d="M58.667 35.8638L56.7715 34.8682L55.0936 36.1198C55.2179 35.4655 55.4665 34.8398 55.5907 34.1571C55.0004 33.7873 54.3789 33.446 53.7574 33.0762C54.4721 32.9909 55.28 32.934 55.9947 32.9055L56.5229 30.9998C56.8337 31.5687 57.0822 32.2229 57.393 32.8202L59.6302 32.7064L57.9212 34.0433L58.667 35.8638Z"
          fill="#332E54"
        />
        <path
          d="M49.6552 51.2818V50.0587H50.1835C50.2397 50.0519 50.2968 50.0566 50.3509 50.0724C50.4049 50.0881 50.4544 50.1146 50.496 50.1499C50.5375 50.1853 50.5701 50.2285 50.5912 50.2767C50.6124 50.3248 50.6217 50.3767 50.6185 50.4285C50.6225 50.4988 50.6 50.5683 50.5548 50.6253C50.5095 50.6822 50.4442 50.7232 50.3699 50.7414L50.6806 51.2818H50.401L50.1524 50.7698H49.9349V51.2818H49.6552ZM49.9038 50.5707H50.0902C50.2767 50.5707 50.3388 50.5138 50.3388 50.4C50.3388 50.2863 50.2767 50.2294 50.1213 50.2294H49.8727V50.5707H49.9038Z"
          fill="#332E54"
        />
        <path
          d="M50.0911 49.7198C50.2939 49.7198 50.4922 49.7749 50.6608 49.878C50.8294 49.9811 50.9609 50.1277 51.0385 50.2993C51.1161 50.4708 51.1364 50.6595 51.0968 50.8416C51.0573 51.0237 50.9596 51.1909 50.8162 51.3222C50.6728 51.4535 50.4901 51.5429 50.2912 51.5791C50.0923 51.6153 49.8861 51.5967 49.6987 51.5257C49.5113 51.4546 49.3512 51.3343 49.2385 51.18C49.1259 51.0256 49.0657 50.8441 49.0657 50.6585C49.0681 50.4102 49.177 50.1727 49.3687 49.9972C49.5605 49.8216 49.8199 49.722 50.0911 49.7198V49.7198ZM50.0911 49.5207C49.7532 49.5207 49.4292 49.6436 49.1903 49.8623C48.9514 50.081 48.8171 50.3776 48.8171 50.6869C48.8171 50.9962 48.9514 51.2928 49.1903 51.5115C49.4292 51.7302 49.7532 51.8531 50.0911 51.8531C50.429 51.8531 50.753 51.7302 50.992 51.5115C51.2309 51.2928 51.3651 50.9962 51.3651 50.6869C51.3651 50.3776 51.2309 50.081 50.992 49.8623C50.753 49.6436 50.429 49.5207 50.0911 49.5207Z"
          fill="#332E54"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_2268">
          <rect
            width="50.5556"
            height="33.7037"
            fill="white"
            transform="translate(9.0741 18.1481)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
