import React from 'react';
import type { ProductHeroEntry } from 'schema';
import { ProductHero } from './';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { CUSTOM_RICH_TEXT_OPTIONS } from './ProductHero.richText';
import { ProductBanner } from '../ProductBanner';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';

export const ContentfulProductHero = ({
  title,
  description,
  banner,
  ...props
}: ProductHeroEntry) => {
  let productBanner;

  if (banner) {
    const { bannerText, ...bannerProps } = banner;
    productBanner = (
      <ProductBanner
        bannerText={documentToReactComponents(bannerText, RICH_TEXT_OPTIONS)}
        {...bannerProps}
      />
    );
  }

  return (
    <ProductHero
      title={documentToReactComponents(title, CUSTOM_RICH_TEXT_OPTIONS)}
      description={documentToReactComponents(
        description,
        CUSTOM_RICH_TEXT_OPTIONS,
      )}
      banner={banner && productBanner}
      {...props}
    />
  );
};
