import React from 'react';

import { SEO } from 'components/global/SEO';
import { Nav } from 'components/global/Nav';
import { Footer } from 'components/global/Footer';
import { LandingPageWrapper } from 'components/global/LandingPageWrapper';

import type { LandingPageEntry, NavBannerEntry } from 'schema';
import { DynamicComponent } from 'components/modules/DynamicComponent';
import {
  NavBannerProps,
  formatBannerProps,
} from 'components/global/Nav/components';
import { DecagonChatbot } from 'components/global/DecagonChatbot';

export const LandingPage = ({
  metaTitle,
  metaDescription,
  indexable,
  minimalNavigation,
  bannerModules,
  heroModule,
  bodyModules,
  footerDisclaimer,
  isMarketingSafe = false,
}: LandingPageEntry) => {
  const bannerProps: NavBannerProps[] =
    bannerModules?.map((props) => formatBannerProps(props as NavBannerEntry)) ??
    [];

  return (
    <LandingPageWrapper>
      <SEO
        title={metaTitle}
        description={metaDescription}
        isIndexableOverride={indexable}
      />
      <DecagonChatbot />
      <Nav
        isMarketingSafe={isMarketingSafe}
        bannerProps={bannerProps}
        minimalNavigation={minimalNavigation}
      />
      <main id="main">
        <DynamicComponent entry={heroModule} />
        {bodyModules.map((module, index) => (
          <DynamicComponent key={index} entry={module} />
        ))}
      </main>
      <Footer
        disclaimer={
          footerDisclaimer && (
            <div
              dangerouslySetInnerHTML={{
                __html: footerDisclaimer,
              }}
            />
          )
        }
      />
    </LandingPageWrapper>
  );
};
