import styled from '@emotion/styled';
import * as Tabs from '@radix-ui/react-tabs';

import { MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const TabsList = styled(Tabs.List)`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  order: 2;

  ${MEDIA_QUERIES.mdUp} {
    flex-direction: column;
    order: 1;
  }
`;

export const TabsTrigger = styled(Tabs.Trigger)`
  align-items: center;
  border-color: ${LOCAL_COLORS.surfaceDarkBold};
  border-bottom-width: 0.125rem;
  border-left-width: 0.125rem;
  border-style: solid;
  color: ${LOCAL_COLORS.textDefault};
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  font-family: var(--header-font);
  font-size: 2rem;
  font-weight: 700;
  gap: 0.625rem;
  height: 5.375rem;
  justify-content: center;
  line-height: 90%;
  padding: 0.625rem;

  &:last-child {
    border-right-width: 0.125rem;
  }

  &[aria-selected='true'] {
    background-color: ${LOCAL_COLORS.surfaceWhite};
  }

  &[aria-selected='false'] {
    background-color: ${LOCAL_COLORS.surfaceBrandLight};
  }

  ${MEDIA_QUERIES.mdUp} {
    align-self: stretch;
    border-bottom-width: 0;
    border-top-width: 0.125rem;

    &:last-child {
      border-bottom-width: 0.125rem;
      border-right-width: 0;
    }
  }

  ${MEDIA_QUERIES.lgUp} {
    align-self: unset;
    font-size: 2.5rem;
    height: unset;
    line-height: 100%;
    padding: 1rem;
    width: 7.3125rem;
  }
`;
