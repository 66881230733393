import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const OuterContainer = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? LOCAL_COLORS.surfaceWhite};
  padding: ${SPACER.large};
  position: relative;
  overflow: hidden;

  ${MEDIA_QUERIES.mdUp} {
    margin-top: ${SPACER.x2large};
  }
`;

export const BannerText = styled.div`
  p {
    max-width: 250px;
  }
`;

export const ProductImage = styled.div`
  position: absolute;
  right: -20px;
  width: fit-content;
  height: 100%;
  bottom: 0;
  max-width: 250px;
  pointer-events: none;

  ${MEDIA_QUERIES.smUp} {
    right: 20px;
  }

  ${MEDIA_QUERIES.lgUp} {
    right: 0;
  }

  & > div {
    height: 100%;
  }

  img {
    object-fit: contain !important;
  }
`;
