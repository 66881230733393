import React from 'react';

import type { SocialMediaEmbedEntry } from 'schema';

import { SocialMediaEmbed } from '.';

export const ContentfulSocialMediaEmbed = ({ embed }: SocialMediaEmbedEntry) =>
  embed ? (
    <SocialMediaEmbed embedCode={embed.html} scriptUrl={embed.scriptUrl} />
  ) : null;
