import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  SPACER,
  MEDIA_QUERIES,
  type ThemeType,
} from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const Container = styled.div`
  flex: 1;
  position: relative;
  margin: 0 auto;
  max-width: 560px;
  padding-top: ${SPACER.medium};

  ${MEDIA_QUERIES.lgUp} {
    max-width: 50%;
    margin: 0 48px 0 0;
    padding-top: 0;
  }
`;

export const CarouselContainer = styled.div`
  max-height: 375px;
  position: relative;

  ${MEDIA_QUERIES.smUp} {
    max-height: 435px;
  }

  ${MEDIA_QUERIES.mdUp} {
    max-height: unset;
  }
`;

export const thumbnailStyles = (theme: ThemeType) => css`
  margin: ${SPACER.large} 0;

  .swiper-wrapper {
    display: flex;
    justify-content: center;
    gap: 2%;
  }

  .swiper-slide {
    max-width: 12.6%;
  }

  .swiper-slide-thumb-active {
    box-sizing: border-box;
    border: 2px solid ${theme.COLORS.primary};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  transform: translateY(70px);
  z-index: 2;
  position: relative;
  margin: auto;
  pointer-events: none;

  ${MEDIA_QUERIES.lgUp} {
    transform: translateY(75px);
  }
`;

export const ArrowButton = styled.button`
  background-color: ${LOCAL_COLORS.surfaceBrandBold};
  width: ${SPACER.x2large};
  height: ${SPACER.x2large};
  border-radius: ${SPACER.x2large};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;

  &:disabled {
    opacity: 0;
  }

  .prev-arrow {
    transform: rotate(180deg);
  }
`;
