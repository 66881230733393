import React, { ReactNode } from 'react';

import {
  ImageRow,
  BeforeAfterCaseContainer,
  MobileImageContainer,
  DesktopImageContainer,
  AfterLabel,
  BeforeLabel,
  MaxWidthContainer,
  CtaContainer,
} from './BeforeAfterCaseSingle.style';
import { Disclaimer } from '../BeforeAfterSingle.style';
import { CtaButton, CtaButtonProps } from 'components/global/CtaButton';

export interface BeforeAfterCaseProps {
  beforeLabel: string | 'Before';
  afterLabel: string;
  beforeImageMobile: ReactNode;
  beforeImageDesktop: ReactNode;
  afterImageMobile: ReactNode;
  afterImageDesktop: ReactNode;
  disclaimer?: string;
  theme?: string;
  ctaButton: CtaButtonProps;
}

export const BeforeAfterCaseSingle = ({
  beforeLabel,
  afterLabel,
  beforeImageMobile,
  beforeImageDesktop,
  afterImageMobile,
  afterImageDesktop,
  disclaimer,
  theme = 'primary',
  ctaButton,
}: BeforeAfterCaseProps) => (
  <BeforeAfterCaseContainer>
    <ImageRow themeType={theme}>
      <DesktopImageContainer>
        {beforeImageDesktop}
        <BeforeLabel>{beforeLabel}</BeforeLabel>
      </DesktopImageContainer>
      <DesktopImageContainer>
        {afterImageDesktop}
        <AfterLabel>{afterLabel}</AfterLabel>
      </DesktopImageContainer>
      <MobileImageContainer>
        {beforeImageMobile}
        <BeforeLabel>{beforeLabel}</BeforeLabel>
      </MobileImageContainer>
      <MobileImageContainer>
        {afterImageMobile}
        <AfterLabel>{afterLabel}</AfterLabel>
      </MobileImageContainer>
    </ImageRow>
    <Disclaimer>{disclaimer}</Disclaimer>
    {ctaButton && (
      <CtaContainer>
        <CtaButton css={MaxWidthContainer} {...ctaButton} />
      </CtaContainer>
    )}
  </BeforeAfterCaseContainer>
);
