import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { SmartImage } from 'components/global/SmartImage';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';
import { getFormattedLink } from 'utils/getFormattedLink';
import type { FlexibleCardsCarouselEntry } from 'schema';
import { FlexibleCardsCarousel } from '.';

export const ContentfulFlexibleCardsCarousel = ({
  body,
  cards,
  ...props
}: FlexibleCardsCarouselEntry) => (
  <FlexibleCardsCarousel
    {...props}
    body={body && documentToReactComponents(body, RICH_TEXT_OPTIONS)}
    cards={cards.map(({ media, body, hoverMedia, link, ...props }) => ({
      media: <SmartImage {...media} layout="fullWidth" />,
      hoverMedia: hoverMedia && (
        <SmartImage {...hoverMedia} layout="fullWidth" />
      ),
      body: documentToReactComponents(body, RICH_TEXT_OPTIONS),
      link: link && getFormattedLink(link),
      ...props,
    }))}
  />
);
