import React from 'react';
import PropTypes from 'prop-types';

import { METHOD_TYPE_ICONS } from '../../../methodTypes';

import {
  MainContainer,
  SvgContainer,
  MethodInfo,
  MethodTitle,
  MethodBar,
  BarFill,
} from './style';

const BarItem = ({ stats }) => {
  const { type, effect, title, percent } = stats;

  return (
    <MainContainer key={type}>
      <SvgContainer effect={effect}>{METHOD_TYPE_ICONS[type]}</SvgContainer>
      <MethodInfo>
        <MethodTitle>{title}</MethodTitle>
        <MethodBar>
          <BarFill effect={effect} fillPercentage={percent}>
            {percent}%
          </BarFill>
        </MethodBar>
      </MethodInfo>
    </MainContainer>
  );
};

BarItem.propTypes = {
  stats: PropTypes.shape({
    type: PropTypes.string.isRequired,
    effect: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    percent: PropTypes.number.isRequired,
  }),
};

export default BarItem;
