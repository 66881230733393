import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

export const VideoParentContainer = styled.div`
  width: 100%;
  max-width: 800px;
`;

export const VideoContainer = styled.div<{ showThumbnail: boolean }>`
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    visibility: ${({ showThumbnail }) =>
      showThumbnail ? 'hidden' : 'visible'};
  }
`;

export const VideoThumbnail = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: 0;
  outline: none;

  img {
    width: 100%;
  }

  &:hover > span {
    background-color: ${({ theme }) => theme.COLORS.primaryTint3};
  }
`;

export const PlayButton = styled.span`
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.white};
  transition: background-color 200ms ease-in-out;
  border-radius: 100%;
  position: absolute;

  svg {
    margin-left: 5px;
  }
`;

export const TranscriptBox = styled.div`
  margin: ${SPACER.medium} ${SPACER.large};
  color: ${({ theme }) => theme.COLORS.primaryTint2};
  padding-bottom: ${SPACER.small};
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.defaultBorder};

  ${MEDIA_QUERIES.mdUp} {
    margin: ${SPACER.medium} 0;
  }
`;

export const transcriptTitleStyles = (theme: ThemeType) => css`
  padding: ${SPACER.small} 0;
  color: ${theme.COLORS.primary};
`;

export const audioDescriptionButton = css`
  margin-left: ${SPACER.large};
  margin-top: ${SPACER.small};
  display: flex;
  align-items: center;
  gap: ${SPACER.small};

  ${MEDIA_QUERIES.mdUp} {
    margin-left: unset;
  }
  :hover {
    opacity: 0.8;
  }
`;
