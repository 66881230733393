import React from 'react';
import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';

import type { DisclaimerEntry } from 'schema';

const Container = styled.section`
  margin: ${SPACER.xlarge} 0 ${SPACER.x2large};
`;

const Divider = styled.div`
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.label};
  letter-spacing: ${({ theme }) => theme.TYPOGRAPHY.fontSize.label};
  margin: ${SPACER.x4large} 0 ${SPACER.x2large};
  text-align: center;
`;

const Disclaimer = styled.div`
  font-style: italic;
  color: ${({ theme }) => theme.COLORS.primaryTint2};

  &:not(:last-of-type) {
    margin-bottom: ${SPACER.medium};
  }
`;

type DisclaimerSectionProps = {
  disclaimers: DisclaimerEntry[];
};

export const DisclaimerSection = ({ disclaimers }: DisclaimerSectionProps) => (
  <Container>
    <Divider>• • •</Divider>
    {disclaimers.map((disclaimer) => (
      <Disclaimer key={disclaimer.name}>
        {disclaimer.preface && <strong>{disclaimer.preface}&ensp;</strong>}
        <span>{disclaimer.text}</span>
      </Disclaimer>
    ))}
  </Container>
);
