import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import type { StepsProps } from '..';

export const STEPS_CORE_CONTENT: StepsProps = {
  header: 'Just-right skincare, delivered right away',
  step1Image: (
    <StaticImage
      src="../images/core/step1.png"
      width={208}
      alt="phone showing Curology's skin quiz"
    />
  ),
  step1Text: 'Snap selfies and share your skin goals',
  step2Image: (
    <StaticImage
      src="../images/core/step2.png"
      width={208}
      alt="patient dispensing Curology Custom Formula"
    />
  ),
  step2Text: 'Your dermatology provider prescribes your custom bottle',
  step3Image: (
    <StaticImage
      src="../images/core/step3.png"
      width={208}
      alt="patient applying Curology Custom Formula"
    />
  ),
  step3Text: 'Apply nightly. Get your glow on.',
};
