import React from 'react';

import METHOD_GRAPH_STATS from '../methodGraphsStats';
import DonutChart from './components/donutChart';
import BarItem from './components/barItem';
import { LOCAL_COLORS } from 'styles/constants';

import {
  MainContainer,
  InnerContainer,
  Title,
  FlexContainer,
  BarItemsContainer,
  GraphsContainer,
  Citation,
} from './style';

const GraphSection = () => (
  <MainContainer>
    <InnerContainer>
      <Title>Birth Control Method Popularity</Title>
      <FlexContainer>
        <BarItemsContainer>
          {METHOD_GRAPH_STATS.map((stats) => (
            <BarItem key={stats.type} stats={stats} />
          ))}
        </BarItemsContainer>
        <GraphsContainer>
          <DonutChart
            chartData={[
              {
                value: 67,
                color: LOCAL_COLORS.birthControlBarGreen,
              },
              {
                value: 33,
                color: LOCAL_COLORS.birthControlBarDefault,
              },
            ]}
          >
            Percent of women (ages 25 - 34) using birth control
          </DonutChart>
          <DonutChart
            chartData={[
              {
                value: 36,
                color: LOCAL_COLORS.birthControlBarRed,
              },
              {
                value: 64,
                color: LOCAL_COLORS.birthControlBarDefault,
              },
            ]}
          >
            Study participants using hormonal IUD who said it made their acne
            worse
          </DonutChart>
        </GraphsContainer>
      </FlexContainer>
      <Citation>
        1. Lortscher D, Admani S, Satur N, Eichenfield LF: Hormonal
        Contraceptives and Acne: A Retrospective Analysis of 2147 Patients. J
        Drugs Dermatol. 2016;15(6):670-674.
      </Citation>
    </InnerContainer>
  </MainContainer>
);

export default GraphSection;
