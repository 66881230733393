import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import type { FlexibleCardProps } from 'components/global/FlexibleCard';
import ECOMMERCE_URLS from 'consts/eCommerceUrls';
import {
  PRODUCT_DISPLAY_NAMES,
  ECOMMERCE_PRODUCT_DESCRIPTIONS,
} from 'consts/products';

const productsCardMaxWidth = 457;

export const ECOMMERCE_CLEANSER: FlexibleCardProps = {
  title: PRODUCT_DISPLAY_NAMES.cleanser,
  body: ECOMMERCE_PRODUCT_DESCRIPTIONS.cleanser,
  media: (
    <StaticImage
      src="../../images/products/cleanser@2x.jpg"
      alt="The cleanser"
      width={productsCardMaxWidth}
    />
  ),
  hoverMedia: (
    <StaticImage
      src="../../images/products/cleanser_hover@2x.jpg"
      alt="Cleanser texture"
      width={productsCardMaxWidth}
    />
  ),
  link: ECOMMERCE_URLS.cleanser,
};

export const ECOMMERCE_MOISTURIZER: FlexibleCardProps = {
  title: PRODUCT_DISPLAY_NAMES.moisturizer,
  body: ECOMMERCE_PRODUCT_DESCRIPTIONS.moisturizer,
  media: (
    <StaticImage
      src="../../images/products/moisturizer@2x.jpg"
      alt="The moisturizer"
      width={productsCardMaxWidth}
    />
  ),
  hoverMedia: (
    <StaticImage
      src="../../images/products/moisturizer_hover@2x.jpg"
      alt="Moisturizer texture"
      width={productsCardMaxWidth}
    />
  ),
  link: ECOMMERCE_URLS.moisturizer,
};

export const ECOMMERCE_EMERGENCY_SPOT_PATCH: FlexibleCardProps = {
  title: PRODUCT_DISPLAY_NAMES.emergencySpotPatches,
  body: ECOMMERCE_PRODUCT_DESCRIPTIONS.emergencySpotPatches,
  media: (
    <StaticImage
      src="../../images/products/emergencySpotPatches@2x.jpg"
      alt="Emergency spot patch"
      width={productsCardMaxWidth}
    />
  ),
  hoverMedia: (
    <StaticImage
      src="../../images/products/emergencySpotPatches_hover@2x.jpg"
      alt="Emergency spot patch texture"
      width={productsCardMaxWidth}
    />
  ),
  link: ECOMMERCE_URLS.emergencySpotPatch,
};
