import React from 'react';
import type { Ingredient as IIngredient } from '../IngredientsTabs';
import {
  IngredientTabContent,
  IngredientTabDetails,
  IngredientTabHeader,
  IngredientTabHeadingWrapper,
  IngredientTabImageContainer,
  IngredientTabSubtitle,
  IngredientTabTag,
  IngredientTabTagContainer,
  IngredientTabTagHeading,
  IngredientTabTagRow,
  IngredientTabTitle,
} from './IngredientTab.style';

export interface IngredientTabProps {
  ingredient: IIngredient;
  tagHeading?: string;
}

export const IngredientTab = ({
  ingredient: { detailsText, iconImage, shortName, tags, title },
  tagHeading,
}: IngredientTabProps) => (
  <IngredientTabContent value={shortName || title}>
    <IngredientTabHeader>
      <IngredientTabHeadingWrapper>
        <IngredientTabTitle>{shortName}</IngredientTabTitle>
        <IngredientTabSubtitle>{title}</IngredientTabSubtitle>
      </IngredientTabHeadingWrapper>
      <IngredientTabImageContainer>{iconImage}</IngredientTabImageContainer>
    </IngredientTabHeader>
    <IngredientTabDetails>{detailsText}</IngredientTabDetails>
    <IngredientTabTagContainer>
      {tagHeading && (
        <IngredientTabTagHeading>{tagHeading}</IngredientTabTagHeading>
      )}
      <IngredientTabTagRow>
        {tags?.map((tag, index) => (
          <IngredientTabTag key={index}>{tag}</IngredientTabTag>
        ))}
      </IngredientTabTagRow>
    </IngredientTabTagContainer>
  </IngredientTabContent>
);
