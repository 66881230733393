import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { LOCAL_COLORS } from 'styles/constants';

export const BodyContainer = styled.div`
  color: ${LOCAL_COLORS.navy400};
  line-height: 150%;
  margin-bottom: 4px;
`;

export const linkStyles = css`
  text-transform: capitalize;

  &:hover {
    text-decoration: none;
  }
`;

export const listStyles = css`
  margin: 0;
`;

export const listItemStyles = css`
  list-style: none;
  display: inline;
`;
