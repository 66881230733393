import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';
import getContrastingColor from 'utils/getContrastingColor';

export const OuterContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const InnerContainer = styled.div`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: 0 auto;
  padding: ${SPACER.x3large} ${SPACER.large};
  display: flex;
  flex-direction: column;
  gap: ${SPACER.base};

  ${MEDIA_QUERIES.mdUp} {
    padding: ${SPACER.x3large} 4.5rem;
  }
`;

export const headerStyles = (backgroundColor: string) => css`
  color: ${getContrastingColor(backgroundColor)};
`;
