import styled from '@emotion/styled';
import { MEDIA_QUERIES } from 'radiance-ui/lib/constants';

export const HeroOuterContainer = styled.div`
  padding: 32px 0;

  ${MEDIA_QUERIES.lgUp} {
    margin: auto;
    max-width: 1440px;
    padding: 64px;
  }
`;

export const HeroContainer = styled.div`
  ${MEDIA_QUERIES.lgUp} {
    align-items: center;
    display: flex;

    & > * {
      flex-grow: 1;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  ${MEDIA_QUERIES.lgUp} {
    padding: 0;
  }
`;

export const HeaderTitle = styled.h1`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 130%;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 52px;
    line-height: 110%;
    margin-bottom: 16px;
  }
`;

export const HeaderSubtitle = styled.h3`
  font-size: 16px;
  font-weight: normal;
  line-height: 132%;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 24px;
    line-height: 140%;
  }
`;

export const HeroAsset = styled.div`
  padding: 0;

  ${MEDIA_QUERIES.smUp} {
    padding: 52px;
  }

  ${MEDIA_QUERIES.lgUp} {
    margin-left: 100px;
    min-width: 375px;
    padding: 0;
  }
`;

export const AuthorOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0;

  ${MEDIA_QUERIES.lgUp} {
    display: none;
  }
`;

export const AuthorContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const AuthorLgScreenOuterContainer = styled.div`
  display: none;

  ${MEDIA_QUERIES.lgUp} {
    display: flex;
    margin: 32px 0;
  }
`;

export const AuthorLgScreenContainer = styled.div`
  align-items: center;
  display: flex;
  padding-right: 56px;
`;

export const MedicalReviewerContainer = styled.div`
  font-size: 12px;
  padding-top: 16px;

  ${MEDIA_QUERIES.lgUp} {
    padding: 0;
  }
`;
