import React from 'react';

import { CtaButton, CtaButtonProps } from 'components/global/CtaButton';
import {
  OuterContainer,
  InnerContainer,
  BeforeAfterContentContainer,
  Header,
  HeaderContent,
  SubheaderContent,
  Subheader,
  DesktopCtaContainer,
} from './BeforeAfterSingle.style';

import { desktopOnly } from 'styles/mixins/displayMobileDesktop';
import { BeforeAfterCaseSingle } from './components/BeforeAfterCaseSingle';

export interface BeforeAfterSingleProps {
  header: string;
  subheader: string;
  ctaButton: CtaButtonProps;
  disclaimer: string;
  beforeLabel?: string;
  afterLabel: string;
  theme: string;
  beforeImageMobile: React.ReactNode;
  beforeImageDesktop: React.ReactNode;
  afterImageMobile: React.ReactNode;
  afterImageDesktop: React.ReactNode;
}

const moduleName = 'beforeAfterSingle';

export const BeforeAfterSingle = ({
  header,
  subheader,
  ctaButton,
  disclaimer,
  beforeLabel,
  afterLabel,
  beforeImageMobile,
  beforeImageDesktop,
  afterImageMobile,
  afterImageDesktop,
  theme = 'primary',
}: BeforeAfterSingleProps) => (
  <OuterContainer themeType={theme} data-module={moduleName}>
    <InnerContainer>
      <BeforeAfterCaseSingle
        afterLabel={afterLabel}
        beforeLabel={beforeLabel || 'Before'}
        beforeImageDesktop={beforeImageDesktop}
        afterImageDesktop={afterImageDesktop}
        beforeImageMobile={beforeImageMobile}
        afterImageMobile={afterImageMobile}
        disclaimer={disclaimer}
        theme={theme}
        ctaButton={ctaButton}
      />
      <BeforeAfterContentContainer>
        <HeaderContent>
          {header && <Header>{header}</Header>}
          {!subheader && ctaButton && (
            <CtaButton css={desktopOnly()} {...ctaButton} />
          )}
        </HeaderContent>
        {subheader && (
          <SubheaderContent>
            <Subheader
              dangerouslySetInnerHTML={{
                __html: subheader,
              }}
            />
            {ctaButton && (
              <DesktopCtaContainer>
                <CtaButton {...ctaButton} />
              </DesktopCtaContainer>
            )}
          </SubheaderContent>
        )}
      </BeforeAfterContentContainer>
    </InnerContainer>
  </OuterContainer>
);
