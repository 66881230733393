import styled from '@emotion/styled';
import * as Tabs from '@radix-ui/react-tabs';

import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const IngredientTabContent = styled(Tabs.Content)`
  align-items: flex-start;
  align-self: stretch;
  color: ${LOCAL_COLORS.textDefault};
  background-color: ${LOCAL_COLORS.surfaceWhite};
  border: 0.125rem solid ${LOCAL_COLORS.surfaceDarkBold};
  display: flex;
  flex-direction: column;
  gap: ${SPACER.medium};
  order: 1;
  padding: ${SPACER.large} 1.625rem;

  ${MEDIA_QUERIES.mdUp} {
    flex: 1 0 0;
    gap: ${SPACER.x2large};
    justify-content: center;
    order: 2;
    padding: ${SPACER.x2large};
  }

  ${MEDIA_QUERIES.lgUp} {
    padding: 4.5rem ${SPACER.x2large};
  }
`;

export const IngredientTabHeader = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 1.25rem;
  justify-content: space-between;
`;

export const IngredientTabHeadingWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const IngredientTabTitle = styled.h2`
  color: ${LOCAL_COLORS.textDefault};
  font-family: var(--header-font);
  font-size: 3.125rem;
  font-weight: 700;
  line-height: 90%;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 4.375rem;
  }
`;

export const IngredientTabSubtitle = styled.div`
  color: ${LOCAL_COLORS.textDefault};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 100%;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 2rem;
  }
`;

export const IngredientTabImageContainer = styled.div`
  align-items: center;
  display: flex;
  height: 4.19rem;
  justify-content: center;
  width: 4.19rem;

  ${MEDIA_QUERIES.lgUp} {
    height: 6.25rem;
    width: 6.25rem;
  }
`;

export const IngredientTabDetails = styled.div`
  color: ${LOCAL_COLORS.textDefault};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 1rem;
  }
`;

export const IngredientTabTagContainer = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${MEDIA_QUERIES.lgUp} {
    gap: 1rem;
  }
`;

export const IngredientTabTagHeading = styled.div`
  color: ${LOCAL_COLORS.textDefault};
  font-size: 1rem;
  font-weight: 700;
  line-height: 120%;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 1.5rem;
  }
`;

export const IngredientTabTagRow = styled.div`
  align-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-flow: row wrap;
  gap: 0.3125rem;

  ${MEDIA_QUERIES.lgUp} {
    gap: 0.375rem;
  }
`;

export const IngredientTabTag = styled.div`
  background: ${LOCAL_COLORS.surfaceOrange};
  align-items: flex-start;
  display: flex;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 150%;
  padding: 0.0625rem 0.3125rem;
`;
