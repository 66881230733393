import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

export const IngredientContainer = styled.div<{ active: boolean }>`
  display: flex;
  color: black;

  ${MEDIA_QUERIES.xlUp} {
    flex-direction: row;
    border: 2px solid ${LOCAL_COLORS.surfaceDarkBold};
    border-right: none;
    background: ${LOCAL_COLORS.surfaceWhite};
    position: relative;

    :nth-of-type(3) {
      border-right: 2px solid ${LOCAL_COLORS.surfaceDarkBold};
    }
    li {
      font-size: 16px;
    }
  }

  p {
    font-size: 16px;
    line-height: 18px;
  }
  ,
  li {
    font-size: 9px;
    line-height: 18px;
  }
`;

export const TitleContainer = styled.div`
  width: 115px;
  border: 1px solid black;
  padding: ${SPACER.medium} ${SPACER.small} ${SPACER.medium};
  position: relative;
  height: 115px;
  z-index: 2;
  background: #ffffff;

  ${MEDIA_QUERIES.xlUp} {
    border: none;
    width: 180px;
    height: 180px;
    padding: ${SPACER.medium};
  }

  ${MEDIA_QUERIES.xxlUp} {
    width: 200px;
    height: 200px;
  }
`;

export const MobileDetailsContainer = styled.div<{ active: boolean }>`
  ${mobileOnly('flex', 'xlUp')};
  position: absolute;
  height: 345px;
  left: 115px;
  width: calc(100% - 125px);
  top: 0;
  border: 1px solid #000;
  border-width: 1px 1px 1px 0px;
  padding-left: 1rem;
  background: ${LOCAL_COLORS.surfaceWhite};
  z-index: 2;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  background: ${LOCAL_COLORS.surfaceWhite};
  ${({ active }) => expandContent(active)};
`;

export const DetailsContainer = styled.div<{
  active: boolean;
}>`
  ${desktopOnly('flex', 'xlUp')};
  position: relative;
  border: 0px;
  border-left: 2px solid #000;
  width: 180px;
  border-top: none;
  padding: ${SPACER.medium};
  height: 180px;
  left: 0;
  top: 0;
  padding-left: 1rem;
  background: ${LOCAL_COLORS.surfaceWhite};
  z-index: 2;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  background: ${LOCAL_COLORS.surfaceWhite};

  ${MEDIA_QUERIES.xlUp} {
    ${({ active }) => expandContent(active)};
  }

  ${MEDIA_QUERIES.xxlUp} {
    width: 200px;
    height: 200px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;

  ${MEDIA_QUERIES.xlUp} {
    display: none;
  }
`;

export const IngredientIntro = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
`;

export const IngredientTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const IngredientName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${SPACER.small};
  line-height: 1;

  span:first-of-type {
    font-family: var(--header-font);
    font-size: ${SPACER.xlarge};
    font-weight: 700;
    line-height: 100%;

    ${MEDIA_QUERIES.xlUp} {
      font-size: 72px;
    }

    ${MEDIA_QUERIES.xxlUp} {
      font-size: 96px;
    }
  }

  span:last-of-type {
    font-size: 14px;
    font-weight: 700;

    ${MEDIA_QUERIES.xlUp} {
      font-size: 18px;
    }
  }
`;

export const IngredientImage = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 6px;

  ${MEDIA_QUERIES.xlUp} {
    width: 80px;
    height: 80px;
  }
`;

export const IngredientDetails = styled.p`
  max-width: 180px;
  margin: 0 0 ${SPACER.large};

  ${MEDIA_QUERIES.xlUp} {
    margin: ${SPACER.large} 0;
  }
`;

export const IngredientTags = styled.ul`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  gap: 5px;
  margin-top: auto;
  margin-bottom: 0;
`;

export const IngredientTag = styled.li`
  font-size: 9px;
  ${MEDIA_QUERIES.xlUp} {
    font-size: inherit;
  }
  font-weight: 700;
  background-color: #ff824f;
  padding: 1px 5px;
`;

const expandContent = (active: boolean) => `
   ${
     active
       ? `
        transition: opacity 0s, width 0.15s ease-in-out 0.25s, visibility 0.15s ease-in-out 0.25s;
        opacity: 1;
        width: 200px;
        visibility: visible;
        display: flex;
      `
       : `
        transition: opacity 0s, width 0.15s ease-in-out, visibility 0.15s ease-in-out;
        width: 0px;
        visibility: hidden;
        opacity: 0;
        display: none;
      `
   };
`;

export const StepBody = styled.div`
  color: ${LOCAL_COLORS.surfaceWhite};
  line-height: 132%;
  margin-bottom: ${SPACER.medium};

  ${MEDIA_QUERIES.xlUp} {
    max-width: 100%;
    font-size: 14px;
    margin: 0 0 0 ${SPACER.medium};
  }

  @media (min-width: 1300px) {
    font-size: 16px;
    margin: 0 ${SPACER.large};
  }
`;

export const MobileImageContainer = styled.div`
  ${mobileOnly()}
  width: 100%;
  margin-bottom: ${SPACER.xlarge};

  > div {
    width: 100%;
  }
`;
