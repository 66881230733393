import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS, LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';
import getContrastingColor from 'utils/getContrastingColor';

export const OuterContainer = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ?? theme.COLORS.secondaryTint3};
`;

export const InnerContainer = styled.div`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: 0 auto;

  ${MEDIA_QUERIES.lgUp} {
    padding: 5rem ${SPACER.x5large} ${SPACER.x5large};
    display: flex;
  }
`;

export const ImageContainer = styled.div`
  margin: 0 auto;
  max-width: 560px;
  flex: 1;
  margin-bottom: ${SPACER.large};
  position: relative;

  ${MEDIA_QUERIES.lgUp} {
    margin: 0;
  }
`;

export const ContentContainer = styled.div`
  ${MEDIA_QUERIES.lgUp} {
    margin-left: auto;
    max-width: 470px;
    flex: 1;
  }
`;

export const InfoPanel = styled.div`
  height: fit-content;
  background-color: ${LOCAL_COLORS.surfaceWhite};
  padding: ${SPACER.large};
`;

export const ValuePropsList = styled.ul`
  color: ${({ theme }) => theme.COLORS.primary};
`;

export const ValuePropListItem = styled.li<{ themeType: string }>`
  display: flex;
  margin-bottom: ${SPACER.medium};
  line-height: 132%;

  &:first-of-type {
    ${({ themeType }) =>
      themeType === 'secondary' ? 'font-weight: 700;' : ''};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const checkmarkIconStyles = css`
  margin-right: ${SPACER.medium};
  flex-shrink: 0;
  stroke-width: ${SPACER.x2small};
  align-self: center;
`;

export const TargetsContainer = styled.div`
  margin-top: ${SPACER.x2large};
  margin-bottom: 1.25rem;
`;

export const TargetsList = styled.div`
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: ${SPACER.small};
  margin-bottom: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  ${MEDIA_QUERIES.lgUp} {
    flex-wrap: wrap;
  }
`;

export const ChipContainer = styled.div<{ targetLabelColor: string }>`
  margin-right: ${SPACER.xsmall};
  margin-bottom: ${SPACER.xsmall};
  white-space: nowrap;

  div {
    background-color: ${({ targetLabelColor }) => targetLabelColor};
    border-radius: 0;

    span {
      color: ${({ targetLabelColor }) =>
        targetLabelColor === LOCAL_COLORS.corePurple50
          ? `${LOCAL_COLORS.surfaceBrandBold} !important`
          : `${getContrastingColor(targetLabelColor)} !important`};
    }
  }
`;

export const titleStyles = css`
  margin-bottom: ${SPACER.small};
  text-wrap: pretty;

  h1 {
    font-size: ${SPACER.xlarge};
    font-weight: 700;
    line-height: 130%;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  margin-bottom: ${SPACER.small};
  color: ${({ theme }) => theme.COLORS.primaryTint3};
`;

export const priceStyles = (theme: ThemeType) => css`
  color: ${theme.COLORS.primaryTint1};
`;

export const freeTrialPriceStyles = (theme: ThemeType) => css`
  color: ${theme.COLORS.success};
`;

export const descriptionStyles = (theme: ThemeType) => css`
  margin-bottom: ${SPACER.large};
  color: ${theme.COLORS.primary};

  ${MEDIA_QUERIES.lgUp} {
    margin-bottom: ${SPACER.medium};
  }

  h5 {
    font-weight: 400;
    text-decoration: underline;
  }
`;

export const ctaStyles = css`
  max-width: unset;
  width: 100% !important;
`;
