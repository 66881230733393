import React from 'react';

import { TrustModule } from '.';

import SchemaError from 'components/global/errors/SchemaError';
import type { TrustEntry } from 'schema';

export const ContentfulTrustModule = (props: TrustEntry) => {
  if (props._type === 'schemaError') {
    return <SchemaError {...props} />;
  }

  return <TrustModule {...props} />;
};
