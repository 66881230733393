import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { RICH_TEXT_OPTIONS } from 'consts/contentful';
import { SmartImage } from 'components/global/SmartImage';
import type { SplitContentEntry } from 'schema';
import { SplitContent } from '.';

export const ContentfulSplitContent = ({
  body,
  media,
  layout,
  mobileLayout,
  ...props
}: SplitContentEntry) => (
  <SplitContent
    body={body && documentToReactComponents(body, RICH_TEXT_OPTIONS)}
    media={<SmartImage {...media} layout="fullWidth" />}
    layout={{
      desktop: layout,
      mobile: mobileLayout,
    }}
    {...props}
  />
);
