import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

export const CarouselContainer = styled.div`
  > div > div {
    max-width: unset;

    ${MEDIA_QUERIES.mdUp} {
      max-width: 100vw;
    }

    > div {
      padding-bottom: unset;
    }
  }

  .slick-list {
    padding-left: ${SPACER.large};

    ${MEDIA_QUERIES.lgUp} {
      padding-left: unset;
    }
  }

  .slick-slider {
    padding: 0 !important;
    justify-content: flex-start !important;

    // right nav arrow
    > :nth-last-of-type(1) {
      ${MEDIA_QUERIES.mdUp} {
        padding-right: ${SPACER.x3large};
        margin-right: unset;
      }
      ${MEDIA_QUERIES.lgUp} {
        padding-right: unset;
      }
    }
  }

  .slick-track {
    gap: ${SPACER.base};
    align-items: flex-start !important;

    ${MEDIA_QUERIES.mdUp} {
      gap: ${SPACER.large};
    }
  }
`;
