import { useAtom } from 'jotai';
import { useEffect } from 'react';

import cookie from 'utils/cookie';
import { COOKIE_KEYS } from 'consts/cookieKeys';
import { isTruthy } from 'utils/string/isTruthy';

import { optedOutAtom } from '../atoms/optedOutAtom';

export const useHasTrackingOptedOut = () => {
  const [optedOut, setOptedOut] = useAtom(optedOutAtom);

  useEffect(() => {
    setOptedOut(isTruthy(cookie.get(COOKIE_KEYS.trackingOptOut)));
  }, [setOptedOut]);

  return optedOut;
};
