import React from 'react';

import type { Ingredient } from '../IngredientsTabs';
import { TabsList, TabsTrigger } from './IngredientsTabsContainer.style';
import { IngredientTab } from './IngredientTab';

export interface IngredientsTabsContainerProps {
  ingredients: Ingredient[];
  tagHeading?: string;
}

export const IngredientsTabsContainer = ({
  ingredients,
  tagHeading,
}: IngredientsTabsContainerProps) => (
  <>
    <TabsList>
      {ingredients.map(({ title, shortName }, index) => (
        <TabsTrigger key={index} value={shortName || title}>
          {shortName || title}
        </TabsTrigger>
      ))}
    </TabsList>
    {ingredients.map((ingredient, index) => (
      <IngredientTab
        ingredient={ingredient}
        key={index}
        tagHeading={tagHeading}
      />
    ))}
  </>
);
