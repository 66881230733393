import styled from '@emotion/styled';

import { ReactComponent as TrustBadgeIcon } from './images/trust_badge.svg';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const TrustBadge = styled(TrustBadgeIcon)<{ fill?: boolean }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${({ fill }) =>
    fill ? LOCAL_COLORS.surfaceWhite : 'none'};
`;

export const TrustModuleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${LOCAL_COLORS.corePurple50};
  padding: ${SPACER.large};
  gap: 12px;

  & > #badge {
    min-width: 77px;
  }

  & > #copy {
    display: none;
  }

  & > #stats {
    flex-grow: 1;
  }

  ${MEDIA_QUERIES.mdUp} {
    gap: ${SPACER.large};

    & > #badge {
      width: 10%;
    }

    & > #copy {
      display: block;
      flex-grow: 1;
      max-width: 33%;
    }

    & > #stats {
      flex-grow: 1;
    }
  }

  ${MEDIA_QUERIES.lgUp} {
    & > #badge {
      min-width: 100px;
    }
  }
}
`;

export const TrustCopy = styled.h2`
  line-height: 24px;
  font-size: 16px;

  ${MEDIA_QUERIES.mdUp} {
    font-size: 18px;
  }

  ${MEDIA_QUERIES.lgUp} {
    font-size: 20px;
  }
`;

export const TrustStatisticContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;

  & > :first-child {
    flex-grow: 1;
  }

  & > :nth-child(2) {
    display: none;
  }

  ${MEDIA_QUERIES.smUp} {
    & > :nth-child(2) {
      display: block;
      flex-grow: 1;
    }
  }
`;

export const TrustStatistic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${LOCAL_COLORS.surfaceBrandLight};
  padding: ${SPACER.medium};
`;

export const TrustStatisticTitle = styled.div`
  font-weight: 700;
  font-size: 44px;
  line-height: 48.4px;

  ${MEDIA_QUERIES.mdUp} {
    font-size: 32px;
    line-height: 35.2px;
  }

  ${MEDIA_QUERIES.xlUp} {
    font-size: 40px;
    line-height: 44px;
  }

  ${MEDIA_QUERIES.xxlUp} {
    font-size: 52px;
    line-height: 57.2px;
  }
`;

export const TrustStatisticSubtitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 21.12px;
  letter: -2%;
`;
