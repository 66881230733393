import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { StarIcon } from 'components/global/StarIcon';

const StarRatingContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    margin-right: ${SPACER.x2large};
  }

  img {
    margin-bottom: ${SPACER.xsmall};
  }

  strong {
    margin-left: ${SPACER.small};
    color: ${({ theme }) => theme.COLORS.primary};
  }
`;

export interface StarRatingProps {
  rating: number;
  text: string;
}

export const StarRating = ({ rating, text }: StarRatingProps) => {
  const starIcons: JSX.Element[] = [];
  const ratingFloor = Math.floor(rating);
  for (let ratingNumber = 0; ratingNumber < ratingFloor; ratingNumber += 1) {
    starIcons.push(<StarIcon key={ratingNumber} />);
  }
  if (rating % 0.5 === 0) {
    starIcons.push(<StarIcon key={rating} size="50%" />);
  }
  return (
    <StarRatingContainer>
      {starIcons}
      {text && <strong>{text}</strong>}
    </StarRatingContainer>
  );
};
