import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import type { FlexibleCardProps } from 'components/global/FlexibleCard';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

import { ReactComponent as OrangeCheckmark } from '../../images/subscription/orangeCheckmark.svg';

const subscriptionCardMaxWidth = 556;
const subscriptionCardMinWidth = 257;

export const CUSTOM_ROUTINE: FlexibleCardProps = {
  title: 'Custom routine subscription',
  subtitle: 'Starting at $39.95/mo',
  body: '',
  featureList: {
    symbol: <OrangeCheckmark width={23} height={23} />,
    listItems: [
      'Full-size personalized formula',
      'Your choice of additional full-size Curology products',
      'Continued skincare support from your dermatology provider',
    ],
  },
  media: (
    <React.Fragment>
      <StaticImage
        src="../../images/subscription/customRoutineDesktop@2x.jpg"
        alt="photo of custom formula"
        width={subscriptionCardMaxWidth}
        css={desktopOnly()}
      />
      <StaticImage
        src="../../images/subscription/customRoutineMobile@2x.jpg"
        alt="photo of custom formula"
        width={subscriptionCardMinWidth}
        css={mobileOnly()}
      />
    </React.Fragment>
  ),
};

export const PERSONALIZED_FORMULA: FlexibleCardProps = {
  title: 'Personalized formula subscription',
  subtitle: '$29.95/mo',
  body: '',
  featureList: {
    symbol: <OrangeCheckmark width={23} height={23} />,
    listItems: [
      'Personalized formula',
      'Continued skincare support from your dermatology provider',
    ],
  },
  media: (
    <React.Fragment>
      <StaticImage
        src="../../images/subscription/personalizedFormulaDesktop@2x.jpg"
        alt="photo of micellar makeup remover, custom formula, the cleanser, and the rich moisturizer"
        width={subscriptionCardMaxWidth}
        css={desktopOnly()}
      />
      <StaticImage
        src="../../images/subscription/personalizedFormulaMobile@2x.jpg"
        alt="photo of micellar makeup remover, custom formula, the cleanser, and the rich moisturizer"
        width={subscriptionCardMinWidth}
        css={mobileOnly()}
      />
    </React.Fragment>
  ),
};
