import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const WhatWeTreatCardContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  max-width: 37.5rem;
  padding-bottom: 1.5rem;

  ${MEDIA_QUERIES.lgUp} {
    flex: 1 1 50%;
    max-width: calc(50% - 1.5rem);
  }
`;

export const WhatWeTreatCardContent = styled.div`
  max-height: 22.625rem;
  position: relative;
  width: 100%;

  .gatsby-image-wrapper {
    max-height: 22.625rem;

    ${MEDIA_QUERIES.mdUp} {
      max-height: unset;
    }
  }

  ${MEDIA_QUERIES.mdUp} {
    max-height: 22.625rem;
    max-height: unset;
  }
`;

export const WhatWeTreatCardImageContainer = styled.div`
  background: ${LOCAL_COLORS.surfaceWhite};
  border-radius: ${SPACER.medium} ${SPACER.medium} 0 0;
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;

  & > div {
    border-radius: ${SPACER.small} ${SPACER.small} 0 0;
    height: 100%;
    width: 100%;
  }

  img {
    object-position: top;

    ${MEDIA_QUERIES.mdUp} {
      object-position: unset;
    }
  }
`;

export const WhatWeTreatCardBadgeContainer = styled.div`
  background-color: #fa824f;
  font-size: ${SPACER.medium};
  font-weight: 700;
  left: ${SPACER.medium};
  line-height: 104%;
  padding: 1px 6px;
  position: absolute;
  top: ${SPACER.medium};
  white-space: nowrap;
  width: fit-content;

  ${MEDIA_QUERIES.mdUp} {
    padding: ${SPACER.small} ${SPACER.medium};
    top: ${SPACER.large};
    left: ${SPACER.large};
  }
`;

export const WhatWeTreatCardGlyphContainer = styled.div`
  align-items: flex-end;
  background-color: ${LOCAL_COLORS.surfaceWhite};
  border-radius: 0 0 ${SPACER.small} ${SPACER.small};
  display: flex;
  flex-direction: row;
  gap: ${SPACER.medium};
  justify-content: space-between;
  max-width: 100%;
  overflow-x: auto;
  padding: ${SPACER.medium};
  scroll-behavior: smooth;
  width: 100%;

  ${MEDIA_QUERIES.lgUp} {
    padding: ${SPACER.xlarge} ${SPACER.medium} ${SPACER.medium};
  }

  ${MEDIA_QUERIES.xlUp} {
    flex: 1;
  }
`;

export const WhatWeTreatCardHeadline = styled.div<{ hasBadge?: boolean }>`
  font-size: ${SPACER.large};
  font-weight: 700;
  left: ${SPACER.medium};
  line-height: 130%;
  position: absolute;
  top: ${({ hasBadge }) => (hasBadge ? SPACER.x2large : SPACER.medium)};

  ${MEDIA_QUERIES.mdUp} {
    left: ${SPACER.large};
    top: ${({ hasBadge }) => (hasBadge ? SPACER.x5large : SPACER.medium)};
  }

  &::after {
    content: '';
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M11.5858 9.00005L8.29289 12.2929L9.70711 13.7072L14.7071 8.70715C15.0976 8.31663 15.0976 7.68346 14.7071 7.29294L9.70711 2.29294L8.29289 3.70715L11.5858 7.00005L1 7.00005L1 9.00005H11.5858Z" fill="%231D1D44"/></svg>');
    height: 1rem;
    position: absolute;
    margin-left: 5px;
    top: 0.5rem;
    width: 1rem;

    ${MEDIA_QUERIES.mdUp} {
      font-size: ${SPACER.medium};
    }
  }
`;

export const WhatWeTreatCardPromoContainer = styled.div`
  height: 5rem;
  position: absolute;
  right: ${SPACER.small};
  top: ${SPACER.small};
  width: 5rem;
  ${MEDIA_QUERIES.mdUp} {
    height: 119px;
    width: 119px;
  }
`;
