import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import type { StepsProps } from '..';

export const STEPS_TEEN_CONTENT: StepsProps = {
  header: 'Easy as 1, 2, 3, 4, glow! ✨',
  step1Image: (
    <StaticImage src="../images/teen/step1.png" width={208} alt="camera" />
  ),
  step1Text: 'Take the quiz and snap some pics',
  step2Image: (
    <StaticImage
      src="../images/teen/step2.png"
      width={208}
      alt="hand holding Curology Custom Formula"
    />
  ),
  step2Text: 'Get your treatment plan from your provider',
  step3Image: (
    <StaticImage src="../images/teen/step3.png" width={208} alt="gift" />
  ),
  step3Text: 'Chill while your formula comes to you',
  step4Image: (
    <StaticImage src="../images/teen/step4.png" width={208} alt="cream" />
  ),
  step4Text: 'Apply nightly and get your glow on',
};
