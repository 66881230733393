import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const OuterContainer = styled.div`
  display: flex;
  gap: 0;
  border: 2px solid #000;
  background-color: ${LOCAL_COLORS.surfaceWhite};
  position: relative;
  max-width: 700px;
`;

export const LeftContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  max-width: 208px;
  border: 1px solid #000;
  border-width: 0 0 1px 1px;

  ${MEDIA_QUERIES.lgUp} {
    position: relative;
    max-width: 500px;
    border-width: 0px 2px 0px 0px;

    & > div {
      height: 100%;
    }
  }
`;

export const ProviderName = styled.h3`
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 1px 5px;
  background: ${LOCAL_COLORS.surfaceOrange};
  font-size: ${SPACER.medium};
  font-weight: 700;
`;

export const RightContainer = styled.div`
  padding: ${SPACER.medium};

  h3 {
    font-size: ${SPACER.large};
    font-weight: 700;
  }
`;

export const Headline = styled.h1`
  font-size: 96px;
  line-height: 1.5;
  color: ${LOCAL_COLORS.surfaceBrandBold};
  font-family: var(--header-font);
`;
