import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { SPACER } from 'radiance-ui/lib/constants';

const baseListStyles = ({ theme }: { theme: ThemeType }) => css`
  font-size: ${theme.TYPOGRAPHY.fontSize.title};
  margin: ${SPACER.large} 0 0;
  padding-left: ${SPACER.xlarge};

  * {
    color: ${theme.COLORS.primaryTint1};
  }
`;

export const OrderedList = styled.ol`
  ${baseListStyles};
`;

export const UnorderedList = styled.ul`
  ${baseListStyles};
  list-style-type: '-  ';
`;

export const ListItem = styled.li`
  margin-bottom: ${SPACER.small};

  > * {
    margin: 0;
    padding: 0;
  }

  > p {
    margin: unset;
  }
`;
