import styled from '@emotion/styled';
import { Typography } from 'radiance-ui';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';

export const MainContainer = styled.div`
  background-color: ${LOCAL_COLORS.surfacePastelPurple};
  margin: ${SPACER.x5large} 0;
  padding: ${SPACER.x3large} ${SPACER.large};

  ${MEDIA_QUERIES.lgUp} {
    padding: ${SPACER.x3large} ${SPACER.x4large};
  }
`;

export const Heading = styled(Typography.Heading)`
  font-size: 2.5rem;
  line-height: 44px;
  padding-bottom: ${SPACER.x2large};

  ${MEDIA_QUERIES.lgUp} {
    font-size: 3.25rem;
    line-height: 57.2px;
  }
`;
