import React from 'react';
import { Avatar } from 'radiance-ui';

import type { ArticleAuthorEntry } from 'schema';

import {
  AvatarsContainer,
  AvatarContainer,
  AuthorText,
  authorNameStyles,
} from './Authors.style';
import defaultAvatarImage from '../../Person/images/defaultAvatar.png';

type AuthorsProps = {
  articleAuthors?: ArticleAuthorEntry[];
  featuredArticleAuthors?: ArticleAuthorEntry[];
  medicalReviewer?: ArticleAuthorEntry;
  date?: string;
  readTimeInMinutes?: number;
};

export const Authors = ({
  articleAuthors,
  featuredArticleAuthors,
  medicalReviewer,
  date,
  readTimeInMinutes,
}: AuthorsProps) => {
  const allAuthors = (articleAuthors ?? [])
    .concat(featuredArticleAuthors ?? [])
    .concat(medicalReviewer ? [medicalReviewer] : []);

  const formatName = ({ name, credentials }: ArticleAuthorEntry) => {
    return credentials ? [name, ...credentials].join(', ') : name;
  };

  return (
    <>
      <AvatarsContainer numAuthors={allAuthors.length}>
        {allAuthors.reverse().map(({ avatar }, index) => (
          <AvatarContainer numAuthors={allAuthors.length} key={index}>
            <Avatar
              size="medium"
              src={avatar?.url ?? defaultAvatarImage}
              alt={avatar?.title ?? 'Default avatar'}
            />
          </AvatarContainer>
        ))}
      </AvatarsContainer>
      <AuthorText hasFeaturedAuthors={!!featuredArticleAuthors}>
        {!!articleAuthors && (
          <div>
            by{' '}
            <span css={authorNameStyles}>
              {articleAuthors.map((author) => formatName(author)).join(', ')}
            </span>
          </div>
        )}
        {!!featuredArticleAuthors && (
          <div>
            featuring{' '}
            <span css={authorNameStyles}>
              {featuredArticleAuthors
                ?.map((author) => formatName(author))
                .join(', ')}
            </span>
          </div>
        )}
        {!!medicalReviewer && (
          <div>
            Medically reviewed by{' '}
            <span css={authorNameStyles}>{formatName(medicalReviewer)}</span>
          </div>
        )}
        <div>
          Updated on {date}
          {!!readTimeInMinutes && `  •  ${readTimeInMinutes} min read`}
        </div>
      </AuthorText>
    </>
  );
};
