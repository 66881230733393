import React from 'react';

import {
  TrustBadge,
  TrustModuleContainer,
  TrustCopy,
  TrustStatisticContainer,
  TrustStatistic,
  TrustStatisticTitle,
  TrustStatisticSubtitle,
} from './TrustModule.style';

import { TrustStatisticEntry } from 'schema';

type TrustModuleProps = {
  copy?: string;
  displayTrustBadge?: boolean;
  statistics?: TrustStatisticEntry[];
};

const Statistic = ({ title, subtitle }: TrustStatisticEntry) => (
  <TrustStatistic>
    <TrustStatisticTitle>{title}</TrustStatisticTitle>
    <TrustStatisticSubtitle>{subtitle}</TrustStatisticSubtitle>
  </TrustStatistic>
);

export const TrustModule = ({
  copy,
  displayTrustBadge,
  statistics,
}: TrustModuleProps) => {
  return (
    <TrustModuleContainer>
      {displayTrustBadge && <TrustBadge id="badge" data-testid="badge" />}
      {copy && <TrustCopy id="copy">{copy}</TrustCopy>}
      {statistics && (
        <TrustStatisticContainer id="stats">
          {statistics.map(Statistic)}
        </TrustStatisticContainer>
      )}
    </TrustModuleContainer>
  );
};
