import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  MEDIA_QUERIES,
  SPACER,
  type ThemeType,
} from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';
import type { ButtonType } from './CtaPanel';

export const PanelButtonContainer = styled.div`
  display: grid;
  text-align: center;
  margin: 0 auto;
  row-gap: ${SPACER.base};
  white-space: pre-line;
  grid-template-rows: 56px auto;
  width: 100%;
  max-width: 325px;

  ${MEDIA_QUERIES.mdUp} {
    text-align: left;
    margin: 0;
    width: 272px;
  }
`;

export const panelButtonStyle = (buttonType: ButtonType) => css`
  text-transform: unset;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: unset;
  border-radius: ${SPACER.x3large};
  background-color: ${LOCAL_COLORS.surfaceBrandBold};
  border-color: ${LOCAL_COLORS.surfaceBrandBold};
  ${buttonType === 'secondary' &&
  `
      background-color: ${LOCAL_COLORS.surfaceWhite};
      border-color: ${LOCAL_COLORS.surfaceBrandLight};
      border-width: 2px;
      color: ${LOCAL_COLORS.surfaceBrandBold};
      &:hover {
        color: ${LOCAL_COLORS.surfaceBrandBold} !important;
        background-color: #f2f2f2 !important;
      }
    `};
`;

export const PanelGroup = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${SPACER.xlarge};
`;

export const panelHeaderText = (theme: ThemeType) => css`
  padding-bottom: ${SPACER.large};
  border-bottom: 1px solid ${theme.COLORS.primary};
  font-size: ${theme.TYPOGRAPHY.fontSize.body};
  margin-bottom: ${SPACER.large};
`;
