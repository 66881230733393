import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';

import {
  LOCAL_COLORS,
  LOCAL_TYPOGRAPHY_CONSTANTS,
  LOCAL_SPACING,
} from 'styles/constants';
import getBirthControlEffectColor from '../../../getBirthControlEffectColor';
import type { MethodEffectsType } from '../../../methodEffects';

export const Text = styled.p`
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.headerBody};
  color: ${LOCAL_COLORS.purple75};
  line-height: 1.5;
  margin: 0 auto ${SPACER.medium};
  text-align: center;
`;

export const GraphTitle = styled.h4`
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.headerBody};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};
  color: ${({ theme }) => theme.COLORS.primary};
  margin: ${SPACER.xlarge} auto ${SPACER.medium};
  text-align: center;
`;

export const BarsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 50px;
  width: 100%;
`;

export const Bar = styled.div<{ effect: MethodEffectsType; width: number }>`
  height: 100%;
  background: ${({ effect, theme }) =>
    getBirthControlEffectColor(effect, theme)};
  width: ${({ width }) => `${width}%`};
`;

export const PercentageContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin: 0 auto ${LOCAL_SPACING.base};
`;

export const Percentage = styled.div<{ width: number }>`
  height: 100%;
  text-align: center;
  color: ${LOCAL_COLORS.purple75};
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.caption};
  font-family: ${({ theme }) => theme.FONTS.headerFont};
  width: ${({ width }) => `${width}%`};
`;

export const LabelsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const Label = styled.div<{ effect: MethodEffectsType }>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: ${LOCAL_COLORS.purple75};
  text-align: center;
  line-height: 1.2;

  :before {
    content: '';
    height: 20px;
    width: 20px;
    margin-bottom: ${LOCAL_SPACING.xxsmall};
    background: ${({ effect, theme }) =>
      getBirthControlEffectColor(effect, theme)};
  }
`;

export const DescriptionContainer = styled.div`
  max-width: 550px;
  margin-top: ${SPACER.x2large};

  p {
    margin-bottom: ${SPACER.large};
    color: ${LOCAL_COLORS.purple75};
    line-height: 1.5;
    font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.headerBody};
  }
`;
