import React from 'react';
import styled from '@emotion/styled';

const MainContainer = styled.div`
  padding: 32px;
`;

const Title = styled.h3`
  font-weight: bold;
  padding-bottom: 16px;
`;

export const KeyTakeaways = ({
  keyTakeawaysList,
}: {
  keyTakeawaysList: React.ReactNode;
}) => (
  <MainContainer>
    <Title>The key takeaways</Title>
    {keyTakeawaysList}
  </MainContainer>
);
