import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import type { StepsProps } from '..';

export const STEPS_DARK_SPOT_FORMULA_CONTENT: StepsProps = {
  header: 'Start fading dark spots in 3 steps',
  step1Image: (
    <StaticImage
      src="../images/dark-spot-formula/step1.png"
      width={208}
      alt="phone showing Curology's skin quiz"
    />
  ),
  step1Text: 'Take a skin quiz and share photos',
  step2Image: (
    <StaticImage
      src="../images/dark-spot-formula/step2.png"
      width={208}
      alt="patient holding Curology Custom Formula"
    />
  ),
  step2Text: 'Your dermatology provider prescribes your formula',
  step3Image: (
    <StaticImage
      src="../images/dark-spot-formula/step3.png"
      width={208}
      alt="patient applying Curology Custom Formula"
    />
  ),
  step3Text: 'Apply daily for happy, healthy skin',
};
