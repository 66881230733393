import React from 'react';
import { Helmet } from 'react-helmet';

import { SITE_URL } from 'consts/environmental';

interface BreadcrumbSchemaProps {
  breadcrumbList: {
    name?: string;
    item?: string;
  }[];
}

export const BreadcrumbSchema = ({ breadcrumbList }: BreadcrumbSchemaProps) => {
  const schemaItemListElement = breadcrumbList
    .filter(({ name }) => name !== null)
    .map(({ name, item }, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name,
        item: `${SITE_URL}${item}`,
      };
    });

  const schemaObject = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: schemaItemListElement,
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaObject)}</script>
    </Helmet>
  );
};
