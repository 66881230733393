import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import type { Options } from '@contentful/rich-text-react-renderer';

import * as Style from './LegalPage.style';

export const RICH_TEXT_OPTIONS: Options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_node, children) => (
      <Style.Heading>{children}</Style.Heading>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <Style.SubHeading>{children}</Style.SubHeading>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Style.Paragraph>{children}</Style.Paragraph>
    ),
    [BLOCKS.OL_LIST]: (_node, children) => (
      <Style.OrderedList>{children}</Style.OrderedList>
    ),
    [BLOCKS.UL_LIST]: (_node, children) => (
      <Style.UnorderedList>{children}</Style.UnorderedList>
    ),
    [BLOCKS.TABLE]: (_node, children) => (
      <Style.TableWrapper>
        <Style.Table>
          <tbody>{children}</tbody>
        </Style.Table>
      </Style.TableWrapper>
    ),
    [BLOCKS.TABLE_ROW]: (_node, children) => (
      <Style.TableRow>{children}</Style.TableRow>
    ),
    [BLOCKS.TABLE_HEADER_CELL]: (_node, children) => (
      <Style.TableHeaderCell>{children}</Style.TableHeaderCell>
    ),
    [BLOCKS.TABLE_CELL]: (_node, children) => (
      <Style.TableCell>{children}</Style.TableCell>
    ),
  },
};
