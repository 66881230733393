import styled from '@emotion/styled';
import { MEDIA_QUERIES } from 'radiance-ui/lib/constants';

export const SqueezeDividerContainer = styled.div<{
  squeezeColor: string;
  sectionColor: string;
  orientation: string;
  showOnDesktop: boolean;
  showOnMobile: boolean;
}>`
  ${({ showOnMobile }) => (showOnMobile ? `display: flex` : `display: none`)};
  background-color: ${({ sectionColor }) => sectionColor};
  color: ${({ squeezeColor }) => squeezeColor};
  margin: auto;
  max-height: 4.375rem;
  max-width: 180rem;
  z-index: 0;

  ${({ orientation }) =>
    orientation === 'flipped'
      ? 'transform: scale(-1, 1); margin-bottom: -1px;'
      : 'margin-top: -2px;'};

  @media (min-width: 400px) {
    max-height: unset;
  }

  ${MEDIA_QUERIES.mdUp} {
    width: 100%;

    ${({ showOnDesktop }) =>
      showOnDesktop ? `display: flex` : `display: none`};
  }

  svg {
    width: 100%;
    z-index: 1;
  }
`;
