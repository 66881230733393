import styled from '@emotion/styled';
import * as Tabs from '@radix-ui/react-tabs';

import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';
import getContrastingColor from 'utils/getContrastingColor';

export const IngredientsTabsSectionContainer = styled.div<{
  background: string;
}>`
  background-color: ${({ background }) => background};
`;

export const IngredientsTabsContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90rem;
  margin: auto;
  padding: 5rem ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    flex-direction: row;
    gap: 1rem;
  }

  ${MEDIA_QUERIES.lgUp} {
    gap: 5rem;
    padding: 7.5rem 4.5rem;
  }
`;

export const IngredientsTabsCopyAndCtaContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3.75rem;

  small {
    margin-top: 2.75rem;
  }

  ${MEDIA_QUERIES.mdUp} {
    align-items: flex-start;

    small {
      margin-top: 1.5rem;
    }
  }

  ${MEDIA_QUERIES.lgUp} {
    gap: 2.5rem;
    padding: 0;
  }
`;

export const IngredientsTabsCopyContainer = styled.div<{ background: string }>`
  color: ${({ background }) =>
    getContrastingColor(background) || LOCAL_COLORS.textDefault};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ${MEDIA_QUERIES.lgUp} {
    padding-top: ${SPACER.large};
  }
`;

export const IngredientsTabsTitle = styled.h2<{ background: string }>`
  color: ${({ background }) =>
    getContrastingColor(background) || LOCAL_COLORS.textDefault};
  font-size: 2rem;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.04rem;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 3.25rem;
    letter-spacing: unset;
  }
`;

export const IngredientsTabsBody = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 132%;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 1.5rem;
    line-height: 140%;
  }
`;

export const TabsRootMobile = styled(Tabs.Root)`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  margin: 0 auto;

  ${MEDIA_QUERIES.mdUp} {
    display: none;
  }
`;

export const TabsRootDesktop = styled(Tabs.Root)`
  display: none;

  ${MEDIA_QUERIES.mdUp} {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1 0 50%;
    flex-direction: row;
  }
`;
