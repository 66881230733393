import React from 'react';
import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';
import { BLOCKS } from '@contentful/rich-text-types';
import type { Options } from '@contentful/rich-text-react-renderer';

import { ContentfulImage } from 'components/global/ContentfulImage';
import { DynamicComponent } from 'components/modules/DynamicComponent';

import { ReactComponent as CheckmarkSvg } from './images/checkmark-orange.svg';

const Checkmark = styled(CheckmarkSvg)`
  min-height: 22px;
  padding-top: 3px;
  min-width: 16px;
`;

const ListItemContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const ListItem = styled.li`
  font-size: 16px;
  margin-bottom: ${SPACER.small};
  width: 100%;

  > * {
    margin: 0;
    padding: 0;
  }
`;

export const RICH_TEXT_OPTIONS: Options = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (_node, children) => (
      <ListItemContainer>
        <Checkmark />
        <ListItem>{children}</ListItem>
      </ListItemContainer>
    ),
    [BLOCKS.EMBEDDED_ASSET]: ({ data }) => (
      <ContentfulImage image={data.target} css={{ marginTop: SPACER.medium }} />
    ),
    [BLOCKS.EMBEDDED_ENTRY]: ({ data }) => (
      <DynamicComponent entry={data.target} />
    ),
  },
};
