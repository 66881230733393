import { useEffect, useState } from 'react';

import { EVENTS } from 'consts/metricsTrackingEvents';
import { trackEvent } from 'utils/metricsTracking';

import {
  Container,
  CopyContainer,
  Header,
  IconContainer,
  Subheader,
} from './QuizBanner.style';

import { Icon, IconName } from '@curology/ui-components-web/react';
import { deviceId as getDeviceId } from 'utils/analytics/amplitude';

type BannerProps = {
  quizSlug: string;
  line1: string;
  line2: string;
};

const QUIZ_DOMAIN = 'https://quiz.curology.com';

const trackCtaClick = () => {
  trackEvent(EVENTS.ctaClicked, {
    path: window.location.pathname,
    host: window.location.host,
    userAgent: window?.navigator.userAgent,
    module: 'quiz-sticky-banner',
  });
};

export const QuizBanner = (props: BannerProps) => {
  const { line1, line2, quizSlug } = props;
  const [href, setHref] = useState(`${QUIZ_DOMAIN}/quiz/${quizSlug}`);
  const [deviceId, setDeviceId] = useState('');

  useEffect(() => {
    const deviceId = getDeviceId();
    if (deviceId) setDeviceId(deviceId);
  });

  useEffect(() => {
    if (deviceId)
      setHref(`${QUIZ_DOMAIN}/quiz/${quizSlug}?amp_device_id=${deviceId}`);
  }, [deviceId]);

  return (
    <a href={href} target="_blank" onClick={trackCtaClick}>
      <Container>
        <CopyContainer>
          <Header>{`${line1} \u00A0`}</Header>
          <Subheader>{line2}</Subheader>
        </CopyContainer>
        <IconContainer>
          <Icon size="sm" name={IconName.ArrowRight} />
        </IconContainer>
      </Container>
    </a>
  );
};
