import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES, GRID } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS, LOCAL_TYPOGRAPHY_CONSTANTS } from 'styles/constants';

export const Citation = styled.p`
  line-height: 1.5;
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.xxsmall};
  color: ${LOCAL_COLORS.purple75};
  margin: 0 auto;
  text-align: center;
  max-width: 500px;
`;

export const MainContainer = styled.div`
  background: ${({ theme }) => theme.COLORS.background};
  padding: ${SPACER.x2large} 0;

  ${MEDIA_QUERIES.mdUp} {
    padding: 7rem 0;
  }
`;

export const InnerContainer = styled.div`
  margin: 0 auto;
  max-width: ${GRID.xlContentMaxWidth};
  padding: 0 ${SPACER.medium};
  width: calc(100% - ${SPACER.xlarge});

  ${MEDIA_QUERIES.mdUp} {
    padding: 0 ${SPACER.xlarge};
    width: calc(100% - ${SPACER.x5large});
  }
`;

export const Title = styled.h2`
  line-height: 1.2;
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.title};
  color: ${LOCAL_COLORS.softBlack};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};
  padding: 0 0 ${SPACER.x4large};
  margin: 0 auto;
  text-align: center;

  ${MEDIA_QUERIES.mdUp} {
    padding: 0 0 6rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  ${MEDIA_QUERIES.xlUp} {
    flex-flow: row nowrap;
    justify-content: space-around;
  }
`;

export const BarItemsContainer = styled.div`
  flex: 1 1 auto;
  margin: 0 auto ${SPACER.xlarge};
  max-width: 500px;
  width: 100%;

  ${MEDIA_QUERIES.xlUp} {
    max-width: initial;
    margin: 0 auto;
    flex: 0 0 50%;
  }
`;

export const GraphsContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERIES.xlUp} {
    flex: 0 0 50%;
  }
`;
