import React from 'react';

import { ProductBanner } from '.';
import type { ProductBannerEntry } from 'schema';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';

export const ContentfulProductBanner = ({
  bannerText,
  ...props
}: ProductBannerEntry) => {
  return (
    <ProductBanner
      bannerText={documentToReactComponents(bannerText, RICH_TEXT_OPTIONS)}
      {...props}
    />
  );
};
