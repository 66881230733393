import React, { ReactNode } from 'react';
import { ReactComponent as CheckSVG } from './images/orangeCheck.svg';
import { CtaButton, CtaButtonProps } from 'components/global/CtaButton';
import { mobileOnly, desktopOnly } from 'styles/mixins/displayMobileDesktop';

import {
  ValuePropsContainer,
  ValuePropsInnerBox,
  ValuePropsLeftBox,
  ValuePropsRightBox,
  ValuePropsTitle,
  ValuePropsSubtitle,
  ValueProps,
  ValuePropContainer,
  ValueProp,
  ValuePropsDisclaimerText,
  ValuePropsSvgContainer,
  ValuePropsRightBoxContent,
} from './ValuePropsSection.style';
import { LOCAL_COLORS } from 'styles/constants';

export interface ValuePropsSectionProps {
  backgroundColor?: string;
  ctaButton?: CtaButtonProps;
  disclaimerText?: string;
  image: ReactNode;
  layoutOptionClasses: string;
  squeezeCompensationValue: number;
  subtitle?: string;
  swapImageOrder?: boolean;
  title: string;
  valueProps?: string[];
}

const moduleName = 'valuePropsSection';

export const ValuePropSection = ({
  backgroundColor = LOCAL_COLORS.surfaceWhite,
  ctaButton,
  disclaimerText,
  image,
  layoutOptionClasses,
  squeezeCompensationValue,
  subtitle,
  swapImageOrder,
  title,
  valueProps,
}: ValuePropsSectionProps) => (
  <ValuePropsContainer
    backgroundColor={backgroundColor}
    className={layoutOptionClasses}
    data-module={moduleName}
    squeezeCompensationValue={squeezeCompensationValue}
  >
    <ValuePropsInnerBox className={layoutOptionClasses}>
      <ValuePropsLeftBox
        className={layoutOptionClasses}
        swapOrder={swapImageOrder}
      >
        {image}
        {swapImageOrder && ctaButton && (
          <CtaButton css={[mobileOnly()]} {...ctaButton} />
        )}
        {swapImageOrder && disclaimerText && (
          <ValuePropsDisclaimerText
            css={swapImageOrder ? [mobileOnly()] : ''}
            dangerouslySetInnerHTML={{
              __html: disclaimerText,
            }}
          />
        )}
      </ValuePropsLeftBox>
      <ValuePropsRightBox className={layoutOptionClasses}>
        <ValuePropsRightBoxContent className={layoutOptionClasses}>
          <ValuePropsTitle
            className={layoutOptionClasses}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          {subtitle && (
            <ValuePropsSubtitle
              className={layoutOptionClasses}
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          )}
          {valueProps && (
            <ValueProps>
              {valueProps.map((valueProp, index) => {
                return (
                  <ValuePropContainer key={index}>
                    <ValuePropsSvgContainer>
                      <CheckSVG />
                    </ValuePropsSvgContainer>
                    <ValueProp
                      dangerouslySetInnerHTML={{
                        __html: valueProp,
                      }}
                    />
                  </ValuePropContainer>
                );
              })}
            </ValueProps>
          )}
          {ctaButton && (
            <CtaButton
              css={swapImageOrder ? [desktopOnly()] : ''}
              {...ctaButton}
            />
          )}
          {disclaimerText && (
            <ValuePropsDisclaimerText
              css={swapImageOrder ? [desktopOnly()] : ''}
              dangerouslySetInnerHTML={{
                __html: disclaimerText,
              }}
            />
          )}
        </ValuePropsRightBoxContent>
      </ValuePropsRightBox>
    </ValuePropsInnerBox>
  </ValuePropsContainer>
);
