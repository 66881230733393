import React from 'react';
import { BeforeAfterSingle, BeforeAfterSingleProps } from './BeforeAfterSingle';
import { SmartImage } from 'components/global/SmartImage';
import { BeforeAfterSingleEntry } from 'schema/beforeAfterSingleSchema';
import { ColorType } from 'components/global/CtaButton';

export const ContentfulBeforeAfterSingle = ({
  header,
  subheader,
  ctaButton,
  disclaimer,
  beforeLabel,
  afterLabel,
  beforeImageMobile,
  beforeImageDesktop,
  afterImageMobile,
  afterImageDesktop,
  theme,
}: BeforeAfterSingleEntry) => {
  const beforeAfterSingleProps: BeforeAfterSingleProps = {
    header,
    subheader,
    ctaButton: { ...ctaButton, color: ctaButton.color as ColorType },
    disclaimer,
    beforeLabel,
    afterLabel,
    beforeImageMobile: <SmartImage {...beforeImageMobile} />,
    beforeImageDesktop: <SmartImage {...beforeImageDesktop} />,
    afterImageMobile: <SmartImage {...afterImageMobile} />,
    afterImageDesktop: <SmartImage {...afterImageDesktop} />,
    theme: theme ? theme : 'primary',
  };

  return <BeforeAfterSingle {...beforeAfterSingleProps} />;
};
