import React from 'react';

import { BirthControlAndAcne } from './components/BirthControlAndAcne/BirthControlAndAcne';
import { MultiCategoryHero } from '../MultiCategoryHero';

type CustomComponentProps = {
  type: 'Birth Control and Acne' | 'Multi-category Hero';
};

const moduleMap = {
  'Birth Control and Acne': BirthControlAndAcne,
  'Multi-category Hero': MultiCategoryHero,
} as const;
export const CustomComponent = ({ type }: CustomComponentProps) => {
  const Component = moduleMap[type];
  if (Component) {
    return <Component />;
  }

  throw 'Invalid type supplied';
};
