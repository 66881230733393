import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

export const BeforeAfterCaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 680px;
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${SPACER.xsmall};
  max-height: 410px;
  min-height: 250px;
  overflow: hidden;

  ${MEDIA_QUERIES.mdUp} {
    padding: 0;
    min-height: 250px;
  }

  ${MEDIA_QUERIES.xlUp} {
    padding: ${SPACER.xsmall};
  }
`;

export const MaxWidthContainer = css`
  .fullWidth {
    max-width: 100% !important;
  }
`;

export const ImageRow = styled.div<{ themeType: string }>`
  display: flex;
  gap: ${SPACER.small};
  background-color: transparent;

  @media (max-width: 570px) {
    min-height: 178px;
    max-height: 380px;
    overflow: hidden;
  }

  @media (max-width: 900px) {
    padding-right: ${SPACER.x2small};
  }

  @media (min-width: 901px) {
    padding-right: ${SPACER.x5large};
  }
`;

export const imageContainerStyles = css`
  position: relative;
`;

export const DesktopImageContainer = styled.div`
  ${desktopOnly()}
  ${imageContainerStyles}
  min-width: 244px;
`;

export const MobileImageContainer = styled.div`
  ${mobileOnly()}
  ${imageContainerStyles}
`;

export const BeforeLabel = styled.span`
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
  text-wrap: nowrap;
  top: 0;
  left: 0;
  background-color: #fa824f;
  padding: 1px 5px;
`;

export const AfterLabel = styled.span`
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
  text-wrap: nowrap;
  top: 0;
  right: 0;
  background-color: #fa824f;
  padding: 1px 5px;
`;

export const QuoteBodyContainer = styled.div<{ themeType: string }>`
  flex-grow: 1;
  background-color: ${LOCAL_COLORS.surfaceWhite};
  padding: ${({ themeType }) =>
    themeType === 'primary'
      ? `${SPACER.large} 0 0`
      : `${SPACER.large} ${SPACER.medium} ${SPACER.medium}`};

  ${MEDIA_QUERIES.mdUp} {
    padding: ${({ themeType }) =>
      themeType === 'primary'
        ? `${SPACER.large} ${SPACER.medium} ${SPACER.medium}`
        : `${SPACER.large}`};
  }

  ${MEDIA_QUERIES.xlUp} {
    padding: ${({ themeType }) =>
      themeType === 'primary' ? `${SPACER.large} 0 0` : `${SPACER.large}`};
  }
`;

export const Quotation = styled.div`
  color: #6554e8;
  margin-bottom: ${SPACER.medium};
`;

export const QuoteSummary = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 6px;
`;

export const QuoteBody = styled.div`
  font-size: 16px;
  line-height: 132%;
`;

export const ImageContainer = styled.div`
  position: relative;
  flex: 1;
  ${desktopOnly()}
  ${mobileOnly()}
`;

export const ImageLabel = styled.span`
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase; // Assuming you want the labels to be uppercase
  text-align: center;
  bottom: ${SPACER.xsmall}; // Positioning label at the bottom of the image
  left: 50%;
  transform: translateX(-50%); // Centering the label
  background-color: ${({ theme }) =>
    theme.COLORS.secondary}; // Replace with your actual secondary color
  color: ${({ theme }) =>
    theme.COLORS
      .background}; // Replace with your actual background color for text
  padding: ${SPACER.x2small} ${SPACER.small};
  border-radius: ${SPACER.xsmall};
`;

export const CtaContainer = styled.div`
  display: flex;
  padding-top: ${SPACER.large};

  ${MEDIA_QUERIES['mdUp']} {
    display: none;
  }
`;
