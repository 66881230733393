import { SmartImage } from 'components/global/SmartImage';
import React from 'react';
import type { HeroPopEntry } from 'schema';
import { HeroPop, POP_REDESIGN_HERO_CONTENT } from './';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

export const ContentfulHeroPop = ({
  heroImageDesktop,
  heroImageMobile,
  mobileHeroClass,
  badgeImageDesktop,
  ...props
}: HeroPopEntry) => {
  const heroImages =
    heroImageDesktop && heroImageMobile ? (
      <React.Fragment>
        <SmartImage {...heroImageDesktop} css={desktopOnly('block', 'mdUp')} />
        <SmartImage {...heroImageMobile} css={mobileOnly('block', 'mdUp')} />
      </React.Fragment>
    ) : (
      POP_REDESIGN_HERO_CONTENT.images
    );

  const badgeImages = badgeImageDesktop ? (
    <React.Fragment>
      <SmartImage {...badgeImageDesktop} css={desktopOnly('block', 'mdUp')} />
    </React.Fragment>
  ) : (
    POP_REDESIGN_HERO_CONTENT.badgeImages
  );

  return (
    <HeroPop
      efficacyStats={POP_REDESIGN_HERO_CONTENT.efficacyStats}
      images={heroImages}
      badgeImages={badgeImages}
      heroClass={mobileHeroClass}
      {...props}
    />
  );
};
