import React from 'react';

import { ValuePropSection } from '.';
import type { ValuePropEntry } from 'schema';
import { SmartImage } from 'components/global/SmartImage';

const layoutOptions: Record<string, string> = {
  'No Container Padding': 'noContainerPadding',
  'Negative Margin Squeeze Compensation': 'squeezeCompensation',
};

export const ContentfulValueProp = ({
  image,
  moduleLayoutOptions,
  squeezeCompensationForEdgeToEdgeImage,
  ...props
}: ValuePropEntry) => {
  const layoutOptionClasses =
    moduleLayoutOptions?.map((option) => layoutOptions[option]).join(' ') ?? '';

  return (
    <ValuePropSection
      image={<SmartImage {...image} />}
      squeezeCompensationValue={squeezeCompensationForEdgeToEdgeImage || 0}
      layoutOptionClasses={layoutOptionClasses}
      {...props}
    />
  );
};
