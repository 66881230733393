import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
export const WorkItOutWrapper = styled.section<{
  $background: string | undefined;
}>`
  background-color: ${({ theme, $background }) =>
    $background ? $background : theme.COLORS.defaultLight};
`;

export const WorkItOutBox = styled.div<{ imagePosition: string }>`
  max-width: 1296px;
  margin: auto;

  ${MEDIA_QUERIES.mdUp} {
    display: flex;
    align-items: center;
    padding-left: ${({ imagePosition }) =>
      imagePosition === 'right' ? SPACER.xlarge : 0};
    padding-right: ${({ imagePosition }) =>
      imagePosition === 'right' ? 0 : SPACER.xlarge};

    > div {
      width: 50%;
    }
  }

  ${MEDIA_QUERIES.lgUp} {
    padding-left: ${({ imagePosition }) =>
      imagePosition === 'right' ? `87px` : 0};
    padding-right: ${({ imagePosition }) =>
      imagePosition === 'right' ? 0 : `87px`};
  }

  ${MEDIA_QUERIES.xxlUp} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ContentBox = styled.div<{
  imagePosition: string;
}>`
  padding: 20px ${SPACER.medium} ${SPACER.x3large};

  ${MEDIA_QUERIES.mdUp} {
    box-sizing: border-box;
    padding: 0;
    padding-left: ${({ imagePosition }) =>
      imagePosition === 'right' ? 0 : SPACER.medium};
    padding-right: ${({ imagePosition }) =>
      imagePosition === 'right' ? SPACER.medium : 0};
    order: ${({ imagePosition }) => (imagePosition === 'right' ? 1 : 2)};
  }
`;

export const Header = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
  max-width: 600px;
  margin-bottom: ${SPACER.medium};

  & > div {
    gap: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 110%;
  }

  p,
  b {
    font-size: 24px;
    font-weight: 700;
    line-height: 110%;
  }

  ${MEDIA_QUERIES.lgUp} {
    font-size: 32px;

    p,
    b {
      font-size: 32px;
    }
  }

  ${MEDIA_QUERIES.xlUp} {
    font-size: 52px;

    p,
    b {
      font-size: 52px;
    }
  }
`;

export const Body = styled.div`
  font-size: 16px;
  line-height: 140%;
  max-width: 495px;
  margin-bottom: ${SPACER.xlarge};

  ${MEDIA_QUERIES.lgUp} {
    font-size: 24px;
  }
`;

export const Disclaimer = styled.small`
  max-width: 450px;
`;

export const DesktopImageWrapper = styled.div<{ imagePosition: string }>`
  order: ${({ imagePosition }) => (imagePosition === 'right' ? 2 : 1)};
`;
