import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';

const SummaryContainer = styled.div`
  color: ${LOCAL_COLORS.textDefault};
  padding: 0 ${SPACER.x2large} 0 ${SPACER.medium};
  margin: ${SPACER.medium} 0;
`;

const headerStyles = css`
  margin-bottom: ${SPACER.medium};
`;

export const Summary = ({ children }: { children: React.ReactNode }) => (
  <SummaryContainer>
    <h3 css={headerStyles}>Summary</h3>
    {children}
  </SummaryContainer>
);
