import styled from '@emotion/styled';
import { ANIMATION, MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import {
  LOCAL_COLORS,
  LOCAL_SPACING,
  LOCAL_TYPOGRAPHY_CONSTANTS,
} from 'styles/constants';

import getBirthControlEffectColor from '../../../getBirthControlEffectColor';
import type { MethodEffectsType } from '../../../methodEffects';

export const MainContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  margin: 0 auto ${SPACER.x2large};

  ${MEDIA_QUERIES.xlUp} {
    margin: 0 auto ${SPACER.x5large};
  }
`;

export const SearchInput = styled.input`
  appearance: none;
  outline: none;
  background: ${({ theme }) => theme.COLORS.white};
  border-radius: 0;
  color: ${LOCAL_COLORS.purple75};
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.body};
  height: 3rem;
  line-height: 2rem;
  padding: 0 ${LOCAL_SPACING.xxsmall};
  width: calc(100% - ${SPACER.large});
  border: 2px solid ${LOCAL_COLORS.borderAlternative};
  box-shadow: none;
  margin-bottom: ${SPACER.small};
  transition: ${ANIMATION.defaultTiming};

  ::placeholder {
    color: ${LOCAL_COLORS.borderAlternative};
  }

  :focus,
  :active {
    border-color: ${LOCAL_COLORS.grey1};
    transition: ${ANIMATION.defaultTiming};
  }
`;

export const SearchResultsContainer = styled.div`
  min-height: 47px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const SearchResult = styled.div<{ effect: MethodEffectsType }>`
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.caption};
  cursor: pointer;
  padding: ${SPACER.small} ${SPACER.medium};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  color: ${({ effect, theme }) => getBirthControlEffectColor(effect, theme)};

  svg {
    margin-right: ${SPACER.xsmall};
    width: 20px;
    fill: ${({ effect, theme }) => getBirthControlEffectColor(effect, theme)};
  }
`;

export const NoResultsMessage = styled.div`
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.headerBody};
  color: ${LOCAL_COLORS.purple75};
  text-align: center;
`;
