import 'swiper/css';
import React, { useCallback, useState } from 'react';
import { type Swiper as SwiperType } from 'swiper';
import { A11y, Thumbs, Scrollbar, Navigation } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { ReactComponent as Arrow } from '../images/arrow.svg';
import 'swiper/css/navigation';

import {
  CarouselContainer,
  Container,
  ButtonContainer,
  thumbnailStyles,
  ArrowButton,
} from './ImageCarousel.style';
import { ImageAsset } from 'schema';
import { imageAssetToGatsbyImageData } from 'utils/contentful/imageAssetToGatsbyImageData';
import { GatsbyImage } from 'gatsby-plugin-image';

export const ImageCarousel = ({ images }: { images: ImageAsset[] }) => {
  const [thumbs, setThumbs] = useState<SwiperType | null>(null);
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [isPrevButtonDisabled, setPrevButtonDisabled] = useState(true);

  const handleSlideClick = useCallback(() => {
    setNextButtonDisabled(false);
    setPrevButtonDisabled(false);

    if (swiperRef?.isEnd) setNextButtonDisabled(true);
    else if (swiperRef?.isBeginning) setPrevButtonDisabled(true);
  }, [swiperRef]);

  const SwiperSlides = (
    <>
      {images.map((image, i) => {
        return (
          <SwiperSlide key={i}>
            <GatsbyImage
              alt={image.title}
              image={imageAssetToGatsbyImageData(image)}
              imgStyle={{ width: '100%', height: 'auto', cursor: 'pointer' }}
            />
          </SwiperSlide>
        );
      })}
    </>
  );

  const CustomNavigation = () => {
    return (
      <ButtonContainer>
        <ArrowButton
          onClick={() => swiperRef?.slidePrev()}
          disabled={isPrevButtonDisabled}
        >
          <Arrow className="prev-arrow" />
        </ArrowButton>
        <ArrowButton
          onClick={() => swiperRef?.slideNext()}
          disabled={isNextButtonDisabled}
        >
          <Arrow />
        </ArrowButton>
      </ButtonContainer>
    );
  };

  return (
    <Container>
      <CarouselContainer>
        <Swiper
          pagination
          modules={[A11y, Thumbs, Scrollbar, Navigation]}
          thumbs={{ swiper: thumbs && !thumbs.destroyed ? thumbs : null }}
          onSwiper={setSwiperRef}
          onSlideChange={handleSlideClick}
        >
          {SwiperSlides}
        </Swiper>
      </CarouselContainer>
      <CustomNavigation />
      <Swiper
        modules={[Scrollbar]}
        onSwiper={setThumbs}
        slidesPerView={1}
        scrollbar={{ draggable: true, dragSize: 24 }}
        css={[thumbnailStyles]}
      >
        {SwiperSlides}
      </Swiper>
    </Container>
  );
};
