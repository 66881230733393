import React from 'react';

import { FaqItem } from './components/FaqItem';
import { FaqSchema } from './components/FaqSchema';
import { MainContainer, Heading } from './ArticleFaq.style';

export interface QuestionAnswerPair {
  question: string;
  answerString: string;
  answer?: React.ReactNode;
}

export interface ArticleFaqProps {
  heading?: string;
  questionsAnswers: QuestionAnswerPair[];
}

export const ArticleFaq = ({ heading, questionsAnswers }: ArticleFaqProps) => (
  <React.Fragment>
    {!!questionsAnswers.length && <FaqSchema faqs={questionsAnswers} />}
    <MainContainer>
      {heading && <Heading>{heading}</Heading>}
      {!!questionsAnswers.length &&
        questionsAnswers.map(
          ({ question, answerString, answer = answerString }) => (
            <FaqItem key={question} question={question} answer={answer} />
          ),
        )}
    </MainContainer>
  </React.Fragment>
);
