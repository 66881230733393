import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

export const NavContainer = styled.nav`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: ${SPACER.medium} 0 ${SPACER.xlarge} 0;
  flex: 1;
  width: 100%;

  ${MEDIA_QUERIES.mdUp} {
    padding: 0 10px;
    margin: ${SPACER.medium} 0;
  }
`;

export const StyledLink = styled(Link)`
  ${({ theme }) => TYPOGRAPHY_STYLE.link(theme)};
  margin: ${SPACER.xsmall} 0;
  border: none;
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};

  ${MEDIA_QUERIES.mdUp} {
    font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.title};
  }

  &[aria-current] {
    text-decoration: underline;
  }
`;

export const NestedNavList = styled.ol`
  padding-left: ${SPACER.xlarge};
  list-style-type: disc;
  margin: 0;
`;

export const NestedNavItem = styled.li`
  margin: ${SPACER.xsmall} 0;

  > a {
    font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.body} !important;
  }
`;
