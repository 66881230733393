import { useSetAtom } from 'jotai';
import { useCallback } from 'react';

import cookie from 'utils/cookie';
import { COOKIE_KEYS } from 'consts/cookieKeys';
import { hasAuth } from 'utils/auth';
import APP_URLS from 'consts/appUrls';

import { optedOutAtom } from '../atoms/optedOutAtom';

export const useOptOutTracking = () => {
  const setOptedOut = useSetAtom(optedOutAtom);

  return useCallback(() => {
    setOptedOut(true);
    cookie.set(COOKIE_KEYS.trackingOptOut, 'true', {
      expires: 365,
    });

    cookie.set(COOKIE_KEYS.convertOptOut, '1', {
      expires: 365,
    });

    if (hasAuth()) {
      window.location.href = `${APP_URLS.trackingOptOut}?redirectUrl=${window.location.href}`;
    } else {
      window.location.reload();
    }
  }, [setOptedOut]);
};
