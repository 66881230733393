import React from 'react';
import PropTypes from 'prop-types';
import { PieChart } from 'react-minimal-pie-chart';

import { MainContainer, ChartLabel } from './style';

const DonutChart = ({ chartData, children }) => (
  <MainContainer>
    <PieChart
      data={chartData}
      lineWidth={22}
      style={{ height: '180px' }}
      startAngle={270}
      lengthAngle={360}
      label={({ dataIndex }) => {
        if (dataIndex === 1) {
          return '';
        }
        return `${chartData[dataIndex].value}%`;
      }}
      labelStyle={{
        fill: `${chartData[0].color}`,
      }}
      labelPosition={0}
    />
    <ChartLabel>{children}</ChartLabel>
  </MainContainer>
);

DonutChart.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
  children: PropTypes.string.isRequired,
};

export default DonutChart;
