import { CtaButton, CtaButtonProps } from 'components/global/CtaButton';
import { ReactNode } from 'react';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';
import {
  Body,
  ContentBox,
  DesktopImageWrapper,
  Disclaimer,
  Header,
  WorkItOutBox,
  WorkItOutWrapper,
} from './WorkItOut.style';
import { trackEvent } from 'utils/metricsTracking';
import { EVENTS } from 'consts/metricsTrackingEvents';
import {
  TREATMENT_CATEGORIES,
  TREATMENT_CATEGORY_IDS,
} from 'consts/treatmentCategories';
import { navExternalWithQueryString } from 'utils/marketingSafeDomain';

export interface WorkItOutProps {
  body?: ReactNode;
  backgroundColor?: string;
  ctaButton?: CtaButtonProps;
  desktopImage: ReactNode;
  desktopImageSectionPosition?: 'left' | 'right';
  disclaimer?: ReactNode;
  header: ReactNode;
  mobileImage: ReactNode;
}

const moduleName = 'workItOut';

export const WorkItOut = ({
  desktopImage,
  mobileImage,
  header,
  body,
  ctaButton,
  disclaimer,
  backgroundColor,
  desktopImageSectionPosition = 'right',
}: WorkItOutProps) => {
  const handleClick = () => {
    trackEvent(EVENTS.ctaClicked, {
      path: window.location.pathname,
      module: moduleName,
      treatmentCategories: TREATMENT_CATEGORIES[TREATMENT_CATEGORY_IDS.hair],
      treatmentCategoryId: TREATMENT_CATEGORY_IDS.hair,
    });

    navExternalWithQueryString(ctaButton?.link);
  };

  return (
    <WorkItOutWrapper data-module={moduleName} $background={backgroundColor}>
      <WorkItOutBox imagePosition={desktopImageSectionPosition}>
        {mobileImage && <div css={mobileOnly()}>{mobileImage}</div>}
        <ContentBox imagePosition={desktopImageSectionPosition}>
          <Header>{header}</Header>
          {body && <Body>{body}</Body>}
          {ctaButton && (
            <CtaButton overrideClick={handleClick} {...ctaButton} />
          )}
          {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
        </ContentBox>
        {desktopImage && (
          <DesktopImageWrapper
            imagePosition={desktopImageSectionPosition}
            css={desktopOnly()}
          >
            {desktopImage}
          </DesktopImageWrapper>
        )}
      </WorkItOutBox>
    </WorkItOutWrapper>
  );
};
