import React from 'react';
import type { KeyTakeawaysEntry } from 'schema';
import { KeyTakeaways } from '.';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { RICH_TEXT_OPTIONS } from './KeyTakeaways.richText';

export const ContentfulKeyTakeaways = ({
  keyTakeawaysList,
}: KeyTakeawaysEntry) => (
  <KeyTakeaways
    keyTakeawaysList={documentToReactComponents(
      keyTakeawaysList,
      RICH_TEXT_OPTIONS,
    )}
  />
);
