import React from 'react';
import { Button } from 'radiance-ui';

import { EVENTS } from 'consts/metricsTrackingEvents';
import { getFormattedLink } from 'utils/getFormattedLink';
import { desktopOnly } from 'styles/mixins/displayMobileDesktop';
import { navExternalWithQueryString } from 'utils/marketingSafeDomain';
import { trackEvent } from 'utils/metricsTracking';
import { CtaButtonProps } from 'components/global/CtaButton';

import { DEFAULT_SIDE_PANEL_CONTENT } from './CtaPanel.content';
import {
  panelButtonStyle,
  PanelButtonContainer,
  panelHeaderText,
  PanelGroup,
} from './CtaPanel.style';

export type ButtonType = 'primary' | 'secondary';

type CtaPanelSectionProps = {
  buttonType?: ButtonType;
  ctaButton: CtaButtonProps;
  disclaimerText?: string;
  event?: string;
  headerText: string;
  moduleName?: string;
};

type CtaPanelProps = {
  moduleName: string;
  enableCustomization?: boolean;
  content?: CtaPanelSectionProps[];
};

const CtaPanelSection = ({
  headerText,
  ctaButton,
  disclaimerText,
  buttonType = 'primary',
  moduleName,
  event = EVENTS.ctaClicked,
}: CtaPanelSectionProps) => {
  const handleClick =
    (link: string, eventName: string) => (event: React.SyntheticEvent) => {
      const target = event.target as HTMLElement;
      const ctaText = target.innerText;
      const formattedLink = getFormattedLink(link);
      trackEvent(eventName, {
        destination: formattedLink,
        module: moduleName,
        path: window.location.pathname,
        text: ctaText,
      });
      return navExternalWithQueryString(formattedLink);
    };

  return (
    <PanelGroup css={desktopOnly('grid')}>
      <h5 css={panelHeaderText}>{headerText}</h5>
      <PanelButtonContainer>
        {ctaButton?.link && (
          <Button
            buttonType={buttonType}
            css={panelButtonStyle(buttonType)}
            isFullWidth
            onClick={handleClick(ctaButton.link, event)}
          >
            {ctaButton.buttonText}
          </Button>
        )}
        {disclaimerText && <small>{disclaimerText}</small>}
      </PanelButtonContainer>
    </PanelGroup>
  );
};

export const CtaPanel = ({
  moduleName,
  content = DEFAULT_SIDE_PANEL_CONTENT,
}: CtaPanelProps) => {
  return (
    <>
      {content.map((data, index) => (
        <CtaPanelSection
          {...data}
          key={`cta-panel-section-${index}`}
          moduleName={moduleName}
        />
      ))}
    </>
  );
};
