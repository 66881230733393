/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import type { ThemeType } from 'radiance-ui/lib/constants';

import { LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';

import type { CardSizeType, DesktopLayoutType } from './FlexibleCardsCarousel';

export const OuterContainer = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.COLORS.white};
`;

export const InnerContainer = styled.div`
  margin: 0 auto;
  padding: ${SPACER.x2large} ${SPACER.large};

  ${MEDIA_QUERIES.lgUp} {
    max-width: ${LOCAL_CONTENT_MAX_WIDTH};
    padding: 4.5rem 4.5rem 5rem;
  }
`;

export const bodyStyles = css`
  max-width: 560px;
`;

export const BadgesContainer = styled.div`
  display: flex;
  gap: ${SPACER.large};
  margin-top: ${SPACER.base};
`;

export const ctaButtonDesktopStyles = (isShownOnDesktop?: boolean) => css`
  display: none;

  ${MEDIA_QUERIES.mdUp} {
    display: ${isShownOnDesktop ? 'grid' : 'none'};
  }

  small {
    text-align: right;
  }
`;

export const ctaButtonMobileStyles = (isShownOnMobile?: boolean) => css`
  margin-top: ${SPACER.large};
  display: ${isShownOnMobile ? 'grid' : 'none'};
  text-align: left;

  ${MEDIA_QUERIES.mdUp} {
    display: none;
  }
`;

export const disclaimerStyles = (theme: ThemeType) => css`
  color: ${theme.COLORS.primary};
  margin-top: ${SPACER.xlarge};

  ${MEDIA_QUERIES.lgUp} {
    margin-top: ${SPACER.x2large};
  }
`;

export const FlexibleCardContainer = styled.div<{
  desktopCardSize: CardSizeType;
  carouselOnMobile: boolean;
  desktopLayout: DesktopLayoutType;
}>`
  width: min-content;
  max-width: min-content;
  min-width: ${({ carouselOnMobile }) =>
    carouselOnMobile ? `calc(100vw - 118px)` : `calc(100vw - 48px)`};

  ${MEDIA_QUERIES.smUp} {
    min-width: calc(calc(100vw - 72px) / 2);
    min-width: ${({ desktopLayout }) =>
      desktopLayout === 'List' && `calc(min(100vw, 1280px) - 48px)`} !important;
  }

  ${MEDIA_QUERIES.mdUp} {
    min-width: ${({ desktopCardSize }) =>
      desktopCardSize === 'Small'
        ? `calc(calc(100vw - 96px) / 3)`
        : `calc(calc(100vw - 72px) / 2)`};
  }

  ${MEDIA_QUERIES.lgUp} {
    min-width: ${({ desktopCardSize }) => {
      switch (desktopCardSize) {
        case 'Large':
          return `calc(calc(min(100vw, 1280px) - 168px) / 2)`;
        case 'Small':
          return `calc(calc(min(100vw, 1280px) - 216px) / 4)`;
        default:
          return `calc(calc(min(100vw, 1280px) - 192px) / 3)`;
      }
    }};
    min-width: ${({ desktopLayout }) =>
      desktopLayout === 'List' &&
      `calc(min(100vw, 1280px) - 144px)`} !important;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${SPACER.large};
`;

export const headerStyles = css`
  margin-bottom: ${SPACER.small};
  max-width: 752px;

  ${MEDIA_QUERIES.lgUp} {
    margin-bottom: ${SPACER.medium};
  }
`;

export const GridContainer = styled.div<{
  showOnMobile: boolean;
  showOnDesktop: boolean;
  listOnDesktop: boolean;
  desktopCardSize: CardSizeType;
}>`
  ${({ showOnMobile }) => (showOnMobile ? `display: flex` : `display: none`)};
  flex-wrap: wrap;
  gap: ${SPACER.x3large} ${SPACER.large};
  gap: ${({ listOnDesktop }) => listOnDesktop && SPACER.large};
  margin-top: ${SPACER.x2large};

  ${MEDIA_QUERIES.lgUp} {
    ${({ showOnDesktop }) =>
      showOnDesktop ? `display: flex` : `display: none`};
  }

  ${({ listOnDesktop, desktopCardSize }) =>
    listOnDesktop &&
    css`
      > div > div,
      > div > a {
        ${MEDIA_QUERIES.smUp} {
          display: grid;
          grid-template-columns: ${desktopCardSize === 'Large'
              ? `3fr`
              : desktopCardSize === 'Medium'
              ? `2fr`
              : `1fr`} 3fr;
          gap: ${SPACER.x3large};
          align-items: center;
        }
      }
    `}
`;

export const CarouselContainer = styled.div<{
  showOnMobile: boolean;
  showOnDesktop: boolean;
}>`
  ${({ showOnMobile }) => (showOnMobile ? `display: block` : `display: none`)};
  margin: ${SPACER.xlarge} -${SPACER.large} 0;

  ${MEDIA_QUERIES.lgUp} {
    margin: ${SPACER.xlarge} 0;
    ${({ showOnDesktop }) =>
      showOnDesktop ? `display: block` : `display: none`};
  }
`;
