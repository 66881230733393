import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';

import { ReactComponent as VectorSVG } from './images/vector.svg';
import { ReactComponent as ChevronSVG } from './images/chevron.svg';

export const MainContainer = styled.div`
  background-color: ${LOCAL_COLORS.surfacePastelPurple};
  padding: 16px;
`;

export const TableOfContentsHeader = styled.h3`
  font-weight: bold;
  line-height: 150%;
  margin-bottom: 8px;

  ${MEDIA_QUERIES.mdUp} {
    font-size: 1.25rem;
  }
`;

export const TableOfContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 8px;
  line-height: 150%;

  a {
    text-decoration: underline;
    border-bottom: none;
  }
`;

export const Content = styled.div<{
  isExpanded: boolean;
  index: number;
}>`
  color: ${LOCAL_COLORS.textDefault};
  display: ${({ index, isExpanded }) =>
    index >= 3 && !isExpanded ? 'none' : 'flex'};

  ${MEDIA_QUERIES.mdUp} {
    font-size: 1rem;
  }
`;

export const ContentToggle = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  gap: 8px;
  line-height: 150%;
`;

export const Chevron = styled(ChevronSVG)<{
  isExpanded: boolean;
}>`
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'none')};
`;

export const Vector = styled(VectorSVG)<{
  isActive: boolean;
}>`
  display: none;
`;

export const BadgeContainer = styled.div`
  position: relative;
  bottom: 50px;
  left: calc(100% - 100px - ${SPACER.small}); // 100% - badge width - padding

  ${MEDIA_QUERIES.mdUp} {
    left: calc(100% - 100px - ${SPACER.medium}); // 100% - badge width - padding
  }
`;
