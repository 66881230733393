import React from 'react';

import { BlogPost } from 'components/pages/BlogPost';
import { ContentfulArticleCarousel } from 'components/modules/ArticleCarousel';
import { ContentfulArticleConversionCta } from 'components/modules/ArticleConversionCta';
import { ContentfulArticleFaq } from 'components/modules/ArticleFaq';
import { ContentfulBeforeAfterPop } from './pop-redesign/BeforeAfterPop';
import { ContentfulBeforeAfterSingle } from './BeforeAfter/pop-redesign/BeforeAfterSingle/ContentfulBeforeAfterSingle';
import { ContentfulCustomComponent } from 'components/modules/CustomComponent';
import { ContentfulCustomFormula } from './pop-redesign/CustomFormulaPop/ContentfulCustomFormula';
import { ContentfulEmbeddedYoutubeVideo } from 'components/modules/EmbeddedYoutubeVideo';
import { ContentfulFaq } from 'components/modules/Faq';
import { ContentfulFlexibleCardsCarousel } from 'components/modules/FlexibleCardsCarousel';
import { ContentfulHeroPop } from './pop-redesign/HeroPop/ContentfulHeroPop';
import { ContentfulHowItWorks } from 'components/modules/HowItWorks';
import { ContentfulHowToUse } from './HowToUse';
import { ContentfulImageLink } from 'components/modules/ImageLink';
import { ContentfulIngredients } from 'components/modules/Ingredients';
import { ContentfulIngredientsPop } from './pop-redesign/IngredientsPop';
import { ContentfulIngredientsTabs } from 'components/modules/IngredientsTabs';
import { ContentfulKeyTakeaways } from 'components/modules/KeyTakeaways';
import { ContentfulLandingHeaderSqueeze } from 'components/modules/LandingHeaderSqueeze';
import { ContentfulMultiCategoryHero } from './MultiCategoryHero';
import { ContentfulNavBanner } from 'components/global/Nav/components/NavBanner/ContentfulNavBanner';
import { ContentfulPartners } from './Partners';
import { ContentfulPrescriptionInfo } from './PrescriptionInfo';
import { ContentfulProductBanner } from './ProductBanner';
import { ContentfulProductHero } from 'components/modules/ProductHero';
import { ContentfulProductHighlights } from './ProductHighlights';
import { ContentfulProducts } from 'components/modules/SqueezeRedesignProducts';
import { ContentfulProductsPop } from './pop-redesign/ProductsPop/ContentfulProductsPop';
import { ContentfulQuote } from 'components/modules/Quote';
import { ContentfulSeeTheDifference } from 'components/modules/SeeTheDifference';
import { ContentfulSimpleHero } from 'components/modules/SimpleHero';
import { ContentfulSocialMediaEmbed } from 'components/modules/SocialMediaEmbed';
import { ContentfulSplitContent } from 'components/modules/SplitContent';
import { ContentfulSqueezeDivider } from './SqueezeDivider/ContentfulSqueezeDivider';
import { ContentfulSteps } from 'components/modules/Steps';
import { ContentfulStepsPop } from './pop-redesign/StepsPop/ContentfulStepsPop';
import { ContentfulTextOnlyHero } from 'components/modules/TextOnlyHero';
import { ContentfulTrustModule } from 'components/modules/TrustModule';
import { ContentfulValueProp } from './ValuePropsSection/ContentfulValuePropsSection';
import { ContentfulVideo } from 'components/modules/Video';
import { ContentfulWhatWeTreat } from 'components/modules/WhatWeTreat';
import { ContentfulWhatWeTreatPop } from 'components/modules/pop-redesign/WhatWeTreatPop';
import { ContentfulWorkItOut } from './WorkItOut/ContentfulWorkItOut';
import { LandingPage } from 'components/pages/LandingPage';
import { LegalPageContentful } from 'components/pages/LegalPage';
import { SchemaError } from 'components/global/errors/SchemaError';
import { VanityRedirect } from 'components/pages/VanityRedirect';
import { VariationContainer } from 'components/pages/VariationContainer';

const componentMap = {
  // Modules
  articleConversionCta: ContentfulArticleConversionCta,
  articleFaq: ContentfulArticleFaq,
  embeddedYoutubeVideo: ContentfulEmbeddedYoutubeVideo,
  imageLink: ContentfulImageLink,
  keyTakeaways: ContentfulKeyTakeaways,
  moduleArticleCarousel: ContentfulArticleCarousel,
  moduleBeforeAfterPop: ContentfulBeforeAfterPop,
  moduleCroBeforeAndAfterSingle: ContentfulBeforeAfterSingle,
  moduleCustomComponent: ContentfulCustomComponent,
  moduleCustomFormula: ContentfulCustomFormula,
  moduleFaq: ContentfulFaq,
  moduleFlexibleCardsCarousel: ContentfulFlexibleCardsCarousel,
  moduleHeroPop: ContentfulHeroPop,
  moduleHowItWorks: ContentfulHowItWorks,
  moduleHowToUse: ContentfulHowToUse,
  moduleIngredients: ContentfulIngredients,
  moduleIngredientsPop: ContentfulIngredientsPop,
  moduleIngredientsTabs: ContentfulIngredientsTabs,
  moduleMultiCategoryHero: ContentfulMultiCategoryHero,
  moduleNavBanner: ContentfulNavBanner,
  modulePartners: ContentfulPartners,
  modulePrescriptionInformation: ContentfulPrescriptionInfo,
  moduleProductBanner: ContentfulProductBanner,
  moduleProductHero: ContentfulProductHero,
  moduleProductHighlights: ContentfulProductHighlights,
  moduleProducts: ContentfulProducts,
  moduleProductsPop: ContentfulProductsPop,
  moduleQuote: ContentfulQuote,
  moduleSeeTheDifference: ContentfulSeeTheDifference,
  moduleSimpleHero: ContentfulSimpleHero,
  moduleSplitContent: ContentfulSplitContent,
  moduleSqueezeDivider: ContentfulSqueezeDivider,
  moduleSqueezeRedesignHero: ContentfulLandingHeaderSqueeze,
  moduleSteps: ContentfulSteps,
  moduleStepsPop: ContentfulStepsPop,
  moduleTextOnlyHero: ContentfulTextOnlyHero,
  moduleTrust: ContentfulTrustModule,
  moduleValueProp: ContentfulValueProp,
  moduleVideo: ContentfulVideo,
  moduleWhatWeTreat: ContentfulWhatWeTreat,
  moduleWhatWeTreatPop: ContentfulWhatWeTreatPop,
  moduleWorkItOut: ContentfulWorkItOut,
  tikTokEmbed: ContentfulSocialMediaEmbed,
  // Pages
  blogPost: BlogPost,
  legalDocument: LegalPageContentful,
  pageLandingPage: LandingPage,
  vanityUrl: VanityRedirect,
  moduleVariationContainer: VariationContainer,

  // Schema error
  schemaError: SchemaError,
} as const;

export type ModuleData = React.ComponentProps<
  (typeof componentMap)[keyof typeof componentMap]
>;

export function DynamicComponent({ entry }: { entry: ModuleData }) {
  if (!entry?._type || !componentMap[entry._type]) {
    throw new Error(
      `DynamicComponent: No component registered for given type: "${entry?._type}"`,
    );
  }
  const Component = componentMap[entry._type] as React.FC<ModuleData>;
  return <Component {...entry} />;
}
