import React from 'react';

import { brandImages } from '../../../brandsData';
import { METHODS_DATA } from '../../../methodsData';
import { METHOD_TYPE_ICONS } from '../../../methodTypes';
import type { MethodEffectsType } from '../../../methodEffects';

import {
  MainContainer,
  GroupTitle,
  MethodBreadcrumbContainer,
  Breadcrumb,
  MethodContainer,
  SvgContainer,
  Details,
  Name,
  Brands,
} from './style';

type MethodsSectionClickHandlerType = ({
  effect,
  index,
}: {
  effect: MethodEffectsType;
  index: number;
}) => void;

const MethodsSection = ({
  effect,
  title,
  clickHandler,
  alignSelf,
  alignItems,
}: {
  effect: MethodEffectsType;
  title: string;
  clickHandler: MethodsSectionClickHandlerType;
  alignSelf: string;
  alignItems: string;
}) => {
  const METHODS = METHODS_DATA[effect];
  return METHODS ? (
    <MainContainer alignSelf={alignSelf} alignItems={alignItems}>
      <GroupTitle effect={effect}>{title}</GroupTitle>
      {METHODS.map((method, index) => (
        <MethodBreadcrumbContainer key={method.name} width={method.width}>
          {alignItems === 'flex-start' && <Breadcrumb effect={effect} />}
          <MethodContainer
            effect={effect}
            onClick={() => clickHandler({ effect, index })}
          >
            <SvgContainer effect={effect}>
              {METHOD_TYPE_ICONS[method.type]}
            </SvgContainer>
            <Details>
              <Name effect={effect}>{method.name}</Name>
              <Brands>
                {method.brands.map((brand) =>
                  brandImages.map((brandImage) => {
                    return brandImage.name === brand && brandImage.image;
                  }),
                )}
              </Brands>
            </Details>
          </MethodContainer>
          {alignItems === 'flex-end' && <Breadcrumb effect={effect} />}
        </MethodBreadcrumbContainer>
      ))}
    </MainContainer>
  ) : (
    <React.Fragment />
  );
};

export default MethodsSection;
