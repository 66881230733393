import type { SyntheticEvent } from 'react';
import React, { useCallback } from 'react';
import { Button, TYPOGRAPHY_STYLE } from 'radiance-ui';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { useTrackingOptOut } from 'components/pages/LegalPage/hooks';

export const ConfirmationText = styled.p`
  ${({ theme }) => TYPOGRAPHY_STYLE.body(theme)};
  margin: ${SPACER.medium} 0 ${SPACER.x2large} 0;
`;

const OptOutButtonContainer = styled.div`
  text-align: center;

  ${MEDIA_QUERIES.smUp} {
    text-align: left;
  }
`;

const optOutButtonStyles = css`
  margin: 0 auto;

  ${MEDIA_QUERIES.smUp} {
    margin: 0;
  }
`;

export const SharingOptOutButton = () => {
  const [hasOptedOut, optOut] = useTrackingOptOut();

  const handleOptOut = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      optOut();
    },
    [optOut],
  );

  return (
    <OptOutButtonContainer>
      <Button
        id="doNotSell_trackingOptOutButton"
        buttonType="primary"
        disabled={hasOptedOut}
        onClick={handleOptOut}
        role="button"
        aria-pressed={hasOptedOut}
        css={optOutButtonStyles}
      >
        Opt-out of Sale/Sharing
      </Button>
      {hasOptedOut && (
        <ConfirmationText>
          You have been opted out of the sale/sharing of your personal
          information.
        </ConfirmationText>
      )}
    </OptOutButtonContainer>
  );
};
