import React from 'react';

import { useHairRxPromoVariant } from 'utils/hooks/useHairPromoVariation';
import { ReactComponent as GetStartedBadge } from '../images/getStartedBadge.svg';
import { ReactComponent as HairPromoBadgeDesktopSVG } from '../images/hairPromoBadgeDesktop.svg';
import { ReactComponent as HairPromoBadgeMobileSVG } from '../images/hairPromoBadgeMobile.svg';
import {
  WhatWeTreatHairPromoBadgeContainer,
  WhatWeTreatHairPromoPercentageText,
  WhatWeTreatHairPromoForJustText,
} from '../WhatWeTreatPop.style';
import { StaticImage } from 'gatsby-plugin-image';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

export const WhatWeTreatHairPromoBadge = () => {
  const chiaWelcomeOfferVariant = useHairRxPromoVariant();

  if (chiaWelcomeOfferVariant && chiaWelcomeOfferVariant.percentageOff) {
    const PercentageOff = (
      <WhatWeTreatHairPromoPercentageText>
        {chiaWelcomeOfferVariant.percentageOff}%
      </WhatWeTreatHairPromoPercentageText>
    );

    return (
      <WhatWeTreatHairPromoBadgeContainer>
        <div css={desktopOnly()}>
          <HairPromoBadgeDesktopSVG />
          {PercentageOff}
        </div>
        <div css={mobileOnly()}>
          <HairPromoBadgeMobileSVG />
          {PercentageOff}
        </div>
      </WhatWeTreatHairPromoBadgeContainer>
    );
  } else if (chiaWelcomeOfferVariant && chiaWelcomeOfferVariant.price) {
    return (
      <WhatWeTreatHairPromoBadgeContainer>
        <div css={desktopOnly()}>
          <GetStartedBadge width="119" height="119" />
          <WhatWeTreatHairPromoForJustText>
            <div>for just</div>
            <span>$</span>
            <span>{chiaWelcomeOfferVariant.price}</span>
          </WhatWeTreatHairPromoForJustText>
        </div>
        <div css={mobileOnly()}>
          <GetStartedBadge width="80" height="80" />
          <WhatWeTreatHairPromoForJustText>
            <div>for just</div>
            <span>$</span>
            <span>{chiaWelcomeOfferVariant.price}</span>
          </WhatWeTreatHairPromoForJustText>
        </div>
      </WhatWeTreatHairPromoBadgeContainer>
    );
  }

  return (
    <React.Fragment>
      <StaticImage
        css={mobileOnly()}
        src="../images/hairPromoBadgeMobile.png"
        alt="Special offer: one month free"
      />
      <StaticImage
        css={desktopOnly()}
        src="../images/hairPromoBadge.png"
        alt="Special offer: one month free"
      />
    </React.Fragment>
  );
};
