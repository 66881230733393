import styled from '@emotion/styled';
import { GRID, MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';

import getBirthControlEffectColor from '../getBirthControlEffectColor';
import type { MethodEffectsType } from '../methodEffects';

export const MainContainer = styled.div`
  background: ${({ theme }) => theme.COLORS.white};
  padding: ${SPACER.x2large} 0;
  box-sizing: content-box;

  ${MEDIA_QUERIES.xlUp} {
    padding-bottom: 7rem;
  }
`;

export const InnerContainer = styled.div`
  padding: 0 ${SPACER.medium};
  width: calc(100% - ${SPACER.xlarge});
  max-width: ${GRID.xlContentMaxWidth};
  margin: 0 auto;

  ${MEDIA_QUERIES.xlUp} {
    padding: 0 ${SPACER.xlarge};
    width: calc(100% - ${SPACER.x5large});
  }
`;

export const AxisContainer = styled.div`
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  ${MEDIA_QUERIES.xlUp} {
    align-items: flex-end;
  }

  :before {
    content: '';
    border-left: 1px solid ${LOCAL_COLORS.grey1};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 3px;
    margin: auto;
    display: none;
    height: 100%;
    width: 0;

    ${MEDIA_QUERIES.xlUp} {
      display: block;
    }
  }
`;

export const AxisLabelContainer = styled.div`
  display: none;

  ${MEDIA_QUERIES.xlUp} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
`;

export const AxisLabel = styled.div<{ effect: MethodEffectsType }>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.caption};
  position: relative;
  background: ${({ theme }) => theme.COLORS.white};
  color: ${({ effect, theme }) => getBirthControlEffectColor(effect, theme)};

  :after {
    content: '';
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${({ effect, theme }) =>
      getBirthControlEffectColor(effect, theme)};
    margin: ${SPACER.medium};
  }
`;

export const AxisBar = styled.div`
  background: ${LOCAL_COLORS.grey1};
  display: block;
  height: 1px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 50px;
  width: 90%;
`;
