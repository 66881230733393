import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import getContrastingColor from 'utils/getContrastingColor';

export const ValuePropsContainer = styled.div<{
  backgroundColor: string;
  squeezeCompensationValue: number;
}>`
  ${({ backgroundColor }) =>
    `background: ${backgroundColor};
     color: ${getContrastingColor(backgroundColor)};
  `}

  &.squeezeCompensation {
    ${MEDIA_QUERIES.mdUp} {
      ${({ squeezeCompensationValue }) =>
        `margin-top: calc(min(100%, 180rem) * ${squeezeCompensationValue});`}
    }
  }
`;

export const ValuePropsInnerBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${SPACER.medium};
  padding: ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    flex-direction: row;
    padding: 5rem ${SPACER.xlarge};
    gap: ${SPACER.x5large};
  }

  ${MEDIA_QUERIES.lgUp} {
    padding: ${SPACER.x5large} 7.5rem;
  }

  ${MEDIA_QUERIES.xlUp} {
    align-items: center;
    max-width: 81rem;
    margin: auto;
    padding: 6.25rem 4.5rem;
  }

  ${MEDIA_QUERIES.xxlUp} {
    padding: 7.5rem 0;
  }

  &.noContainerPadding {
    padding: 0;

    ${MEDIA_QUERIES.mdUp} {
      gap: 0;
      max-width: 180rem;
      align-items: center;
    }
  }
`;

export const ValuePropsLeftBox = styled.div<{
  swapOrder?: boolean;
}>`
  flex: 1;
  ${({ swapOrder }) =>
    swapOrder
      ? `
          order: 2;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
        `
      : ''};

  ${MEDIA_QUERIES.mdUp} {
    order: inherit;
  }

  &.squeezeCompensation {
    ${MEDIA_QUERIES.mdUp} {
      flex: 1 0 0;
      max-width: 50%;
    }
  }
`;

export const ValuePropsRightBox = styled.div`
  display: flex;
  flex: 1;

  &.noContainerPadding {
    padding: 2.5rem 1rem;
  }

  &.squeezeCompensation {
    ${MEDIA_QUERIES.mdUp} {
      flex: 1 0 0;
      max-width: 50%;
      padding: 4.25rem 2.5rem 0;
    }

    ${MEDIA_QUERIES.lgUp} {
      padding: 6.25rem 4.5rem 0;
    }
  }
`;

export const ValuePropsRightBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACER.medium};
`;

export const ValuePropsTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  line-height: 110%;

  .nobr {
    white-space: nowrap;
  }

  ${MEDIA_QUERIES.lgUp} {
    font-size: 3.25rem;
    max-width: 31.25rem;
  }

  &.squeezeCompensation {
    ${MEDIA_QUERIES.lgUp} {
      font-size: 2.5rem;
    }

    ${MEDIA_QUERIES.xlUp} {
      font-size: 3.25rem;
    }
  }
`;

export const ValuePropsSubtitle = styled.div`
  font-size: ${SPACER.medium};
  line-height: 140%;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 1.5rem;
  }

  &.squeezeCompensation {
    ${MEDIA_QUERIES.lgUp} {
      font-size: 1.25rem;
    }

    ${MEDIA_QUERIES.xlUp} {
      font-size: 1.5rem;
    }
  }
`;

export const ValueProps = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${SPACER.medium};
`;

export const ValuePropContainer = styled.div`
  display: flex;
  gap: 0.375rem;
  padding: ${SPACER.small};
`;

export const ValuePropsSvgContainer = styled.div`
  padding-top: ${SPACER.xsmall};

  svg {
    align-self: center;
  }
`;

export const ValueProp = styled.div`
  font-size: ${SPACER.medium};
  font-weight: 700;
`;

export const ValuePropsDisclaimerText = styled.div`
  font-size: 0.875rem;
  line-height: 140%;
  margin-top: ${SPACER.medium};
`;
