import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useLocation } from '@gatsbyjs/reach-router';
import throttle from 'lodash.throttle';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Footer } from 'components/global/Footer';
import { Nav } from 'components/global/Nav';
import { SEO } from 'components/global/SEO';
import { ArticleCarousel } from 'components/modules/ArticleCarousel';
import {
  ContentfulCustomFormula,
  CustomFormulaPop,
  GENERAL_PURPOSE_FORMULA_CONTENT,
} from 'components/modules/pop-redesign/CustomFormulaPop';
import type { BlogPostEntry } from 'schema';
import { fetchRelatedArticles } from 'utils/article/fetchArticles';
import { formatDate } from 'utils/formatDate';
import useSetLandingPageUrlCookie from 'utils/hooks/useSetLandingPageUrlCookie';

import {
  AdditionalInfo,
  Body,
  BodyContainer,
  DisclaimerText,
  RICH_TEXT_OPTIONS,
  CtaButton,
  BottomContent,
  TableOfContentsContainer,
} from './BlogPost.style';
import { ArticleList } from './components/ArticleList';
import { ArticleSchema } from './components/ArticleSchema';
import { CtaPanel } from './components/CtaPanel';
import { DisclaimerSection } from './components/DisclaimerSection';
import { Header } from './components/Header';
import { Person } from './components/Person';
import { Summary } from './components/Summary';
import { TableOfContents } from './components/TableOfContents';
import { Tags } from './components/Tags';
import {
  enrichBodyContent,
  getTableOfContentsNodes,
} from './utils/tableOfContents';
import { BLOG_CTA_BUTTON } from 'consts/copy';
import APP_URLS from 'consts/appUrls';

import { QuizBanner } from './components/QuizBanner';
import quizData from './components/QuizBanner/fixtures/data.json';

const moduleName = 'blogArticle';
const DEFAULT_TOC_INDEX = 'toc-0';

export const BlogPost = ({
  _createdAt,
  _updatedAt,
  title,
  pageTitle,
  metaDescription,
  subtitle,
  readTimeInMinutes,
  disclaimer: disclaimers,
  articleAuthors,
  featuredArticleAuthors,
  mainCategory,
  medicalReviewer,
  headerImage,
  headerVideo,
  summary,
  bodyContent,
  tag: tags,
  shouldGenerateTableOfContents,
  popularBlogPosts,
  relatedBlogPosts,
  navCtaButton,
  sidebarCtas,
  hideSidebarCtaPanel = false,
  ctaButton,
  customFormulaModule,
}: BlogPostEntry) => {
  useSetLandingPageUrlCookie();
  const { pathname } = useLocation();

  const [currentTOCIndex, setCurrentTOCIndex] = useState({
    sectionId: DEFAULT_TOC_INDEX,
  });
  const currentTOCIndexRef = useRef(DEFAULT_TOC_INDEX);
  const previousTOCIndexRef = useRef(DEFAULT_TOC_INDEX);
  const scrollDistanceRef = useRef(0);
  const isScrollingDownRef = useRef(true);

  useEffect(() => {
    currentTOCIndexRef.current = DEFAULT_TOC_INDEX;
    previousTOCIndexRef.current = DEFAULT_TOC_INDEX;
    isScrollingDownRef.current = true;
    scrollDistanceRef.current = 0;
    setCurrentTOCIndex({ sectionId: DEFAULT_TOC_INDEX });
  }, [pathname]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const sectionId = entry.target.id;
        if (
          (entry.isIntersecting && isScrollingDownRef.current) ||
          (!entry.isIntersecting && !isScrollingDownRef.current)
        ) {
          previousTOCIndexRef.current = currentTOCIndexRef.current;
          currentTOCIndexRef.current = sectionId;
          setCurrentTOCIndex({ sectionId });
        }
      });
    });

    document.querySelectorAll("[id^='toc-']").forEach((section) => {
      observer.observe(section);
    });
  }, []);

  const handleScroll = useCallback(
    throttle(() => {
      const currentScrollDistance = window.scrollY;
      isScrollingDownRef.current =
        currentScrollDistance >= scrollDistanceRef.current;
      scrollDistanceRef.current = currentScrollDistance;
    }, 100),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const richBodyContent = enrichBodyContent(bodyContent);
  const seoPageTitle = pageTitle ?? `${title.substring(0, 70)} - Curology`;
  const seoMetaDescription =
    metaDescription ?? documentToPlainTextString(bodyContent).substring(0, 300);
  const relatedArticles = fetchRelatedArticles(pathname);
  const datePublished = formatDate(new Date(_createdAt), { month: 'short' });
  const dateModified = formatDate(new Date(_updatedAt), { month: 'short' });
  const quizBanner = quizData.find((quiz) =>
    pathname.includes(quiz.articleSlug),
  );
  const navCtaButtonOverrides = navCtaButton
    ? {
        ctaTextOverride: navCtaButton.buttonText,
        ctaLinkOverride: navCtaButton.link,
      }
    : {};

  return (
    <>
      <SEO
        title={seoPageTitle}
        description={seoMetaDescription}
        ogTitle={seoPageTitle}
        ogDescription={seoMetaDescription}
        ogImage={headerImage.url}
      />
      <ArticleSchema
        articleTitle={title}
        imageSrc={headerImage.url}
        datePublished={datePublished}
        dateModified={dateModified}
        authors={articleAuthors}
      />
      {quizBanner ? (
        <Nav
          hasHowItWorksBanner
          quizBanner={<QuizBanner {...quizBanner} />}
          {...navCtaButtonOverrides}
        />
      ) : (
        <Nav hasHowItWorksBanner {...navCtaButtonOverrides} />
      )}
      <Header
        articleAuthors={articleAuthors}
        featuredArticleAuthors={featuredArticleAuthors}
        medicalReviewer={medicalReviewer}
        title={title}
        subtitle={subtitle}
        date={dateModified}
        readTimeInMinutes={readTimeInMinutes}
        image={headerImage}
        video={headerVideo}
        mainCategory={mainCategory}
      />
      <DisclaimerText>
        We’re here to share what we know — but don’t take it as medical advice.
        Talk to your medical provider if you have questions.
      </DisclaimerText>
      {shouldGenerateTableOfContents && (
        <TableOfContentsContainer>
          <TableOfContents
            nodes={getTableOfContentsNodes(richBodyContent)}
            pathname={pathname}
            currentTOCIndex={currentTOCIndex.sectionId}
            isScrollingDown={isScrollingDownRef.current}
          />
        </TableOfContentsContainer>
      )}
      <BodyContainer>
        <Body id="blog_post_body">
          {summary && (
            <Summary>
              {documentToReactComponents(summary, RICH_TEXT_OPTIONS)}
            </Summary>
          )}
          {bodyContent &&
            documentToReactComponents(richBodyContent, RICH_TEXT_OPTIONS)}
          {disclaimers && <DisclaimerSection disclaimers={disclaimers} />}
          {tags && <Tags tags={tags} />}
          <Person
            avatar={articleAuthors[0].avatar}
            byline="Written by"
            name={articleAuthors[0].name}
            credentials={articleAuthors[0].credentials}
          />
          {medicalReviewer && (
            <Person
              avatar={medicalReviewer.avatar}
              byline="Medically reviewed by"
              name={medicalReviewer.name}
              credentials={medicalReviewer.credentials}
            />
          )}
        </Body>
        <AdditionalInfo>
          <ArticleList
            data-module="relatedArticles"
            header="Related Articles"
            articles={relatedBlogPosts}
          />
          <ArticleList
            data-module="popularArticles"
            header="Popular Articles"
            articles={popularBlogPosts}
          />
          {!hideSidebarCtaPanel && (
            <CtaPanel moduleName={moduleName} content={sidebarCtas} />
          )}
        </AdditionalInfo>
      </BodyContainer>
      <BottomContent>
        {customFormulaModule ? (
          <ContentfulCustomFormula {...customFormulaModule} />
        ) : (
          <CustomFormulaPop {...GENERAL_PURPOSE_FORMULA_CONTENT} />
        )}
        {!relatedArticles?.isLoading && relatedArticles?.response && (
          <ArticleCarousel content={relatedArticles.response} />
        )}
      </BottomContent>
      <CtaButton
        link={ctaButton?.link || APP_URLS.welcome}
        buttonText={ctaButton?.buttonText || BLOG_CTA_BUTTON}
        module={moduleName}
        small
      />
      <Footer />
    </>
  );
};
