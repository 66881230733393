import METHOD_EFFECTS from './methodEffects';
import { METHOD_TYPES } from './methodTypes';

const METHOD_GRAPH_STATS = [
  {
    type: METHOD_TYPES.PILL,
    effect: METHOD_EFFECTS.NONE,
    title: 'Combined oral contraceptive',
    percent: 57,
  },
  {
    type: METHOD_TYPES.IUD,
    effect: METHOD_EFFECTS.WORSE,
    title: 'Hormonal IUD',
    percent: 23,
  },
  {
    type: METHOD_TYPES.IMPLANT,
    effect: METHOD_EFFECTS.WORSE,
    title: 'Subdermal Implant',
    percent: 7,
  },
  {
    type: METHOD_TYPES.RING,
    effect: METHOD_EFFECTS.BETTER,
    title: 'Vaginal Ring',
    percent: 5,
  },
  {
    type: METHOD_TYPES.SHOT,
    effect: METHOD_EFFECTS.WORSE,
    title: 'Depot injection',
    percent: 4,
  },
  {
    type: METHOD_TYPES.OTHER,
    effect: METHOD_EFFECTS.UNSET,
    title: 'Other',
    percent: 2,
  },
];

export default METHOD_GRAPH_STATS;
