import React from 'react';
import styled from '@emotion/styled';

import { EVENTS } from 'consts/metricsTrackingEvents';
import { trackEvent } from 'utils/metricsTracking';

const StyledLink = styled.a`
  color: ${({ theme }) => theme.COLORS.primaryTint1};
  text-decoration: underline;
  border-bottom: none;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.COLORS.primaryTint2};
  }
`;

type TextLinkProps = {
  href: string;
  children: React.ReactNode;
};

export const TextLink = ({ href, children }: TextLinkProps) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    trackEvent(EVENTS.blogLinkClicked, {
      path: window.location.pathname,
      linkText: children,
      linkUrl: event.currentTarget.href,
      type: 'Text',
    });

    window.location.href = event.currentTarget.href;
  };

  return (
    <StyledLink href={href} onClick={handleClick}>
      {children}
    </StyledLink>
  );
};
