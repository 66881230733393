import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';

export const MainContainer = styled.div`
  margin: ${SPACER.x2large} 0;
`;

export const ChartLabel = styled.div`
  color: ${({ theme }) => theme.COLORS.primary};
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.caption};
  margin-top: ${SPACER.medium};
  max-width: 350px;
  text-align: center;
`;
