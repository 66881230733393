import { useTheme } from '@emotion/react';
import { Accordion } from 'radiance-ui';
import { PlayIcon } from 'radiance-ui/lib/icons';
import React, { useState } from 'react';
import type { YouTubeProps } from 'react-youtube';
import Youtube from 'react-youtube';

import { EVENTS } from 'consts/metricsTrackingEvents';
import { LOCAL_COLORS } from 'styles/constants';
import { trackEvent } from 'utils/metricsTracking';

import {
  audioDescriptionButton,
  PlayButton,
  TranscriptBox,
  transcriptTitleStyles,
  VideoContainer,
  VideoParentContainer,
  VideoThumbnail,
} from './EmbeddedYoutubeVideo.style';
import { getYoutubeID } from './utils/getYoutubeID';

export interface EmbeddedYoutubeVideoProps {
  youtubeUrl: string;
  youtubeUrlWithAudioDescription?: string;
  thumbnailOverride?: React.ReactNode;
  transcript?: React.ReactNode;
}

export const EmbeddedYoutubeVideo = ({
  youtubeUrl,
  youtubeUrlWithAudioDescription,
  transcript,
  thumbnailOverride,
}: EmbeddedYoutubeVideoProps) => {
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [transcriptIsOpen, setTranscriptIsOpen] = useState(false);
  const [activeVideo, setActiveVideo] = useState<string>(youtubeUrl);

  const theme = useTheme();

  const handleThumbnailClick = () => {
    trackEvent(EVENTS.moduleInteraction, {
      path: window.location.pathname,
      'Module Name': `Video`,
      'Module Element Section': 'Play Button',
      'Module Element Interaction Type': 'Click',
    });
    setShowThumbnail(false);
  };

  const videoId = getYoutubeID(activeVideo);
  const thumbnail = thumbnailOverride || (
    <img
      src={`https://i1.ytimg.com/vi/${videoId}/maxresdefault.jpg`}
      alt="play video"
    />
  );
  const youtubeSettings: YouTubeProps = {
    videoId: showThumbnail ? '' : videoId,
    opts: {
      playerVars: {
        autoplay: 1,
      },
    },
  };

  return (
    <VideoParentContainer>
      <VideoContainer showThumbnail={showThumbnail}>
        <Youtube {...youtubeSettings} />
        {showThumbnail && (
          <VideoThumbnail
            onClick={handleThumbnailClick}
            aria-label="Watch video"
          >
            {thumbnail}
            <PlayButton>
              <PlayIcon height={20} width={20} color={theme.COLORS.primary} />
            </PlayButton>
          </VideoThumbnail>
        )}
      </VideoContainer>
      {youtubeUrlWithAudioDescription && (
        <button
          type="button"
          css={audioDescriptionButton}
          aria-label={
            activeVideo === youtubeUrl
              ? 'Activate audio description'
              : 'Deactivate audio description'
          }
          onClick={() =>
            setActiveVideo(
              activeVideo === youtubeUrl
                ? youtubeUrlWithAudioDescription
                : youtubeUrl,
            )
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            width={30}
            height={30}
            fill={
              activeVideo === youtubeUrl
                ? LOCAL_COLORS.surfaceBrandLight
                : LOCAL_COLORS.surfaceBrandBold
            }
          >
            <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM213.5 173.3l72 144c5.9 11.9 1.1 26.3-10.7 32.2s-26.3 1.1-32.2-10.7l-9.4-18.9H150.9l-9.4 18.9c-5.9 11.9-20.3 16.7-32.2 10.7s-16.7-20.3-10.7-32.2l72-144c4.1-8.1 12.4-13.3 21.5-13.3s17.4 5.1 21.5 13.3zm-.4 106.6L192 237.7l-21.1 42.2h42.2zM304 184c0-13.3 10.7-24 24-24h56c53 0 96 43 96 96s-43 96-96 96H328c-13.3 0-24-10.7-24-24V184zm48 24v96h32c26.5 0 48-21.5 48-48s-21.5-48-48-48H352z" />
          </svg>
          {activeVideo === youtubeUrl ? 'Without' : 'With'} audio description
        </button>
      )}
      {transcript && (
        <TranscriptBox>
          <Accordion
            title={<div css={transcriptTitleStyles}>Transcript</div>}
            isOpen={transcriptIsOpen}
            onClick={() => setTranscriptIsOpen(!transcriptIsOpen)}
            noBorder
          >
            <small>{transcript}</small>
          </Accordion>
        </TranscriptBox>
      )}
    </VideoParentContainer>
  );
};
