import React from 'react';
import { v4 } from 'uuid';

import { Carousel } from 'components/global/Carousel';
import {
  FlexibleCard,
  type FlexibleCardProps,
} from 'components/global/FlexibleCard';
import { CtaButton, type CtaButtonProps } from 'components/global/CtaButton';
import { StarRating, type StarRatingProps } from 'components/global/StarRating';

import { LeapingBunnyBadge } from './components/LeapingBunnyBadge';
import { VeganAndCrueltyFreeBadge } from './components/VeganAndCrueltyFreeBadge';
import {
  BadgesContainer,
  bodyStyles,
  CarouselContainer,
  ctaButtonDesktopStyles,
  ctaButtonMobileStyles,
  disclaimerStyles,
  FlexibleCardContainer,
  GridContainer,
  HeaderContainer,
  headerStyles,
  InnerContainer,
  OuterContainer,
} from './FlexibleCardsCarousel.style';

export type CardSizeType = 'Large' | 'Medium' | 'Small';
export type DesktopLayoutType = 'Carousel' | 'Grid' | 'List';
export type MobileLayoutType = 'Carousel' | 'Grid';
export type CtaButtonDisplayType = ('Show on desktop' | 'Show on mobile')[];

export interface FlexibleCardsCarouselProps {
  header: string;
  starRating?: StarRatingProps;
  body?: React.ReactNode;
  showBadges?: boolean;
  /** List of FlexibleCard components */
  cards: FlexibleCardProps[];
  desktopCardSize?: CardSizeType;
  desktopLayout?: DesktopLayoutType;
  mobileLayout?: MobileLayoutType;
  ctaButton?: CtaButtonProps;
  ctaButtonDisplay?: CtaButtonDisplayType;
  disclaimer?: string;
  backgroundColor?: string;
}

export const FlexibleCardsCarousel = ({
  header,
  starRating,
  body,
  showBadges,
  cards,
  disclaimer,
  backgroundColor,
  desktopCardSize = 'Medium',
  desktopLayout = 'Grid',
  mobileLayout = 'Carousel',
  ctaButton,
  ctaButtonDisplay,
}: FlexibleCardsCarouselProps) => {
  const carouselItems = cards.map((card) => (
    <FlexibleCardContainer
      key={v4()}
      desktopCardSize={desktopCardSize}
      carouselOnMobile={mobileLayout === 'Carousel'}
      desktopLayout={desktopLayout}
    >
      <FlexibleCard
        title={card.title}
        subtitle={card.subtitle}
        body={card.body}
        featureList={card.featureList}
        media={card.media}
        hoverMedia={card.hoverMedia}
        mediaTag={card.mediaTag}
        link={card.link}
      />
    </FlexibleCardContainer>
  ));

  const moduleName = 'flexibleCardsCarousel';
  const ctaButtonIsShownOnMobile = ctaButtonDisplay?.includes('Show on mobile');
  const ctaButtonIsShownOnDesktop =
    ctaButtonDisplay?.includes('Show on desktop');

  return (
    <OuterContainer backgroundColor={backgroundColor} data-module={moduleName}>
      <InnerContainer>
        <HeaderContainer>
          <div>
            {!!header && <h2 css={headerStyles}>{header}</h2>}
            {body && <div css={bodyStyles}>{body}</div>}
            {starRating && (
              <StarRating rating={starRating.rating} text={starRating.text} />
            )}
            {showBadges && (
              <BadgesContainer>
                <LeapingBunnyBadge />
                <VeganAndCrueltyFreeBadge />
              </BadgesContainer>
            )}
          </div>
          {ctaButton && ctaButtonDisplay && (
            <CtaButton
              {...ctaButton}
              module={moduleName}
              css={ctaButtonDesktopStyles(ctaButtonIsShownOnDesktop)}
            />
          )}
        </HeaderContainer>
        <GridContainer
          showOnMobile={mobileLayout === 'Grid'}
          showOnDesktop={desktopLayout === 'Grid' || desktopLayout === 'List'}
          listOnDesktop={desktopLayout === 'List'}
          desktopCardSize={desktopCardSize}
        >
          {carouselItems}
        </GridContainer>
        <CarouselContainer
          showOnMobile={mobileLayout === 'Carousel'}
          showOnDesktop={desktopLayout === 'Carousel'}
        >
          <Carousel items={carouselItems} desktopCardSize={desktopCardSize} />
        </CarouselContainer>
        {ctaButton && ctaButtonDisplay && (
          <CtaButton
            {...ctaButton}
            module={moduleName}
            css={ctaButtonMobileStyles(ctaButtonIsShownOnMobile)}
          />
        )}
        {disclaimer && <small css={disclaimerStyles}>{disclaimer}</small>}
      </InnerContainer>
    </OuterContainer>
  );
};
