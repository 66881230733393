import React from 'react';

import {
  LeftContainer,
  ProviderName,
  RightContainer,
  Headline,
  OuterContainer,
} from './ProviderCard.style';

export interface ProviderCardProps {
  providerName: string;
  providerImage: React.ReactNode;
  headline: string;
  cardBody: React.ReactNode;
}

export const ProviderCard = ({
  providerName,
  providerImage,
  headline,
  cardBody,
}: ProviderCardProps) => (
  <OuterContainer>
    <LeftContainer>
      {providerImage}
      <ProviderName>{providerName}</ProviderName>
    </LeftContainer>
    <RightContainer>
      <Headline>{headline}</Headline>
      {cardBody}
    </RightContainer>
  </OuterContainer>
);
