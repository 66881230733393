import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_SPACING } from 'styles/constants';

import getBirthControlEffectColor from '../../../getBirthControlEffectColor';
import type { MethodEffectsType } from '../../../methodEffects';

export const MainContainer = styled.div<{
  alignItems: string;
  alignSelf: string;
}>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  margin: 0 auto ${SPACER.x2large};
  cursor: pointer;

  ${MEDIA_QUERIES.xlUp} {
    align-items: ${({ alignItems }) => alignItems};
    align-self: ${({ alignSelf }) => alignSelf};
    max-width: ${({ alignItems }) => (alignItems === 'center' ? '60%' : '50%')};
    margin: 0;
  }
`;

export const GroupTitle = styled.div<{ effect: MethodEffectsType }>`
  border-bottom: 2px solid;
  display: block;
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.title};
  line-height: 1.2;
  margin: ${SPACER.large};
  padding-bottom: ${LOCAL_SPACING.xxsmall};
  text-align: center;
  width: calc(100% - ${SPACER.x2large});
  color: ${({ effect, theme }) => getBirthControlEffectColor(effect, theme)};
  border-color: ${({ effect, theme }) =>
    getBirthControlEffectColor(effect, theme)};

  ${MEDIA_QUERIES.xlUp} {
    display: none;
  }
`;

export const MethodBreadcrumbContainer = styled.div<{ width: number }>`
  ${MEDIA_QUERIES.xlUp} {
    width: ${({ width }) => `${width}%`};
    margin: ${SPACER.medium} 0;
    display: flex;
    align-items: center;
  }
`;

export const Breadcrumb = styled.div<{ effect: MethodEffectsType }>`
  display: none;

  ${MEDIA_QUERIES.xlUp} {
    display: block;
    flex: 1;
    border-width: 0 0 5px 0;
    border-style: dotted;
    border-color: ${({ effect, theme }) =>
      getBirthControlEffectColor(effect, theme)};
    margin: 0 2px 0;
  }
`;

export const SvgContainer = styled.div<{ effect: MethodEffectsType }>`
  width: 50px;
  height: 50px;
  min-width: 50px;
  border: none;
  padding: 0 ${LOCAL_SPACING.xxsmall} 0 0;
  background: ${({ theme }) => theme.COLORS.white};

  svg {
    fill: ${({ effect, theme }) => getBirthControlEffectColor(effect, theme)};
  }

  ${MEDIA_QUERIES.xlUp} {
    padding: ${LOCAL_SPACING.xxsmall};
    border: 1px solid;
    border-radius: 50%;
    border-color: ${({ effect, theme }) =>
      getBirthControlEffectColor(effect, theme)};
  }
`;

export const MethodContainer = styled.div<{ effect: MethodEffectsType }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 260px;
  position: relative;
  margin: ${SPACER.small} 0;

  ${MEDIA_QUERIES.xlUp} {
    margin: 0;
    :hover {
      ${SvgContainer} {
        background: ${({ effect, theme }) =>
          getBirthControlEffectColor(effect, theme)};

        svg {
          fill: ${({ theme }) => theme.COLORS.white};
        }
      }
    }
  }
`;

export const Details = styled.div`
  padding: 0 ${SPACER.medium};
`;

export const Name = styled.h4<{ effect: MethodEffectsType }>`
  color: ${({ effect, theme }) => getBirthControlEffectColor(effect, theme)};
  margin: 0;
  line-height: 1.2;
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};

  ${MEDIA_QUERIES.xlUp} {
    font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.body};
  }
`;

export const Brands = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-right: ${SPACER.xsmall};
  }
`;
