import React from 'react';
import { EmbeddedYoutubeVideo } from '../EmbeddedYoutubeVideo';

import {
  OuterContainer,
  InnerBox,
  LeftContainer,
  RightContainer,
  HeaderContainer,
  StepsContainer,
  DesktopBar,
  Disclaimer,
} from './HowToUse.style';

export interface HowToUseProp {
  header: string;
  stepsHeader?: string;
  steps?: string[];
  youtubeUrl: string;
  youtubeUrlWithAudioDescription?: string;
  thumbnailOverride?: React.ReactNode;
  /** While this is optional, it is highly recommended so our site can be accessible */
  transcript?: React.ReactNode;
  disclaimer?: React.ReactNode;
}

export const HowToUse = ({
  header,
  stepsHeader,
  steps,
  youtubeUrl,
  youtubeUrlWithAudioDescription,
  thumbnailOverride,
  transcript,
  disclaimer,
}: HowToUseProp) => {
  const video = {
    youtubeUrl,
    youtubeUrlWithAudioDescription,
    thumbnailOverride,
    transcript,
  };

  return (
    <OuterContainer>
      <InnerBox>
        <LeftContainer>
          <HeaderContainer>{header}</HeaderContainer>
          <DesktopBar />
          <StepsContainer>
            {stepsHeader}
            {steps && (
              <ol>
                {steps.map((step, index) => (
                  <li key={index}> {step}</li>
                ))}
              </ol>
            )}
            {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
          </StepsContainer>
        </LeftContainer>
        <RightContainer>
          <EmbeddedYoutubeVideo {...video} />
        </RightContainer>
      </InnerBox>
    </OuterContainer>
  );
};
