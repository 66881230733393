import React from 'react';

import starFull from './images/starFull.png';
import starFull2x from './images/starFull@2x.png';
import star2 from './images/star2.png';
import star22x from './images/star2@2x.png';
import star3 from './images/star3.png';
import star32x from './images/star3@2x.png';
import star5 from './images/star5.png';
import star6 from './images/star6.png';
import star62x from './images/star6@2x.png';
import star7 from './images/star7.png';
import star72x from './images/star7@2x.png';
import StarIconStyled from './StarIcon.style';

export interface StarIconProps {
  size?: string;
}

export const StarIcon = ({ size = 'full' }: StarIconProps) => {
  let iconPath;
  let iconPath2x;

  switch (size) {
    case '20%':
      iconPath = star2;
      iconPath2x = star22x;
      break;
    case '30%':
      iconPath = star3;
      iconPath2x = star32x;
      break;
    case '50%':
      iconPath = star5;
      iconPath2x = star5;
      break;
    case '60%':
      iconPath = star6;
      iconPath2x = star62x;
      break;
    case '70%':
      iconPath = star7;
      iconPath2x = star72x;
      break;
    default:
      iconPath = starFull;
      iconPath2x = starFull2x;
      break;
  }

  return (
    <StarIconStyled
      src={iconPath}
      srcSet={`${iconPath2x} 2x`}
      alt="star icon"
      loading="lazy"
    />
  );
};
