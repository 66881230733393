import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import {
  documentToReactComponents,
  type Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { SPACER } from 'radiance-ui/lib/constants';

import { ContentfulImage } from 'components/global/ContentfulImage';
import type { ArticleFaqEntry } from 'schema';

import { DynamicComponent } from '../DynamicComponent';
import { ArticleFaq } from './ArticleFaq';
import * as Style from './ContentfulArticleFaq.style';

export const RICH_TEXT_OPTIONS: Options = {
  renderNode: {
    [BLOCKS.OL_LIST]: (_node, children) => (
      <Style.OrderedList>{children}</Style.OrderedList>
    ),
    [BLOCKS.UL_LIST]: (_node, children) => (
      <Style.UnorderedList>{children}</Style.UnorderedList>
    ),
    [BLOCKS.LIST_ITEM]: (_node, children) => (
      <Style.ListItem>{children}</Style.ListItem>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <ContentfulImage
        image={node.data.target}
        css={{ margin: `${SPACER.large} 0` }}
      />
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node) => (
      <DynamicComponent entry={node.data.target} />
    ),
  },
};

export const ContentfulArticleFaq = ({
  questionsAnswers,
  ...props
}: ArticleFaqEntry) => {
  const processedQA = questionsAnswers.map(({ question, answer }) => ({
    question,
    answer: documentToReactComponents(answer, RICH_TEXT_OPTIONS),
    answerString: documentToPlainTextString(answer),
  }));
  return <ArticleFaq {...props} questionsAnswers={processedQA} />;
};
