import type { ThemeType } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';
import METHOD_EFFECTS, { type MethodEffectsType } from './methodEffects';

const getBirthControlEffectColor = (
  effect: MethodEffectsType,
  theme: ThemeType,
) => {
  let effectColor;

  switch (effect) {
    case METHOD_EFFECTS.NONE:
      effectColor = LOCAL_COLORS.birthControlNoneEffect;
      break;

    case METHOD_EFFECTS.WORSE:
      effectColor = LOCAL_COLORS.orange;
      break;

    case METHOD_EFFECTS.BETTER:
      effectColor = theme.COLORS.primary;
      break;

    case METHOD_EFFECTS.UNSET:
      effectColor = LOCAL_COLORS.birthControlUnsetEffect;
      break;

    default:
      effectColor = LOCAL_COLORS.birthControlUnsetEffect;
      break;
  }

  return effectColor;
};

export default getBirthControlEffectColor;
