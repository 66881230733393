import React from 'react';
import { useLocation } from '@gatsbyjs/reach-router';
import { Typography } from 'radiance-ui';

import { SEO } from 'components/global/SEO';
import { Nav } from 'components/global/Nav';
import { Footer } from 'components/global/Footer';
import { SharingOptOutButton } from './components/SharingOptOutButton';
import { SideNav } from './components/SideNav';
import {
  BodyContainer,
  InnerContainer,
  MainContainer,
} from './LegalPage.style';
import { getQueryParameterValue } from 'utils/queryParameters';

type LegalPageProps = {
  title: string;
  seoTitle: string;
  bodyContent: React.ReactNode;
  datePublished: string;
};

export const LegalPage = ({
  title,
  seoTitle,
  bodyContent,
  datePublished,
}: LegalPageProps) => {
  const { pathname } = useLocation();
  const isDoNotSellPage = pathname.includes('/donotsell');
  const isModal = getQueryParameterValue('modal');

  return (
    <>
      <SEO title={`Curology - ${seoTitle}`} />
      {!isModal && <Nav />}
      <MainContainer shouldCenterContent={isDoNotSellPage}>
        <InnerContainer>
          {!isDoNotSellPage && !isModal && <SideNav />}
          <BodyContainer extraPadding={!isDoNotSellPage}>
            <Typography.Display>{title}</Typography.Display>
            <Typography.Caption>
              Last Updated: {datePublished}
            </Typography.Caption>
            {bodyContent}
            {isDoNotSellPage && <SharingOptOutButton />}
          </BodyContainer>
        </InnerContainer>
      </MainContainer>
      <Footer />
    </>
  );
};
