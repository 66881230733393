import React from 'react';

import { CtaButton, type CtaButtonProps } from 'components/global/CtaButton';
import { EmbeddedYoutubeVideo } from 'components/modules/EmbeddedYoutubeVideo';

import {
  OuterContainer,
  InnerContainer,
  TextContainer,
  headerStyles,
  headerDescription,
  ctaButtonStyles,
} from './Video.style';

export interface VideoProps {
  /** 50 characters max, preferred ~30 */
  header?: string;
  /** Pick a font style for the header text  */
  headerStyle?: 'H2' | 'H3';
  /** 180 characters max, preferred ~100 */
  body?: React.ReactNode;
  /** Button text is required. Disclaimer text is optional */
  ctaButton?: CtaButtonProps;
  /** You can get this by right-clicking on the video and clicking "Copy video URL" */
  youtubeUrl: string;
  youtubeUrlWithAudioDescription?: string;
  thumbnailOverride?: React.ReactNode;
  /** While this is optional, it is highly recommended so our site can be accessible */
  transcript?: React.ReactNode;
}

export const Video = ({
  header,
  headerStyle = 'H2',
  body,
  ctaButton,
  youtubeUrl,
  youtubeUrlWithAudioDescription,
  thumbnailOverride,
  transcript,
}: VideoProps) => {
  const moduleName = 'videoModule';
  const showTextBox: boolean = !!header || !!body || !!ctaButton;
  const video = {
    youtubeUrl,
    youtubeUrlWithAudioDescription,
    thumbnailOverride,
    transcript,
  };

  return (
    <OuterContainer data-module={moduleName}>
      <InnerContainer showTextBox={showTextBox}>
        {showTextBox && (
          <TextContainer>
            {headerStyle === 'H2' ? (
              <h2 css={headerStyles}>{header}</h2>
            ) : (
              <h3 css={headerStyles}>{header}</h3>
            )}
            <div css={headerDescription}>{body}</div>
            {ctaButton && (
              <CtaButton
                {...ctaButton}
                module={moduleName}
                css={ctaButtonStyles()}
              />
            )}
          </TextContainer>
        )}
        <EmbeddedYoutubeVideo {...video} />
      </InnerContainer>
    </OuterContainer>
  );
};
