import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { LOCAL_COLORS } from 'styles/constants';

export const AvatarsContainer = styled.div<{
  numAuthors: number;
}>`
  display: inline-flex;
  flex-direction: row-reverse;
  max-height: 52px;
  width: ${({ numAuthors }) => `${52 + 0.5 * ((numAuthors - 1) * 52)}px`};
`;

export const AvatarContainer = styled.div<{
  numAuthors: number;
}>`
  border: 2px solid ${LOCAL_COLORS.surfacePastelPurple};
  border-radius: 50%;
  min-height: 48px;
  min-width: 48px;
  z-index: -1;

  &:not(:last-child) {
    margin-left: ${({ numAuthors }) => `-${(1 / (numAuthors + 1)) * 100}%`};
  }
`;

export const AuthorText = styled.div<{
  hasFeaturedAuthors: boolean;
}>`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  row-gap: ${({ hasFeaturedAuthors }) => (hasFeaturedAuthors ? '0' : '4px')};
  line-height: 150%;
  padding-left: 16px;
`;

export const authorNameStyles = css`
  color: ${LOCAL_COLORS.corePurple600};
  font-weight: bold;
`;
