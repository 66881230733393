const NONE = 'none';
const WORSE = 'worse';
const BETTER = 'better';
const UNSET = 'unset';

const METHOD_EFFECTS = {
  NONE,
  WORSE,
  BETTER,
  UNSET,
} as const;

export type MethodEffectsType = valueof<typeof METHOD_EFFECTS>;

export default METHOD_EFFECTS;
