import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  CTA_UNLOCK_YOUR_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';
import type { ProductHeroProps } from '..';

export const PRODUCT_HERO_CUSTOM_FORMULA_ANTI_AGING_CONTENT: ProductHeroProps =
  {
    productImage: (
      <StaticImage
        src="../images/customFormula@2x.jpg"
        alt="Custom Formula - 93% report effective"
        width={560}
        height={560}
        loading="eager"
      />
    ),
    isRx: true,
    title: (
      <React.Fragment>
        Custom Formula<sup>RX</sup>
        <br />
        for anti-aging
      </React.Fragment>
    ),
    description:
      'Boost your glow with a with a personalized Rx cream—prescribed for you by a dermatology provider.  You’ll get a custom combination of anti-aging ingredients, which can include tretinoin, niacinamide, tranexamic acid, and more.',
    valueProps: [
      'Clinically proven ingredients',
      'Free online access to a dermatology provider',
      'Easy bi-monthly deliveries of your routine',
    ],
    targets: [
      'Wrinkles',
      'Dark spots',
      'Firmness',
      'Fine lines',
      'Skin texture',
    ],
    ctaButton: {
      buttonText: CTA_UNLOCK_YOUR_OFFER,
      disclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
    },
    importantSafetyInformationUrl:
      'https://support.curology.com/en_us/curology-custom-formularx-important-safety-information-H1SpxQeQa',
    purchaseTypeToggle: {
      netsuiteId: 'Large Curology Future-Proof',
      oneTimePrice: '$44.95',
      oneTimeText: 'Try it with no commitment!',
      recurringPrice: '$34.95',
      recurringSavings: '$14.95',
      recurringFrequency:
        'Save 50% and get a <strong>free routine starter set!</strong>',
    },
  };
