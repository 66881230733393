import React from 'react';
import PropTypes from 'prop-types';
import { ImmersiveModal } from 'radiance-ui';

import { METHODS_DATA } from '../../../methodsData';
import METHOD_EFFECTS from '../../../methodEffects';

import {
  Text,
  GraphTitle,
  BarsContainer,
  Bar,
  PercentageContainer,
  Percentage,
  LabelsContainer,
  Label,
  DescriptionContainer,
} from './style';

const MethodDetailsModal = ({ activeMethod, onClose }) => {
  const METHOD = METHODS_DATA[activeMethod.effect][activeMethod.index];
  return (
    <ImmersiveModal onClose={onClose} title={METHOD.name}>
      <Text>{METHOD.ingredients}</Text>
      <Text>
        <strong>Examples: </strong>
        {METHOD.examples}
      </Text>

      {!!METHOD.percentages.length && (
        <React.Fragment>
          <GraphTitle>Self-Reported Effect on Acne</GraphTitle>

          <BarsContainer>
            <Bar width={METHOD.percentages[0]} effect={METHOD_EFFECTS.WORSE} />
            <Bar width={METHOD.percentages[1]} effect={METHOD_EFFECTS.NONE} />
            <Bar width={METHOD.percentages[2]} effect={METHOD_EFFECTS.UNSET} />
            <Bar width={METHOD.percentages[3]} effect={METHOD_EFFECTS.BETTER} />
          </BarsContainer>

          <PercentageContainer>
            <Percentage width={METHOD.percentages[0]}>
              {METHOD.percentages[0]}%
            </Percentage>
            <Percentage width={METHOD.percentages[1]}>
              {METHOD.percentages[1]}%
            </Percentage>
            <Percentage width={METHOD.percentages[2]}>
              {METHOD.percentages[2]}%
            </Percentage>
            <Percentage width={METHOD.percentages[3]}>
              {METHOD.percentages[3]}%
            </Percentage>
          </PercentageContainer>

          <LabelsContainer>
            <Label effect={METHOD_EFFECTS.WORSE}>Makes it worse</Label>
            <Label effect={METHOD_EFFECTS.NONE}>Not much effect</Label>
            <Label effect={METHOD_EFFECTS.UNSET}>Helps a bit</Label>
            <Label effect={METHOD_EFFECTS.BETTER}>Helps significantly</Label>
          </LabelsContainer>
        </React.Fragment>
      )}
      <DescriptionContainer>{METHOD.description}</DescriptionContainer>
    </ImmersiveModal>
  );
};

MethodDetailsModal.propTypes = {
  activeMethod: PropTypes.shape({
    effect: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

export default MethodDetailsModal;
