import React from 'react';
import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';
import { Script } from 'gatsby';

interface SocialMediaEmbedProps {
  embedCode: string;
  scriptUrl: string;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${SPACER.base};
`;

export const SocialMediaEmbed = ({
  embedCode,
  scriptUrl,
}: SocialMediaEmbedProps) => (
  <>
    <Wrapper dangerouslySetInnerHTML={{ __html: embedCode }} />
    <Script async src={scriptUrl} />
  </>
);
